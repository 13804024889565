import { create } from 'zustand';
import { BrowserDeviceType } from 'generated/data-contracts';

export const enum OverlayTypes {
	CONTENT = 'CONTENT',
	FULL = 'FULL',
}
interface AppState {
	segmentationId: number;
	showOverlay: OverlayTypes | false;
	pageOrigin: string;
	device: BrowserDeviceType;
}

interface AppActions {
	setSegmentationId: (segmentationId: number) => void;
	setShowOverlay: (showOverlay: OverlayTypes | false) => void;
	setDevice: (device: BrowserDeviceType) => void;
}

export const useAppStore = create<AppState & AppActions>((set) => ({
	segmentationId: 1,
	showOverlay: false,
	pageOrigin: '',
	device: BrowserDeviceType.Desktop,
	setSegmentationId: (segmentationId) => set({ segmentationId }),
	setShowOverlay: (showOverlay) => set({ showOverlay }),
	setDevice: (device) => set({ device }),
}));
