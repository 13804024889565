import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import DisabledCell from 'components/features/NewBasket/shared/DisabledCell';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/shared';
import { useDragToCopy } from 'components/shared/DragToCopyContext';
import { useKeyboardNavigation } from 'components/shared/KeyboardNavigationContext';
import { formatTranslationAsJsx } from 'helpers/stringHelpers';
import { useBasketRowContext } from '../context/BasketRowContext';
import styles from './GridCell.module.scss';

export interface GridCellProps {
	shipTo: string;
	className?: string;
	isDisabledOverride?: boolean;
	isTooltipDisabled?: boolean;
	rowIndex: number;
	columnIndex: number;
}

export const GridCell: React.FunctionComponent<React.PropsWithChildren<GridCellProps>> = ({
	shipTo,
	className,
	rowIndex,
	columnIndex,
	isDisabledOverride,
	isTooltipDisabled,
	children,
}) => {
	const {
		bundle,
		lookups: { restrictions },
		allowedShipTos,
		masterId,
	} = useBasketRowContext();
	const { data: translations } = useTranslationQuery();
	const isDisabled = React.useMemo(() => {
		if (isDisabledOverride) return true;
		if (allowedShipTos !== undefined && !allowedShipTos[shipTo]) {
			return true;
		}
		// If it's boxes, then checking allowedShipTos is enough
		if (!masterId) {
			return false;
		}

		const starterBoxRestrictions = restrictions?.[masterId];
		return starterBoxRestrictions?.restrictions[shipTo] === true;
	}, [allowedShipTos, isDisabledOverride, masterId, restrictions, shipTo]);
	const { cellAttributes } = useKeyboardNavigation();
	const { draggableCellContent } = useDragToCopy();
	const shouldTooltipBeEnabled = React.useMemo(() => {
		if (isDisabled || isTooltipDisabled) return false;
		if (!bundle.minimumQuantity) return false;
		return true;
	}, [bundle.minimumQuantity, isTooltipDisabled, isDisabled]);
	return (
		<Tooltip placement="top" enabled={shouldTooltipBeEnabled} open showArrow isRenderedInPortal={false}>
			<TooltipTrigger asChild>
				<li
					className={classNames(styles.shipToItem, className, {
						[styles.isDisabled]: isDisabled,
					})}
					{...cellAttributes({
						rowIndex,
						columnIndex,
					})}
					{...draggableCellContent({
						rowIndex,
						columnIndex,
					})}
				>
					{isDisabled ? <DisabledCell /> : children}
				</li>
			</TooltipTrigger>
			{shouldTooltipBeEnabled && (
				<TooltipContent className={styles.tooltipText}>
					{formatTranslationAsJsx(translations?.preBasket.minQuantityRequired, {
						0: bundle.minimumQuantity,
					})}
				</TooltipContent>
			)}
		</Tooltip>
	);
};
