import { FilterValueResponse } from 'generated/data-contracts';
import {
	DateFilterValueResponse,
	FilterState,
	RangeFilterValueResponse,
	SelectedFilterValues,
} from '../types/FilterState';
import { FilterTypesEnum } from '../types/FilterTypes';

type UrlParamFilterState = {
	[FilterTypesEnum.Checkbox]: Record<string, string[]>;
	[FilterTypesEnum.Range]: Record<string, RangeFilterValueResponse>;
	[FilterTypesEnum.Date]: Record<string, DateFilterValueResponse>;
	[FilterTypesEnum.Single]: Record<string, string>;
};

const CheckBoxFilterMapper = {
	toMap: (filter: Record<string, string[]>): Map<string, SelectedFilterValues> =>
		Object.entries(filter).reduce<Map<string, SelectedFilterValues>>((acc, [key, values]) => {
			acc.set(
				key,
				values.reduce<SelectedFilterValues>((acc2, value) => {
					const filterValue: FilterValueResponse = {
						isSelected: true,
						label: value,
						value: value,
					};
					acc2.set(value, filterValue);
					return acc2;
				}, new Map<string, FilterValueResponse>()),
			);
			return acc;
		}, new Map<string, SelectedFilterValues>()),
	toObject: (filter: Map<string, SelectedFilterValues>): Record<string, string[]> =>
		Array.from(filter.entries()).reduce<Record<string, string[]>>((acc, curr) => {
			const [key, val] = curr;
			acc[key] = Array.from(val.keys()).map((v) => v);
			return acc;
		}, {}),
};

const RangeFilterMapper = {
	toMap: (filter: Record<string, RangeFilterValueResponse>): Map<string, RangeFilterValueResponse> =>
		Object.entries(filter).reduce<Map<string, RangeFilterValueResponse>>((acc, [key, value]) => {
			acc.set(key, value);
			return acc;
		}, new Map<string, RangeFilterValueResponse>()),
	toObject: (filter: Map<string, RangeFilterValueResponse>): Record<string, RangeFilterValueResponse> =>
		Array.from(filter.entries()).reduce<Record<string, RangeFilterValueResponse>>((acc, curr) => {
			const [key, val] = curr;
			acc[key] = val;
			return acc;
		}, {}),
};

const DateRangeFilterMapper = {
	toMap: (filter: Record<string, DateFilterValueResponse>): Map<string, DateFilterValueResponse> =>
		Object.entries(filter).reduce<Map<string, DateFilterValueResponse>>((acc, [key, value]) => {
			acc.set(key, value);
			return acc;
		}, new Map<string, DateFilterValueResponse>()),
	toObject: (filter: Map<string, DateFilterValueResponse>): Record<string, DateFilterValueResponse> =>
		Array.from(filter.entries()).reduce<Record<string, DateFilterValueResponse>>((acc, curr) => {
			const [key, val] = curr;
			acc[key] = val;
			return acc;
		}, {}),
};

const SingleFilterMapper = {
	toMap: (filter: Record<string, string>): Map<string, FilterValueResponse> =>
		Object.entries(filter).reduce<Map<string, FilterValueResponse>>((acc, [key, value]) => {
			acc.set(key, {
				isSelected: true,
				label: value,
				value: value,
			});
			return acc;
		}, new Map<string, FilterValueResponse>()),
	toObject: (filter: Map<string, FilterValueResponse>): Record<string, string> =>
		Array.from(filter.entries()).reduce<Record<string, string>>((acc, curr) => {
			const [key, val] = curr;
			acc[key] = val.value;
			return acc;
		}, {}),
};

/** @throws Error */
export const getFiltersFromUrl = (searchParams: URLSearchParams, urlParam: string): FilterState => {
	const filtersInBase64 = searchParams.get(urlParam);

	const filterObject: UrlParamFilterState = filtersInBase64 ? JSON.parse(filtersInBase64) : [];
	return {
		[FilterTypesEnum.Checkbox]: CheckBoxFilterMapper.toMap(filterObject[FilterTypesEnum.Checkbox]),
		[FilterTypesEnum.Range]: RangeFilterMapper.toMap(filterObject[FilterTypesEnum.Range]),
		[FilterTypesEnum.Date]: DateRangeFilterMapper.toMap(filterObject[FilterTypesEnum.Date]),
		[FilterTypesEnum.Single]: SingleFilterMapper.toMap(filterObject[FilterTypesEnum.Single]),
	};
};

export const convertFilterStateToObject = (filterState: FilterState) => {
	return {
		[FilterTypesEnum.Checkbox]: CheckBoxFilterMapper.toObject(filterState[FilterTypesEnum.Checkbox]),
		[FilterTypesEnum.Range]: RangeFilterMapper.toObject(filterState[FilterTypesEnum.Range]),
		[FilterTypesEnum.Date]: Array.from(filterState[FilterTypesEnum.Date].entries()).reduce<
			Record<string, DateFilterValueResponse>
		>((acc, curr) => {
			const [key, val] = curr;
			acc[key] = val;
			return acc;
		}, {}),
		[FilterTypesEnum.Single]: Array.from(filterState[FilterTypesEnum.Single].entries()).reduce<
			Record<string, string>
		>((acc, curr) => {
			const [key, val] = curr;
			acc[key] = val.value;
			return acc;
		}, {}),
	};
};
/** @throws Error */
export const setFiltersInUrl = (filterState: FilterState, searchParams: URLSearchParams, urlParam: string) => {
	const filterObject: UrlParamFilterState = convertFilterStateToObject(filterState);

	searchParams.set(urlParam, JSON.stringify(filterObject));
	return searchParams;
};
