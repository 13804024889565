import { TranslationsWebsite, OrderHistorySort } from 'generated/data-contracts';

export const getOrderHistoryDesktopHeader = (
	overviewEnabled: boolean,
	translations: TranslationsWebsite | undefined,
): {
	label: string;
	sortBy?: OrderHistorySort;
}[] => {
	if (!translations) return [];
	const orderDetailsTranslation = translations.myOrders.orderDetails;
	return overviewEnabled
		? [
				{
					label: orderDetailsTranslation.orderNumberShort,
					sortBy: OrderHistorySort.OrderNumber,
				},
				{
					label: orderDetailsTranslation.customerRef,
					sortBy: OrderHistorySort.ClientReference,
				},
				{
					label: orderDetailsTranslation.datePlaced,
					sortBy: OrderHistorySort.CreatedDate,
				},
				{
					label: orderDetailsTranslation.brand,
					sortBy: OrderHistorySort.BrandName,
				},
				{
					label: translations.myOrders.account,
					sortBy: OrderHistorySort.AccountName,
				},
				{
					label: orderDetailsTranslation.platform,
					sortBy: OrderHistorySort.SalesChannelName,
				},
				{
					label: translations.myOrders.createdBy,
					sortBy: OrderHistorySort.BasketCreatorName,
				},
				{
					label: orderDetailsTranslation.quantity,
					sortBy: OrderHistorySort.TotalOrderQuantity,
				},
				{
					label: translations.myOrders.totalValue,
					sortBy: OrderHistorySort.TotalOrderNetPrice,
				},
		  ]
		: [
				{
					label: '',
				},
				{
					label: orderDetailsTranslation.styleNumber,
					sortBy: OrderHistorySort.StyleNumber,
				},
				{
					label: orderDetailsTranslation.orderNumberShort,
					sortBy: OrderHistorySort.OrderNumber,
				},
				{
					label: orderDetailsTranslation.estimatedShipping,
					sortBy: OrderHistorySort.DeliveryDate,
				},
				{
					label: orderDetailsTranslation.warehouseEta,
					sortBy: OrderHistorySort.EstimatedDeliveryDate,
				},
				{
					label: orderDetailsTranslation.platform,
					sortBy: OrderHistorySort.SalesChannelName,
				},
				{
					label: orderDetailsTranslation.quantity,
					sortBy: OrderHistorySort.OrderLineQuantityPieces,
				},
				{
					label: orderDetailsTranslation.price,
					sortBy: OrderHistorySort.UnitNetPrice,
				},
				{
					label: orderDetailsTranslation.value,
					sortBy: OrderHistorySort.OrderLineNetPrice,
				},
		  ];
};
