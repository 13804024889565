import React from 'react';
// eslint-disable-next-line local/react-router-dom-useNavigate
import { NavigateFunction, Path, To, useNavigate as useNavigateImport } from 'react-router-dom';
import { encodeSearchParams } from './helpers/encodeSearchParams';

export function useNavigate(): NavigateFunction {
	const navigate = useNavigateImport();
	const navigateFunc: NavigateFunction = React.useCallback(
		(to: To | number, options = {}) => {
			if (typeof to === 'number') {
				return navigate(to);
			}
			if (typeof to === 'string') {
				return navigate(encodeSearchParams(to) ?? '', options);
			}
			const newTo: Partial<Path> = {
				hash: to.hash,
				pathname: to.pathname,
				search: encodeSearchParams(to.search),
			};
			return navigate(newTo, options);
		},
		[navigate],
	);
	return navigateFunc;
}
