import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'library/routing';

type DeliveryDateType = string;
export type DeliveryDateOption = {
	label: string;
	value: DeliveryDateType;
	hitCount?: number;
};
export interface UseBasketDeliveryDateReturnType {
	selectedDeliveryDate: DeliveryDateType | undefined;
	setDeliveryDate: React.Dispatch<React.SetStateAction<DeliveryDateType | undefined>>;
	deliveryDates: DeliveryDateOption[];
	setAvailableDeliveryDates: React.Dispatch<React.SetStateAction<DeliveryDateOption[]>>;
}

const BASKET_DELIVERY_DATE_SEARCH_PARAM = 'deliveryDate';

export const useBasketDeliveryDateFilter = (): UseBasketDeliveryDateReturnType => {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [deliveryDates, setAvailableDeliveryDates] = React.useState<DeliveryDateOption[]>([]);
	const [selectedDeliveryDate, setDeliveryDate] = React.useState<DeliveryDateType | undefined>(() => {
		const value = searchParams.get(BASKET_DELIVERY_DATE_SEARCH_PARAM);
		return value ?? undefined;
	});

	React.useEffect(() => {
		setSearchParams(
			(prev) => {
				const newSearchParams = new URLSearchParams(prev);
				if (selectedDeliveryDate) {
					newSearchParams.set(BASKET_DELIVERY_DATE_SEARCH_PARAM, selectedDeliveryDate);
				} else {
					newSearchParams.delete(BASKET_DELIVERY_DATE_SEARCH_PARAM);
				}
				return newSearchParams;
			},

			{
				replace: true,
				state: location.state,
			},
		);
	}, [setSearchParams, selectedDeliveryDate, location.state]);

	return React.useMemo(
		() => ({
			selectedDeliveryDate,
			setAvailableDeliveryDates,
			deliveryDates,
			setDeliveryDate,
		}),
		[deliveryDates, selectedDeliveryDate],
	);
};
