import { isEmpty } from 'lodash';
import { BasketAvailabilityResponse, Lookups } from 'generated/data-contracts';

const emptyAvailability = {
	hasAvailability: false,
	availability: undefined,
	realTimeTotalInBasket: 0,
	availableStockLeft: 0,
};

type UseStockAvailabilityWithQueryProps = {
	(props: {
		assortmentId?: string;
		quantityInBasket?: number;
		inputValue?: number;
		deliveryDate?: string;
		shouldUseStockAvailability?: boolean | undefined;
		lookups: Lookups;
	}): {
		hasAvailability: boolean;
		availability: BasketAvailabilityResponse | undefined;
		availableStockLeft: number;
		realTimeTotalInBasket: number;
	};
};

/**
 * Custom hook that provides stock availability information based on the given assortmentId, quantity, and value.
 *
 * @param assortmentId - The ID of a freeAssortment or box.
 * @param quantity - The quantity of the item.
 * @param value - The value of the item.
 * @returns An object containing stock availability information.
 */
export const useStockAvailabilityWithQuery: UseStockAvailabilityWithQueryProps = ({
	assortmentId,
	quantityInBasket,
	inputValue,
	deliveryDate,
	shouldUseStockAvailability = false,
	lookups,
}) => {
	const availabilities = lookups.availabilities.availabilities;
	if (!shouldUseStockAvailability) return emptyAvailability;
	if (assortmentId === undefined) return emptyAvailability;

	const variantAvailability = availabilities[assortmentId];
	const hasAvailability = !isEmpty(variantAvailability);
	if (!hasAvailability) return emptyAvailability;

	const { totalAmount, totalInBasket } = variantAvailability;

	let total = totalAmount;
	let inBasket = totalInBasket;

	if (!isEmpty(deliveryDate)) {
		const deliveryDateAvailability = variantAvailability.availabilities.find(({ date }) => date === deliveryDate);

		if (!isEmpty(deliveryDateAvailability)) {
			total = deliveryDateAvailability.amount;
			inBasket = deliveryDateAvailability.inBasket;
		} else {
			return emptyAvailability;
		}
	}

	if (quantityInBasket === undefined || inputValue === undefined)
		return {
			hasAvailability,
			availability: variantAvailability,
			availableStockLeft: total - inBasket,
			realTimeTotalInBasket: 0,
		};

	const realTimeTotalInBasket = inBasket - quantityInBasket + inputValue;
	return {
		hasAvailability,
		availability: variantAvailability,
		availableStockLeft: total - inBasket + quantityInBasket,
		realTimeTotalInBasket,
	};
};
