import React from 'react';
import { Builder, BuilderElement } from '@builder.io/react';
import { useSearchParams } from 'library/routing';
import { isEmpty } from 'lodash';
import { useCmsContentQuery } from 'api/cms';
import { useLayoutQuery } from 'api/layout';
import { ProductBundleQuery, parseBundleQueryParameters } from 'api/product';
import { useTranslationQuery } from 'api/translations';
import ProductList from 'components/features/ProductList';
import { useProductBundleActions } from 'components/features/ProductList/hooks/useProductBundleActions';
import { ProductFilterProps, convertPropsToProductFilter } from '../../shared/ProductsFilters';

interface PLPProps extends ProductFilterProps {
	builderBlock: BuilderElement;
}

export const PLP: React.FunctionComponent<PLPProps> = (props) => {
	const { data: layout } = useLayoutQuery();
	const [searchParams] = useSearchParams();

	const pathWithoutLocale = location.pathname.replace(layout?.frontPageUrl || '', '');
	const content = useCmsContentQuery(pathWithoutLocale, layout?.cmsSettings?.page || '', layout?.audienceTargeting);

	const isSingular =
		(content.data?.data?.blocks?.filter(
			(block) => block.component?.name === 'PLP' || block.component?.name === 'ProductSlider',
		)?.length || 0) === 1;

	const queryParams = parseBundleQueryParameters(searchParams);

	const query: ProductBundleQuery = React.useMemo(
		() => convertPropsToProductFilter(props, 'PLP') as ProductBundleQuery,
		[props],
	);

	if (isSingular && !isEmpty(queryParams)) {
		Object.entries(queryParams).forEach(([paramKey, paramValue]) => {
			if (!paramKey || !paramValue?.length) return;
			query[paramKey] = paramValue;
		});
	}

	const actions = useProductBundleActions(query, isSingular ? content.data?.id : undefined);

	actions.uniqueFilterId = props.builderBlock?.id || new Date().toString();
	const { data: translations } = useTranslationQuery();

	// This is only shown in the builder preview.
	const isPreviewingFromBuilder = Builder.isEditing;
	if (isPreviewingFromBuilder && actions.pageData?.pagingInformation.totalNumberOfItems === 0) {
		return <h2>{translations?.productList.noResults}</h2>;
	}

	return <ProductList actions={actions} isPlpPlaceholder />;
};
