import { FilterResponse, FilterValueResponse } from 'generated/data-contracts';
import { CheckboxFilterType, CheckboxFilterValue, FilterTypesEnum } from '../types/FilterTypes';

export const convertFilterValueResponseToCheckboxFilterValue = (value: FilterValueResponse): CheckboxFilterValue => {
	return {
		isSelected: value.isSelected,
		label: value.label,
		value: value.value,
		hitCount: value.hitCount ?? undefined,
	};
};

export const convertFilterResponseToCheckboxFilter = (filter: FilterResponse): CheckboxFilterType => {
	return {
		id: filter.id,
		label: filter.name,
		type: FilterTypesEnum.Checkbox,
		values: filter.values?.map(convertFilterValueResponseToCheckboxFilterValue) ?? [],
	};
};
