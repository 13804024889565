import React from 'react';
import classNames from 'classnames';
import { useAddAllToFavouriteListMutation } from 'api/favouriteList';
import { useLayoutQuery } from 'api/layout';
import { ProductListPageQuery } from 'api/search';
import { useTranslationQuery } from 'api/translations';
import { Button, Icon, AlertTypes, Link } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { SearchType, PageType, TranslationsWebsite, Area } from 'generated/data-contracts';
import styles from './AddAllToFavouriteList.module.scss';

interface AddAllToFavouriteListProps {
	filterQuery: ProductListPageQuery;
	className?: string;
}

export const AddAllToFavouriteList: React.FunctionComponent<AddAllToFavouriteListProps> = ({
	filterQuery,
	className,
}) => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();
	const { mutate: addAllToFavouriteList, isPending: isAddingAllToFavouriteList } = useAddAllToFavouriteListMutation();

	const handleAddAllToFavouriteList = React.useCallback((): void => {
		addAllToFavouriteList(
			{
				searchType: SearchType.Family,
				...filterQuery,
			},
			{
				onSuccess: () => {
					notificationActions.addNotification({
						type: AlertTypes.SUCCESS,
						options: {
							duration: 5000,
						},
						children: <NotificationMessage translations={translations} />,
					});
				},
			},
		);
	}, [addAllToFavouriteList, filterQuery, notificationActions, translations]);

	return (
		<Button
			onClick={handleAddAllToFavouriteList}
			className={classNames(styles.addToFavouriteListButton, className)}
			variant="secondary"
			size="sm"
			isLoading={isAddingAllToFavouriteList}
		>
			<Icon className="u-mr-2xs" name="plus" size="md" />
			{translations?.productList.addAllToFavouriteList}
		</Button>
	);
};

const NotificationMessage: React.FunctionComponent<{
	translations?: TranslationsWebsite;
}> = ({ translations }) => {
	const { data: layout } = useLayoutQuery();

	const favouriteListUrl = layout?.staticLinks?.find((link) => link.pageType === PageType.FavouriteList)?.url;

	return (
		<>
			{translations?.productList.allStylesAddedToFavouriteList}
			&nbsp;
			<Link
				to={{
					pathname: favouriteListUrl,
					search: `?favouriteListId=${layout?.currentFavouriteListId}`,
				}}
				state={{ area: Area.StaticPages, pageType: PageType.FavouriteList }}
				className={styles.viewFavouriteListLink}
			>
				{translations?.favouriteList.viewFavouriteList}
			</Link>
		</>
	);
};
