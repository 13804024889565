import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/shared';
import Toggle from 'components/shared/Toggle';
import { useHideBasketTotals } from 'components/shared/hooks/useHideBasketTotals';
import styles from './ToggleBasketTotals.module.scss';

export interface ToggleHideBasketTotalsProps {}

const ToggleHideBasketTotals: React.FunctionComponent<ToggleHideBasketTotalsProps> = () => {
	const { hideBasketTotals, handleClick } = useHideBasketTotals();
	const { data: translations } = useTranslationQuery();
	return (
		<Tooltip placement="top">
			<TooltipTrigger className={classNames(styles.hideBasketTotals)}>
				<span className={styles.wrapper}>
					{translations?.myProfile.hideBasketTotals}
					<Toggle value={hideBasketTotals} onChange={handleClick} variant="secondary" />
				</span>
			</TooltipTrigger>
			<TooltipContent className={styles.tooltipText}>
				{translations?.myProfile.hideBasketTotalsTooltip}
			</TooltipContent>
		</Tooltip>
	);
};

export default ToggleHideBasketTotals;
