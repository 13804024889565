import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'library/routing';
import { SupportedCssUnits } from 'types/cssUnits';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { Logo } from 'components/shared/Logo';
import { Area } from 'generated/data-contracts';
import styles from './DrawerHeaderSubBrandMenu.module.scss';

type LogoSize = {
	width?: SupportedCssUnits;
	height?: SupportedCssUnits;
};
export interface DrawerHeaderSubBrandMenuProps {
	className?: string;
	closeButtonClassName?: string;
	logo?: string;
	logoSize?: LogoSize;
	showBrandSelectorMenu?: boolean;
	showSubBrandSelectorMenu?: boolean;
	handleClose?: () => void;
	handleToggle?: () => void;
	handleToggleSubBrandMenu?: () => void;
}

export const DrawerHeaderSubBrandMenu: React.FunctionComponent<DrawerHeaderSubBrandMenuProps> = ({
	className,
	closeButtonClassName,
	logo,
	logoSize,
	showBrandSelectorMenu,
	showSubBrandSelectorMenu,
	handleClose,
	handleToggle,
	handleToggleSubBrandMenu,
}) => {
	const navigate = useNavigate();
	const { data: layout } = useLayoutQuery();
	const frontPageUrl = layout?.frontPageUrl;
	const { data: translations } = useTranslationQuery();

	const [showingSubBrandContent, setShowingSubBrandContent] = React.useState(false);

	const route = {
		area: Area.CMS,
	};

	const goToFrontPage = (): void => {
		// Setting link url this way makes sure mobile menu closes
		// even though user is already on that page
		navigate(frontPageUrl || '/', { state: route });
		handleClose?.();
	};

	const onToggleClick = (): void => {
		handleToggle?.();
	};

	const onToggleSubBrandContent = (): void => {
		setShowingSubBrandContent(!showingSubBrandContent);
		handleToggleSubBrandMenu?.();
	};

	return (
		<header className={classNames(styles.header, className)}>
			{showBrandSelectorMenu && (
				<Button
					className={styles.logoCta}
					title={translations?.shared.siteName}
					variant={'ghost'}
					size={'sm'}
					hasOnlyIcon
					onClick={onToggleClick}
				>
					<Icon name={'apps'} size={'sm'} />
				</Button>
			)}
			<div className={classNames({ [styles.brandWrapper]: logo })}>
				<Button
					className={classNames(styles.logoCta)}
					title={translations?.shared.siteName}
					hasNoStyles
					onClick={goToFrontPage}
				>
					<Logo
						name={logo || 'bestsellerLogo'}
						width={logoSize?.width || '7.5rem'}
						height={logoSize?.height || '1.5rem'}
					/>
				</Button>
				{showSubBrandSelectorMenu && (
					<Button variant={'ghost'} size={'sm'} hasOnlyIcon onClick={onToggleSubBrandContent}>
						<Icon name={'chevronDown'} size={'lg'} {...(showingSubBrandContent && { rotate: 180 })} />
					</Button>
				)}
			</div>

			<Button className={closeButtonClassName} variant="outline" size="sm" hasOnlyIcon onClick={handleClose}>
				<Icon name={'close'} size={'md'} />
			</Button>
		</header>
	);
};
