import React from 'react';
import classNames from 'classnames';
import { formatTranslation, formatTranslationAsJsx, isScannerApp } from 'helpers';
import { useNavigate } from 'library/routing';
import { useLayoutQuery } from 'api/layout';
import { ProductListPageQuery } from 'api/search';
import { useTranslationQuery } from 'api/translations';
import { Link, FoldOut, Button, Icon } from 'components/shared';
import { Area, PageType, PagingInformationResponse, ProductListCategoryResponse } from 'generated/data-contracts';
import { AddAllToFavouriteList } from './AddAllToFavouriteList';
import styles from './Header.module.scss';

interface HeaderProps {
	phrase?: string | null;
	didYouMean?: string | null;
	pagingInformation: PagingInformationResponse;
	category?: ProductListCategoryResponse;
	layoutSwitch: string;
	hasFilters?: boolean;
	isMobile?: boolean;
	loading?: boolean;
	filterQuery?: ProductListPageQuery;
}

export const Header: React.FunctionComponent<HeaderProps> = ({
	pagingInformation,
	phrase,
	didYouMean,
	category,
	layoutSwitch,
	hasFilters,
	loading,
	isMobile,
	filterQuery,
}) => {
	const { data: layout } = useLayoutQuery();
	const { data: translations } = useTranslationQuery();
	const staticLinks = layout?.staticLinks;
	const searchUrl = staticLinks?.find((item) => item.pageType === PageType.Search)?.url;
	const noResults = !pagingInformation.totalNumberOfItems && !hasFilters && !loading;
	const navigate = useNavigate();

	const handleNavigateBack = (): void => {
		navigate(-1);
	};

	const showBackButton = isScannerApp && history.length > 1;

	return (
		<header className={classNames(styles.header, 'u-container-full', { [styles.hasBottomMargin]: showBackButton })}>
			<div className={styles.innerWrapper}>
				<div className={styles.topInfo}>
					{showBackButton && (
						<div className={styles.backButtonWrapper}>
							<Button
								variant={'secondary'}
								hasOnlyIcon
								size="sm"
								className={styles.backButton}
								title={translations?.shared.back}
								onClick={handleNavigateBack}
							>
								<Icon className={styles.icon} name={'chevronDown'} size={'md'} rotate={90} />
							</Button>
						</div>
					)}

					<div
						className={classNames({
							[styles.hasBackButton]: showBackButton,
							[styles.hasBottomPadding]: !showBackButton,
						})}
					>
						{noResults ? (
							<>
								<h1 className={styles.title}>
									{phrase
										? formatTranslation(translations?.productList.noResultsFor, { 0: phrase })
										: translations?.productList.noProducts}
								</h1>

								{didYouMean && (
									<p>
										{formatTranslationAsJsx(translations?.productList.didYouMean || '', {
											0: (
												<Link
													to={{ pathname: searchUrl, search: `?phrase=${didYouMean}` }}
													state={{ area: Area.StaticPages, pageType: PageType.Search }}
													isRichTextLink
													className={styles.didYouMeanText}
												>
													{didYouMean}?
												</Link>
											),
										})}
									</p>
								)}

								{phrase && (
									<div className={styles.suggestions}>
										<h2 className={styles.suggestionsHeading}>
											{translations?.productList.searchSuggestionsHeadline}
										</h2>

										<ul className={styles.suggestionsList}>
											<li>{translations?.productList.searchSuggestionsText1}</li>
											<li>{translations?.productList.searchSuggestionsText2}</li>
											<li>{translations?.productList.searchSuggestionsText3}</li>
										</ul>
									</div>
								)}
							</>
						) : phrase ? (
							<>
								<h1 className={styles.title}>
									{formatTranslation(translations?.productList.resultsFor, { 0: phrase })}
								</h1>

								{pagingInformation && pagingInformation.totalNumberOfItems > 0 && (
									<p>
										{formatTranslation(translations?.productList.resultsFound, {
											0: pagingInformation.totalNumberOfItems,
										})}
									</p>
								)}
							</>
						) : (
							<>
								<h1 className={styles.title}>
									{category?.name ?? translations?.productList.categoryAll}
								</h1>

								{category?.description && !isMobile && (
									<FoldOut
										className={`u-hide-${layoutSwitch}-max`}
										forceDisable={category?.description.length < 260}
									>
										<p>{category.description}</p>
									</FoldOut>
								)}
							</>
						)}
					</div>
				</div>
				{filterQuery && pagingInformation.totalNumberOfItems > 0 && (
					<AddAllToFavouriteList filterQuery={filterQuery} className={styles.favouriteListButton} />
				)}
			</div>
		</header>
	);
};
