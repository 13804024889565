import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Vmi } from 'components/features/Header/MyProfile/VMIDrawer/VMI';
import { Button, Drawer, DrawerHeader, DrawerHeaderWBackButton, DrawerLevel, Icon } from 'components/shared';
import styles from './VMIDrawer.module.scss';

interface VmiDrawerProps {
	handleClose?: () => void;
}

export const VmiDrawer: React.FunctionComponent<VmiDrawerProps> = ({ handleClose }) => {
	const { data: translations } = useTranslationQuery();

	const [isOpen, setIsOpen] = React.useState(false);

	const handleBack = () => setIsOpen(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	return (
		<>
			<Button className={styles.button} hasNoStyles onClick={handleOpen}>
				<div className={styles.titleWrapper}>
					<Icon className={'u-mr-sm'} name={'vmi'} size={'md'} />
					<span className={styles.title}>{translations?.vmi.title}</span>
				</div>

				<Icon className={'u-ml-sm'} name={'chevronDown'} size={'lg'} rotate={270} />
			</Button>

			{isOpen &&
				(handleClose ? (
					<DrawerLevel isActive={isOpen}>
						<DrawerHeaderWBackButton
							handleBack={handleBack}
							handleClose={handleClose}
							title={translations?.vmi.title}
						/>
						<Vmi handleClose={handleClose} />
					</DrawerLevel>
				) : (
					<Drawer onClose={handleBack}>
						<DrawerHeader handleClose={handleBack} title={translations?.vmi.title} />
						<Vmi handleClose={handleBack} />
					</Drawer>
				))}
		</>
	);
};
