import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { ProductOrderType } from 'generated/data-contracts';
import { useProductCardContext } from '../../context/ProductCardContext';
import BottomSplash from '../BottomSplash';

export const Inbound: FC = () => {
	const { product } = useProductCardContext();
	const { data: translations } = useTranslationQuery();
	const splash = product.splashes[ProductOrderType.Inbound];
	if (!splash || !splash.quantity) {
		return null;
	}
	let translation = translations?.productCard.inbound;
	if (splash.inMultipleAccounts) {
		translation = translations?.productCard.inboundForMultipleStores;
	}
	return (
		<BottomSplash
			icon="boxDelivery"
			text={translation}
			quantity={splash.quantity}
			hideQuantity={splash.inMultipleAccounts}
		/>
	);
};
