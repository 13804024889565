import React, { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { useScrollEnd } from '../hooks/useScrollEnd';
import styles from './Carousel.module.scss';
import { CarouselBadge } from './CarouselBadge';
import { CarouselItem } from './CarouselItem';
import { CarouselNavigation } from './CarouselNavigation';
import { useCarouselStore } from './state/CarouselContext';

export interface CarouselProps extends PropsWithChildren {
	className?: string;
	listClassName?: string;
	slideClassName?: string;
	selectedImage?: number;
	Navigation?: React.ReactNode;
	Badge?: React.ReactNode;
}

export type CarouselSubComponents = {
	Navigation: typeof CarouselNavigation;
	Badge: typeof CarouselBadge;
};

export const Carousel: React.FunctionComponent<CarouselProps> & CarouselSubComponents = ({
	children,
	selectedImage,
	className,
	listClassName,
	slideClassName,
	Badge,
	Navigation,
}: CarouselProps) => {
	const {
		carouselRef,
		config,
		slides: { activeSlide, setIntersectionEnabled, intersectionEnabled, setNumberOfSlides },
	} = useCarouselStore();

	const slides = useMemo(() => React.Children.toArray(children), [children]);
	useEffect(() => {
		setNumberOfSlides(slides.length);
	}, [setNumberOfSlides, slides.length]);

	const id = useRef(uniqueId('carousel')).current;

	useEffect(() => {
		if (carouselRef.current && !intersectionEnabled && carouselRef.current.clientWidth > 0) {
			if (selectedImage !== undefined && selectedImage !== activeSlide) {
				carouselRef.current.scrollTo({
					left: selectedImage * carouselRef.current.clientWidth,
					behavior: 'instant',
				});
				setIntersectionEnabled(true);
			} else {
				carouselRef.current.scrollTo({
					left: activeSlide * carouselRef.current.clientWidth,
					behavior: 'instant',
				});
				setIntersectionEnabled(true);
			}
		}
	}, [
		selectedImage,
		setIntersectionEnabled,
		intersectionEnabled,
		activeSlide,
		carouselRef,
		carouselRef.current?.clientWidth,
	]);

	useScrollEnd(carouselRef, () => {
		setIntersectionEnabled(true);
	});

	const items = useMemo(
		() =>
			slides.map((child, index) => (
				<CarouselItem
					key={`${id}-item-${index}`}
					index={index}
					listRef={carouselRef}
					className={slideClassName}
				>
					{child}
				</CarouselItem>
			)),
		[slides, id, carouselRef, slideClassName],
	);

	return (
		<section className={classNames(className, styles.carousel)}>
			<div className={styles.container}>
				<ul
					className={classNames(listClassName, styles.slideList, 'u-scroll-wrapper-x', 'u-scroll-bar-hidden')}
					ref={carouselRef}
					id={id}
				>
					{items}
				</ul>
				{config.showNavigation && Navigation}
				{config.showBadge && Badge}
			</div>
		</section>
	);
};

Carousel.Badge = CarouselBadge;
Carousel.Navigation = CarouselNavigation;
