import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { skeletonProductCards } from 'components/features/ProductList/helpers';
import { ProductItemResponse } from 'generated/data-contracts';
import { useViewportSize } from 'helpers/useViewportSize';
import NewSlider from '../NewSlider';
import { PlpProductCard } from '../ProductCard/PlpProductCard';
import styles from './ProductSlider.module.scss';

interface ProductSliderProps {
	products?: ProductItemResponse[] | null;
	numberOfProductsShown?: number;
	infiniteScroll?: boolean;
	autoScroll?: boolean;
	autoScrollDelay?: number;
}

/**
 * ProductSlider component, used for showing product cards next to each other.
 * Initiates a slider, when enough products passed.
 */
export const ProductSlider: React.FunctionComponent<ProductSliderProps> = ({
	products,
	numberOfProductsShown: _numberOfProductsShownFromProps,
	infiniteScroll,
	autoScroll,
	autoScrollDelay,
}) => {
	const id = useRef(uniqueId('product-slider')).current;
	const size = useViewportSize();
	const { numberOfProductsShown, cardWidth, isNavigationShown } = useMemo(() => {
		switch (size.size) {
			case 'xs':
				return { numberOfProductsShown: 2, isNavigationShown: false };
			case 'sm':
			case 'md':
				return { numberOfProductsShown: 4, isNavigationShown: true };
			case 'lg':
			case 'xl':
			default:
				return { cardWidth: '18rem', isNavigationShown: true };
		}
	}, [size]);

	const shownProducts: (ProductItemResponse | undefined)[] = products ? products : skeletonProductCards(1);

	if (shownProducts.length < 1) {
		return (
			<div
				hidden
				aria-hidden
				className={classNames(styles.cardWrapper, styles.hidden, 'u-grid u-grid-cols-custom')}
			>
				<PlpProductCard />
			</div>
		);
	}
	if ((products ?? []).length > 3) {
		return (
			<NewSlider
				showNavigation={isNavigationShown}
				numberOfItemsShown={_numberOfProductsShownFromProps ?? numberOfProductsShown}
				slideWidth={_numberOfProductsShownFromProps ? undefined : cardWidth}
				arrowsClassName={styles.productSliderArrows}
				arrowContainerClassName={styles.productSliderArrowsContainer}
				infiniteScroll={infiniteScroll}
				autoScroll={autoScroll}
				autoScrollDelay={autoScrollDelay}
			>
				{products?.map((product) => <PlpProductCard product={product} key={`${id}-${product.id}`} />)}
			</NewSlider>
		);
	}

	return (
		<div className={classNames(styles.cardWrapper, 'u-grid u-grid-cols-custom')}>
			{shownProducts.map((product, i) => (
				<PlpProductCard product={product} key={`${id}-${product?.id ?? i}`} />
			))}
		</div>
	);
};
