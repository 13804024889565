import React from 'react';
import classNames from 'classnames';
import styles from './ProductCardImageWrapper.module.scss';

export interface ProductCardImageWrapperProps {
	TopLeftSplashes?: React.ReactNode;
	topLeftClassName?: string;
	TopRightSplashes?: React.ReactNode;
	topRightClassName?: string;
	Image?: React.ReactNode;
	BottomLeftSplashes?: React.ReactNode;
	bottomLeftClassName?: string;
	BottomRightSplashes?: React.ReactNode;
	bottomRightClassName?: string;
	className?: string;
}

export const ProductCardImageWrapper: React.FunctionComponent<ProductCardImageWrapperProps> = ({
	Image,
	BottomLeftSplashes,
	BottomRightSplashes,
	TopLeftSplashes,
	TopRightSplashes,
	bottomLeftClassName,
	bottomRightClassName,
	topLeftClassName,
	topRightClassName,
	className,
}) => {
	return (
		<div className={classNames(className, styles.imgWrapper)}>
			{Image}
			<div className={classNames(topLeftClassName, styles.topLeft)}>{TopLeftSplashes}</div>
			<div className={classNames(topRightClassName, styles.topRight)}>{TopRightSplashes}</div>
			<div className={classNames(bottomLeftClassName, styles.bottomLeft)}>{BottomLeftSplashes}</div>
			<div className={classNames(bottomRightClassName, styles.bottomRight)}>{BottomRightSplashes}</div>
		</div>
	);
};
