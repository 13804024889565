const URI_ENCODED_CHARACTERS = {
	'+': '%20',
	'#': '%23',
	'&': '%26',
};

/** Reverses the object */
const URI_DECODED_CHARACTERS = Object.entries((r) => r)
	.map<Record<string, string>>(([key, value]) => {
		return {
			[value]: key,
		};
	})
	.reduce((acc, cur) => {
		return {
			...acc,
			...cur,
		};
	}, {});

const runURLThroughEncoding = (url: string, chars: Record<string, string>) => {
	const patternString = `[${Object.keys(chars).join('')}]`;
	const pattern = new RegExp(patternString, 'g');
	return url.replace(pattern, (match) => chars[match]);
};

/**
 * Does custom encoding of the URL. Reason for this is that the default `encodeURIComponent`
 * encodes a lot that is not really required.
 * This makes it that the URL grows in size and also is a lot harder to read. The `encodeURIComponent` also
 * encodes EVERYTHING every time it's run. Meaning that `%20` will be encoded to `%2520`
 *
 * This function should encode only the things required and nothing else
 *
 * @param url The URL to encode. This can be either only the searchParams, or a full URL
 */
export const encodeSearchParams = (url?: string) => {
	if (url === undefined) {
		return;
	}
	const [path, searchParams] = url.split('?');
	const urlParams = new URLSearchParams(searchParams);
	const encodedUrlParams: string[] = [];
	const encodeString = (val: string) => runURLThroughEncoding(decodeURIComponent(val), URI_ENCODED_CHARACTERS);
	urlParams.forEach((value, key) => {
		encodedUrlParams.push(`${encodeString(key)}=${encodeString(value)}`);
	});
	return path + `?` + encodedUrlParams.join('&');
};

/**
 * Does custom decoding of the URL. Reason for this is that the default `encodeURIComponent`
 * encodes a lot that is not really required.
 * This makes it that the URL grows in size and also is a lot harder to read. The `encodeURIComponent` also
 * encodes EVERYTHING every time it's run. Meaning that `%20` will be encoded to `%2520`
 *
 * This function should encode only the things required and nothing else
 *
 * @param url The URL to decode. This can be either only the searchParams, or a full URL
 */
export const decodeSearchParams = (url: string): string => {
	const decodedString = url;

	return runURLThroughEncoding(decodedString, URI_DECODED_CHARACTERS);
};
