import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useProductCardContext } from '../context/ProductCardContext';
import styles from './ProductCardThumbnails.module.scss';
import { ProductThumbList, ThumbProps } from './ProductThumbList';

export interface ProductCardThumbnailsProps {
	className?: string;
}

export const ProductCardThumbnails: React.FunctionComponent<ProductCardThumbnailsProps> = ({ className }) => {
	const { setActiveMaster, isSkeleton, route, product } = useProductCardContext();

	const thumbProps = React.useMemo(() => {
		return product.orderedMastersList.map((r): ThumbProps => {
			const master = product.masters[r];
			return {
				colour: master.colour,
				id: master.id,
				image: master.primaryImage,
				hasImage: !isEmpty(master.primaryImage),
				url: master.url,
				isEnabledForCmi: master.isEnabledForCmi,
			};
		});
	}, [product.masters, product.orderedMastersList]);
	return (
		<ProductThumbList
			className={classNames(styles.productCardThumbList, className)}
			items={thumbProps}
			isLazyImage
			route={route}
			hasHoverEffect
			setCurrentActiveMaster={setActiveMaster}
			isSkeleton={isSkeleton}
		/>
	);
};
