import {
	keepPreviousData,
	useMutation,
	UseMutationResult,
	useQuery,
	useQueryClient,
	UseQueryResult,
} from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { useBasketContext } from 'components/features/NewBasket/context/BasketContext';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Basket as BasketApi } from 'generated/Basket';
import { BasketExport } from 'generated/BasketExport';
import {
	BasketDetailsRequest,
	BasketDetailsResponse,
	BasketFilterType,
	BasketId,
	BasketListResponse,
	BasketTotalQuantityAndShortenedPriceResponse,
	CreateBasketRequest,
	ErrorResult,
	PDFDownloadWithSummaryResponseWrapper,
	ProblemDetails,
	RouteResponse,
	SetBasketResponse,
	ShipToId,
	UpdateNameAndShipTosForBasketRequest,
} from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { messageToApp } from 'helpers/app';
import { formatTranslation } from 'helpers/stringHelpers';
import { useAppStore } from 'store/appStore';
import { useTranslationQuery } from './translations';

export const useBasketCreateMutation = (): UseMutationResult<
	HttpResponse<BasketDetailsResponse, ErrorResult | void>,
	HttpResponse<ErrorResult>,
	{ basketId: BasketId; query: UpdateNameAndShipTosForBasketRequest; host?: string; cookies?: string }
> => {
	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketSetorderreferenceandshiptosCreate(variables.basketId, variables.query);
		},
	});
};
export const useSelectBasketMutation = (): UseMutationResult<
	HttpResponse<SetBasketResponse, ErrorResult | void>,
	HttpResponse<ErrorResult>,
	{
		basketId: number;
		productFamilyId: string | undefined;
		host?: string;
		cookies?: string;
	}
> => {
	const segmentationId = useAppStore((state) => state.segmentationId);

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			const query = { segmentationId, productFamilyId: variables.productFamilyId };

			return basketApi.basketSetbasketCreate(variables.basketId, query);
		},
	});
};

interface AutoSelectBasketRequest {
	returnUrl?: string | null;
	shipToIds: ShipToId[];
}

export const useBasketTryAutoSelectMutation = (): UseMutationResult<
	HttpResponse<RouteResponse, void>,
	HttpResponse<void>,
	AutoSelectBasketRequest
> => {
	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({ baseUrl: undefined, baseApiParams: { headers: setHeaders() } });

			return basketApi.basketCreateorupdatebasketbyshiptosCreate({
				shipToIds: variables.shipToIds,
				returnUrl: variables.returnUrl,
			});
		},
	});
};

export const useBasketTotalQuantityQuery = (): UseQueryResult<number> => {
	const segmentationId = useAppStore((state) => state.segmentationId);

	return useQuery({
		queryKey: queryKeys.basket.quantity(segmentationId).queryKey,
		queryFn: async (): Promise<number> => {
			const basketApi = new BasketApi({ baseApiParams: { headers: setHeaders() } });

			const response = await basketApi.basketTotalquantityandshortenedpriceList();

			return response.data.totalQuantity ?? 0;
		},
	});
};

export const useMiniBasketQuantityQuery = (
	host?: string,
	cookies?: string,
): UseQueryResult<BasketTotalQuantityAndShortenedPriceResponse> => {
	const segmentationId = useAppStore((state) => state.segmentationId);

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.basket.miniQuantity(segmentationId).queryKey,
		queryFn: async (): Promise<BasketTotalQuantityAndShortenedPriceResponse> => {
			const basketApi = new BasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });

			const response = await basketApi.basketTotalquantityandshortenedpriceList();

			if (response.ok) {
				messageToApp({
					type: 'miniBasketQuantity',
					quantity: response.data.totalQuantity ?? 0,
				});
			}

			return response.data;
		},
	});
};

export const useBasketFamilyQuantitiesQuery = (
	host?: string,
	cookies?: string,
): UseQueryResult<Record<string, number>> => {
	const segmentationId = useAppStore((state) => state.segmentationId);

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.basket.productCardQuantity(segmentationId).queryKey,
		queryFn: async (): Promise<Record<string, number>> => {
			const basketApi = new BasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });

			const response = await basketApi.basketFamilytotalquantityList();

			return response.data.familyTotals;
		},
	});
};

export const useBasketQuery = (
	shipmentFilterValue?: string,
	shipmentFilterType?: BasketFilterType,
	host?: string,
	cookies?: string,
): UseQueryResult<BasketDetailsResponse> => {
	const segmentationId = useAppStore((state) => state.segmentationId);

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.basket.current(segmentationId, shipmentFilterValue).queryKey,
		queryFn: async (): Promise<BasketDetailsResponse> => {
			const basketApi = new BasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });

			const response = await basketApi.basketList({
				shipmentFilterType: shipmentFilterType || BasketFilterType.All,
				shipmentFilterValue: shipmentFilterValue,
			});

			return response.data;
		},
		staleTime: 2000,
		placeholderData: keepPreviousData,
	});
};

export interface BasketFilters {
	BasketCreators?: string[];
	BrandIds?: number[];
	SearchPhrase?: string;
	ShipToIds?: string[];
	SubBrandIds?: number[];
}

export const useBasketListQuery = (
	filters?: BasketFilters,
	disable?: boolean,
	host?: string,
	cookies?: string,
): UseQueryResult<BasketListResponse> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.basket.list(segmentationId, filters).queryKey,
		queryFn: async (): Promise<BasketListResponse> => {
			const basketApi = new BasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });
			const response = await basketApi.basketListList({
				...filters,
			});

			return response.data;
		},
		enabled: !disable,
	});
};

type UpdateOrderReferenceRequest = { basketId: BasketId; newBasketName: string; host?: string; cookies?: string };
export const useUpdateOrderReferenceMutation = (): UseMutationResult<
	HttpResponse<void>,
	HttpResponse<ProblemDetails>,
	UpdateOrderReferenceRequest
> => {
	const queryClient = useQueryClient();
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useMutation({
		mutationFn: async ({ basketId, newBasketName, host, cookies }) => {
			const basketApi = new BasketApi({
				baseUrl: host,
				baseApiParams: { headers: setHeaders(cookies) },
			});

			return basketApi.basketSetorderreferenceCreate(basketId, newBasketName);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: queryKeys.newBasket.current._def });
			// basket list cache should be invalidated because it contains the old basket reference.
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.list._def });
		},
		onError: (err) => {
			if (err.status === 500) {
				notificationActions.addNotification({
					type: AlertTypes.DANGER,
					children: formatTranslation(translations?.shared.genericErrorMsg, {}),
				});
			}
		},
	});
};

export const useCreateBasketMutation = (): UseMutationResult<
	HttpResponse<SetBasketResponse>,
	HttpResponse<ErrorResult>,
	{ query: { body: CreateBasketRequest; familyId?: string }; host?: string; cookies?: string }
> => {
	const { data: translations } = useTranslationQuery();

	const queryClient = useQueryClient();
	const { notificationActions } = useNotificationContext();

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketCreateCreate(variables.query.body, { productFamilyId: variables.query.familyId });
		},
		onSuccess: () => {
			queryClient.invalidateQueries();
			messageToApp({ type: 'basketChanged' });
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(translations?.basket.invalidShipToIds, {}),
			});
		},
	});
};

export const useDeleteBasketsMutation = (): UseMutationResult<
	HttpResponse<BasketListResponse>,
	HttpResponse<ErrorResult>,
	{ query: { data: BasketId[] }; host?: string; cookies?: string }
> => {
	const { notificationActions } = useNotificationContext();

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketDeletemanyCreate(variables.query.data);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.list._def });
		},
		onError: (err) => {
			notificationActions.addNotification({
				children: err.data.detail,
				type: AlertTypes.DANGER,
			});
		},
	});
};

export const useBasketPdfMutation = (): UseMutationResult<
	HttpResponse<PDFDownloadWithSummaryResponseWrapper, void | ProblemDetails>,
	HttpResponse<void>
> => {
	const {
		filter,
		deliveryDate: { selectedDeliveryDate },
	} = useBasketContext();
	const query: BasketDetailsRequest = {
		filterType: filter,
		filterDate: selectedDeliveryDate,
	};

	return useMutation({
		mutationFn: async () => {
			const basketExportApi = new BasketExport({ baseApiParams: { headers: setHeaders() } });

			return await basketExportApi.basketexportExportaspdfCreate(query);
		},
	});
};

export const useBasketExcelMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void>
> => {
	const {
		filter,
		deliveryDate: { selectedDeliveryDate },
	} = useBasketContext();

	const query: BasketDetailsRequest = {
		filterType: filter,
		filterDate: selectedDeliveryDate,
	};

	return useMutation({
		mutationFn: async () => {
			const basketExportApi = new BasketExport({ baseApiParams: { headers: setHeaders() } });

			return await basketExportApi.basketexportExportasexcelCreate(query, {
				format: 'blob',
			});
		},
	});
};

export const useBasketMediaContentMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void>
> => {
	const {
		filter,
		deliveryDate: { selectedDeliveryDate },
	} = useBasketContext();

	const query: BasketDetailsRequest = {
		filterType: filter,
		filterDate: selectedDeliveryDate,
	};

	return useMutation({
		mutationFn: async () => {
			const basketExportApi = new BasketExport({ baseApiParams: { headers: setHeaders() } });

			return await basketExportApi.basketexportExportasmediacontentCreate(query, {
				format: 'blob',
			});
		},
	});
};
