import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { clamp } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { NOW_DELIVERY_DATE_VALUE } from 'components/features/NewBasket/shared/basketTypes';
import { Icon, IconName, ProgressBar } from 'components/shared';
import { BasketAvailability, BasketAvailabilityResponse } from 'generated/data-contracts';
import { sizePopupStockBarId } from 'helpers/globalVars';
import styles from './StockAvailabilityDesktop.module.scss';


type StockAvailabilityDesktopProps = {
	quantity: number;
	ean?: string;
	availability: BasketAvailabilityResponse | undefined;
	renderInPortal?: boolean;
};

type GetStockAvailabilityInfoPros = {
	(
		availabilityItem: BasketAvailability,
		remainingQuantity: number,
	): { clampedItemQuantity: number; icon: IconName; accentColorClass: string; title: string };
};
const getStockAvailabilityInfo: GetStockAvailabilityInfoPros = (availabilityItem, remainingQuantity) => {
	let accentColorClass = styles.incomingBarAccentColor;
	let icon: IconName = 'backOrder';
	const title = availabilityItem.name;
	if (availabilityItem.date === NOW_DELIVERY_DATE_VALUE) {
		icon = 'truck';
		accentColorClass = styles.stockBarAccentColor;
	}

	const clampedItemQuantity = clamp(remainingQuantity, 0, availabilityItem.amount);
	return { clampedItemQuantity, icon, accentColorClass, title };
};

export const StockAvailabilityDesktop: FC<StockAvailabilityDesktopProps> = ({
	quantity,
	availability,
	ean,
	renderInPortal = true,
}) => {
	const portalId = ean ? `${sizePopupStockBarId}-${ean}` : sizePopupStockBarId;
	const sizePopupStockBarPortal: HTMLElement | null = document.getElementById(portalId);

	const { data: translations } = useTranslationQuery();
	if (!availability) return null;

	let remainingQuantity = quantity;
	const hasNowDelivery = availability.availabilities[0].date === NOW_DELIVERY_DATE_VALUE;
	const hideExtraIconsIndex = hasNowDelivery ? 2 : 1;

	const Component = (
		<div className={styles.stockWrapper}>
			<span className={styles.stockTitle}>{translations?.basket?.stockTitle}</span>
			<ul className={styles.stockList}>
				{availability.availabilities.map((availabilityItem, i) => {
					const { clampedItemQuantity, icon, accentColorClass, title } = getStockAvailabilityInfo(
						availabilityItem,
						remainingQuantity,
					);
					remainingQuantity = Math.max(0, remainingQuantity - clampedItemQuantity);

					return (
						<li
							key={`availability-item-${i}`}
							className={classNames(styles.stockListItem, {
								[styles.firstStockListItem]: i === 0 && hasNowDelivery,
							})}
						>
							<div className={styles.stockBarWrapper}>
								{i < hideExtraIconsIndex && <Icon size={'sm'} name={icon} />}
								<div className={styles.stockBar}>
									<ProgressBar
										accentColorClassName={accentColorClass}
										progressPercent={(clampedItemQuantity / availabilityItem.amount) * 100}
									/>
								</div>
							</div>
							<div className={styles.stockInfo}>
								<span className={styles.stockInfoTitle}>{title}</span>
								<span
									className={styles.stockInfoValue}
								>{`${clampedItemQuantity}/${availabilityItem.amount}`}</span>
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
	if (renderInPortal) {
		if (sizePopupStockBarPortal) {
			return createPortal(Component, sizePopupStockBarPortal);
		}
		return null;
	}
	return Component;
};