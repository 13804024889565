import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { InputCheckbox, SearchBar } from 'components/shared';
import { useFilterContext } from '../../../context/FilterContext';
import { SearchableCheckboxFilterType } from '../../../types/FilterTypes';
import styles from './SearchableFilterHeader.module.scss';

export interface SearchableFilterHeaderProps {
	filter: SearchableCheckboxFilterType;
}

export const SearchableFilterHeader: React.FunctionComponent<SearchableFilterHeaderProps> = ({ filter }) => {
	const { data: translations } = useTranslationQuery();

	const { checkboxActions } = useFilterContext();
	const { areAllSelected, areSomeSelected } = checkboxActions.areAllCheckboxesSelected(filter.id);

	return (
		<>
			<SearchBar
				classNameInput={styles.searchBar}
				onClear={filter.search.clearSearch}
				onChange={(e) => filter.search.changeSearch(e.target.value)}
				value={filter.search.search}
			/>
			<InputCheckbox
				controlled
				className={styles.checkbox}
				label={translations?.shared.selectAll}
				checked={areSomeSelected}
				partiallyChecked={!areAllSelected}
				onChange={() => {
					checkboxActions.onSelectAllCheckboxes(filter.id);
				}}
			/>
		</>
	);
};
