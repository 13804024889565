import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { SupportedCssUnits } from 'types/cssUnits';
import { portalRootId, useOnClickAway, useOnEscape, useScrollLock } from 'helpers/index';
import { OverlayTypes, useAppStore } from 'store/appStore';
import styles from './Drawer.module.scss';
import { DrawerLevel } from './DrawerLevel';

interface DrawerProps {
	children: React.ReactElement | React.ReactElement[];
	direction?: 'Left' | 'Right' | 'Top' | 'Bottom';
	width?: SupportedCssUnits;
	desktopWidth?: SupportedCssUnits;
	height?: SupportedCssUnits;
	desktopHeight?: SupportedCssUnits;
	allowOutsideInteractions?: boolean;
	onClose?: () => void;
	className?: string;
	isOrderConfirmationDrawerOpened?: boolean;
	hasMobileHeight?: boolean;
	renderInPortal?: boolean;
}

/**
 * Generic Drawer component, used for toggling off-screen, multi-"page" menu.
 * Used e.g. for mobile menu or mobile filter menu. This component cares about
 * the slide-in and "page"-switching behavior, not content.
 */
export const Drawer: React.FunctionComponent<DrawerProps> = ({
	children,
	direction = 'Left',
	allowOutsideInteractions = false,
	height,
	desktopHeight,
	width,
	desktopWidth,
	onClose,
	className,
	hasMobileHeight,
	renderInPortal = true,
}) => {
	const setScrollLock = useScrollLock();
	const drawerRef = React.useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = React.useState(false);
	const { setShowOverlay } = useAppStore((state) => state);

	const drawerTransTime = 400; // should match value in CSS

	const handleClose = React.useCallback((): void => {
		if (!onClose) return;
		setIsOpen(false);
		setTimeout(() => {
			onClose();
		}, drawerTransTime);
	}, [onClose]);
	useOnEscape(handleClose, isOpen);

	const handleOnClickAwayClose = React.useCallback((): void => {
		if (allowOutsideInteractions) return;
		handleClose();
	}, [allowOutsideInteractions, handleClose]);
	useOnClickAway(drawerRef, handleOnClickAwayClose, isOpen);

	React.useEffect(() => {
		setIsOpen(true);

		if (allowOutsideInteractions) return;
		setShowOverlay(OverlayTypes.FULL);
		setScrollLock(true);

		return (): void => {
			if (allowOutsideInteractions) return;
			setScrollLock(false);
			setShowOverlay(false);
		};
	}, [setScrollLock, allowOutsideInteractions, setShowOverlay]);

	const renderDrawer = (): React.ReactNode => (
		<aside
			className={classNames(className, styles.wrapper, styles[`from${direction}`], {
				[styles.isOpen]: isOpen,
				[styles.noZIndex]: allowOutsideInteractions,
				[styles.hasRoundedBorders]: hasMobileHeight,
			})}
			style={
				{
					'--height': hasMobileHeight && !height ? '65vh' : height,
					'--width': width,
					'--desktopHeight': desktopHeight,
					'--desktopWidth': desktopWidth,
				} as CSSProperties
			}
			ref={drawerRef}
		>
			<DrawerLevel handleClose={handleClose} isActive={true} direction={'None'}>
				{children}
			</DrawerLevel>
		</aside>
	);

	if (!renderInPortal) return <>{renderDrawer()}</>;

	const drawerElement: HTMLElement | null = document.getElementById(portalRootId);

	return ReactDOM.createPortal(<>{renderDrawer()}</>, drawerElement || document.body);
};
