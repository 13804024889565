import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';
import styles from './OrderNumber.module.scss';

export interface OrderNumberProps {
	onOrder?: boolean;
}

export const OrderNumber: React.FunctionComponent<OrderNumberProps> = ({ onOrder = false }) => {
	const { orderLineDetails } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	if (!translations) return null;

	if (onOrder) {
		return (
			<OrderDetailCell
				valueClassName={styles.bold}
				title={translations?.myOrders.orderDetails.orderNumberShort}
				value={orderLineDetails.orderNumber ?? translations.myOrders.pendingOrderLabel}
				copyable
				copyTooltip={translations.myOrders.orderDetails.copyOrderNumber}
				onCopyText={translations.myOrders.orderDetails.copiedOrderNumber}
			/>
		);
	}

	return orderLineDetails.orderNumber ? (
		<OrderDetailCell
			title={translations?.myOrders.orderDetails.orderNumberShort}
			value={orderLineDetails.orderNumber}
			copyable
			copyTooltip={translations.myOrders.orderDetails.copyOrderNumber}
			onCopyText={translations.myOrders.orderDetails.copiedOrderNumber}
		/>
	) : (
		<OrderDetailCell
			iconName="basket"
			title={translations?.myOrders.orderDetails.basketId}
			value={orderLineDetails.basketId ? String(orderLineDetails.basketId) : undefined}
		/>
	);
};
