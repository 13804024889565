import React from 'react';
import classNames from 'classnames';
import CopyTextButton from 'components/shared/CopyTextButton';
import { Icon, IconName } from 'components/shared/Icon';
import { LoadingBar } from 'components/shared/LoadingBar';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import styles from './OrderDetailCell.module.scss';

type CopyableProps = {
	copyable: true;
	copyTooltip: string;
	onCopyText: string;
};
export type OrderDetailCellProps = {
	title?: string;
	label?: React.ReactNode;
	value?: string;
	iconName?: IconName;
	className?: string;
	valueClassName?: string;
} & (
	| {
			copyable?: false;
	  }
	| CopyableProps
);

export const OrderDetailCell: React.FunctionComponent<OrderDetailCellProps> = ({
	title,
	value: valueFromProps,
	label,
	iconName,
	className,
	valueClassName,
	copyable = false,
	...props
}) => {
	const { size, isSkeleton } = useOrderLineContext();
	let copyableProps: CopyableProps | undefined;
	if (copyable) {
		copyableProps = props as CopyableProps;
	}

	const value = copyableProps ? (
		<CopyTextButton copySuccessMessage={copyableProps.onCopyText} text={valueFromProps} />
	) : (
		valueFromProps
	);

	const tooltip = copyableProps ? copyableProps.copyTooltip : valueFromProps;

	if (size === 'sm') {
		if (isSkeleton) {
			return (
				<div className={classNames(className, styles.valueRow)}>
					<LoadingBar height="1.25rem" width="45%" />
					<span className={classNames(valueClassName, styles.value)}>
						<LoadingBar height="1.25rem" width="40%" />
					</span>
				</div>
			);
		}
		return (
			<div className={classNames(className, styles.valueRow)}>
				<p className={styles.title}>{title}</p>
				<span title={tooltip} className={classNames(valueClassName, styles.value, 'u-ellipsis')}>
					{label}
					<p className="u-ellipsis">{value}</p>
				</span>
			</div>
		);
	}
	if (size === 'md') {
		if (isSkeleton) {
			return (
				<div className={classNames(className, styles.column)}>
					<LoadingBar height="1.25rem" width="100%%" />
					<LoadingBar height="1.5rem" width="100%" />
				</div>
			);
		}
		return (
			<div className={classNames(className, styles.column)}>
				<p className={styles.title}>{title}</p>
				<span title={tooltip} className={classNames(valueClassName, styles.value, 'u-ellipsis')}>
					<p className="u-ellipsis">{value}</p>
					{label}
				</span>
			</div>
		);
	}
	if (isSkeleton) {
		return (
			<div className={classNames(className, styles.valueCell)}>
				<div aria-hidden="true" />
				<LoadingBar height="1.5rem" />
			</div>
		);
	}
	return (
		<div className={classNames(className, styles.valueCell)}>
			{label || <div aria-hidden="true" />}

			<p
				title={tooltip}
				className={classNames(valueClassName, styles.value, 'u-ellipsis')}
				aria-describedby={title}
			>
				{iconName && <Icon name={iconName} size="sm" />}
				{value}
			</p>
		</div>
	);
};
