import React, { PropsWithChildren } from 'react';
import { uniqueId } from 'lodash';


interface UseKeyboardNavigationReturnType {
	cellAttributes: (input: { rowIndex: number; columnIndex: number }) => {
		'data-container': string;
		'data-cell': '';
		'aria-rowindex': number;
		'aria-colindex': number;
		tabIndex: 0;
	};
	containerRef: React.MutableRefObject<Element | null>;
}

type FocusableElement = HTMLElement & {
	focus: () => void;
};

interface KeyboardNavigationContextProps {
	onElementFocus?: (element: HTMLElement) => void;
	containerRef?: React.MutableRefObject<Element | null>;
}

const useKeyboardNavigationState = ({
	onElementFocus,
	containerRef,
}: KeyboardNavigationContextProps): UseKeyboardNavigationReturnType => {
	const CONTAINER_ID = React.useRef<string>(uniqueId('keyboard-navigation-container-')).current;
	const DATA_CELL_ATTRIBUTE = 'data-cell';
	const DATA_CONTAINER_ATTRIBUTE = 'data-container';
	const CELL_QUERY_SELECTOR = `[${DATA_CELL_ATTRIBUTE}][${DATA_CONTAINER_ATTRIBUTE}="${CONTAINER_ID}"]`;
	// Either creates a new ref, or uses the existing one.
	const sanitizedContainerRef = React.useRef<Element | null>(containerRef?.current ?? null);

	React.useEffect(() => {
		sanitizedContainerRef.current = containerRef?.current ?? null;
	}, [containerRef]);
	const eventListener = React.useCallback(() => {
		let focusedCell;

		/**
		 * This event listener is looking for the active element in the document,
		 * and then tries to find it's ancestor with the data-cell, and data-container attribute.
		 * If it can't find it, there's no focused cell, and the keyDownEventListener
		 * won't do anything.
		 */
		const focusEventListener = () => {
			focusedCell = undefined;
			const containerElement = sanitizedContainerRef.current;
			if (!containerElement) return;
			focusedCell = document.activeElement?.closest(CELL_QUERY_SELECTOR);
		};

		focusEventListener();
		return {
			keyDownEventListener: (event) => {
				focusEventListener();
				const containerElement = sanitizedContainerRef.current;
				if (!containerElement || !focusedCell) return;

				if (['ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
					// Prevent scrolling with arrow keys
					event.preventDefault();
				}
				let rowIndex = focusedCell.attributes['aria-rowindex']?.value;
				let columnIndex = focusedCell.attributes['aria-colindex']?.value;
				const { key } = event;
				if (key === 'ArrowDown') {
					rowIndex = parseInt(rowIndex) + 1;
				} else if (key === 'ArrowUp') {
					rowIndex = parseInt(rowIndex) - 1;
				} else if (key === 'ArrowRight') {
					columnIndex = parseInt(columnIndex) + 1;
				} else if (key === 'ArrowLeft') {
					columnIndex = parseInt(columnIndex) - 1;
				}

				const nextFocusedElement: FocusableElement | null = containerElement?.querySelector(
					`${CELL_QUERY_SELECTOR}[aria-rowindex="${rowIndex}"][aria-colindex="${columnIndex}"]`,
				);
				if (nextFocusedElement) {
					// Check if it has a focusable element inside, like a button or an input.
					// If not, focus the element itself.
					const focusableElement = (nextFocusedElement.querySelector(
						'input, button, [tabindex]:not([tabindex="-1"])',
					) || nextFocusedElement) as HTMLInputElement | HTMLButtonElement;
					focusableElement.focus();
					onElementFocus?.(nextFocusedElement);
				}
			},
			focusEventListener: focusEventListener,
		};
	}, [CELL_QUERY_SELECTOR, onElementFocus]);

	React.useEffect(() => {
		const { focusEventListener, keyDownEventListener } = eventListener();
		document.addEventListener('focus', focusEventListener);
		document.addEventListener('keydown', keyDownEventListener);
		return () => {
			document.removeEventListener('focus', focusEventListener);
			document.removeEventListener('keydown', keyDownEventListener);
		};
	}, [eventListener]);

	return {
		cellAttributes: ({ rowIndex, columnIndex }) => ({
			'data-container': CONTAINER_ID,
			'data-cell': '',
			'aria-rowindex': rowIndex,
			'aria-colindex': columnIndex,
			tabIndex: 0,
		}),
		containerRef: sanitizedContainerRef,
	};
};

const KeyboardNavigationContext = React.createContext<UseKeyboardNavigationReturnType | null>(null);

export const KeyboardNavigationProvider: React.FunctionComponent<PropsWithChildren<KeyboardNavigationContextProps>> = ({
	children,
	...props
}: PropsWithChildren<KeyboardNavigationContextProps>) => {
	const value = useKeyboardNavigationState(props);

	return <KeyboardNavigationContext.Provider value={value}>{children}</KeyboardNavigationContext.Provider>;
};

/**
 * To use this hook, you need to wrap your component with KeyboardNavigationProvider
 * Then you need to use the `containerRef` on the section you want
 * to navigate through. This does not necessarily have to be a scrollable container,
 * it just needs to wrap all your cells
 *
 * After that, on every cell, you need to pass the `cellAttributes` function with the
 * rowIndex and columnIndex as arguments. This will add the necessary attributes to the cell,
 * making them navigable with the arrow keys.
 *
 * @returns {UseKeyboardNavigationReturnType} The keyboard navigation context
 */
export const useKeyboardNavigation = (): UseKeyboardNavigationReturnType => {
	const context = React.useContext(KeyboardNavigationContext);

	if (!context) {
		throw new Error('useKeyboardNavigation must be used within a KeyboardNavigationProvider');
	}

	return context;
};