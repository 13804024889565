import { CmiSplash } from './Cmi';
import { Delivered } from './Delivered';
import { DeliveryMonth } from './DeliveryMonth';
import { Discount } from './Discount';
import { FashionForward } from './FashionForward';
import { InBasket } from './InBasket';
import { Inbound } from './Inbound';
import { Noos } from './Noos';


export const Splashes = {
	Inbound,
	Delivered,
	InBasket,
	FashionForward,
	DeliveryMonth,
	Discount,
	Noos,
	Cmi: CmiSplash,
};