import React from 'react';
import { InputCheckbox } from 'components/shared';
import { InfiniteScrollTrigger } from 'components/shared/InfiniteScrollTrigger';
import { FilterValueResponse } from 'generated/data-contracts';
import { useFilterContext } from '../../../context/FilterContext';
import { SearchableCheckboxFilterType } from '../../../types/FilterTypes';
import styles from './SearchableFilterContent.module.scss';

export interface SearchableFilterContentProps {
	filter: SearchableCheckboxFilterType;
}

export const SearchableFilterContent: React.FunctionComponent<SearchableFilterContentProps> = ({ filter }) => {
	const { checkboxActions } = useFilterContext();
	const handleChange = (item: FilterValueResponse) => () => {
		checkboxActions.onChangeCheckboxItem(filter.id, item);
	};

	return (
		<ul className={styles.list}>
			{filter.values?.map((item) => {
				const isCurrentItemSelected = checkboxActions.isCheckboxSelected(filter.id, item);
				const hitCount = item.hitCount ? <span>({item.hitCount})</span> : <></>;
				return (
					<li key={`${filter.id}-${item.value}`} role="option" aria-selected={isCurrentItemSelected}>
						<InputCheckbox
							controlled
							className={styles.checkbox}
							label={
								<div className={styles.listItem}>
									<span>{item.label}</span>
									{hitCount}
								</div>
							}
							id={item.value}
							checked={isCurrentItemSelected}
							name={item.label}
							onChange={handleChange(item)}
						/>
					</li>
				);
			})}
			<InfiniteScrollTrigger
				as="li"
				hasMore={filter.hasNextPage}
				rootMargin="0.5rem"
				onLoadMore={filter.loadMore}
			/>
		</ul>
	);
};
