import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'library/routing/useSearchParams';
import { uniq, isEqual, isEmpty } from 'lodash';
import { SearchableFilterType } from 'components/shared/Filters/SearchableFilter';
import { FilterRequest, FilterResponse } from 'generated/data-contracts';

export const DEFAULT_FILTER_PARAM = 'filter';

/**
 *	Util function to be passed to a `.filter()` or `.some()` function.
 * 	Last argument can be used to keep or remove the item in the array if the filter matches
 *  */
export const filterList =
	(id?: string | null, value?: string | null, keepItemInArrayIfMatches: boolean = true) =>
	(filter: FilterRequest): boolean => {
		if (!id) return keepItemInArrayIfMatches;
		if (!value) return keepItemInArrayIfMatches ? filter.filter === id : filter.filter !== id;
		if (keepItemInArrayIfMatches) return filter.filter === id && filter.value === value;
		return filter.filter !== id || filter.value !== value;
	};

type FilterObject = {
	[key: string]: FilterRequest['value'][];
};

export type UseFilterOption =
	| {
			storeInUrl?: never;
			urlParam?: never;
	  }
	| {
			storeInUrl: true;
			urlParam?: string;
	  };

export type FilterOptions = {
	changeFilterMobile: (sortBy: string, sortDirection: string) => void;
	toBeSet: FilterRequest[];
	selectedFilters: FilterRequest[];
	toBeRemoved: FilterRequest[];
	setToBeSetFilters: React.Dispatch<React.SetStateAction<FilterRequest[]>>;
	setToBeRemovedFilters: React.Dispatch<React.SetStateAction<FilterRequest[]>>;
	resetFilters: () => void;
	changeFilter: (id: string) => void;
	removeFilter: (id: string, value?: unknown) => void;
	filters: (SearchableFilterType | FilterResponse)[];
};

/** @throws Error */
export const getFiltersFromUrl = (searchParams: URLSearchParams, urlParam: string) => {
	const filtersInBase64 = searchParams.get(urlParam);
	const filterObject: FilterObject = filtersInBase64 ? JSON.parse(atob(filtersInBase64)) : [];
	return Object.entries(filterObject).flatMap(([filter, values]) =>
		values.map((value) => ({
			filter,
			value,
		})),
	);
};

export const useFilters = (
	filters: (SearchableFilterType | FilterResponse)[],
	options: UseFilterOption = {},
): FilterOptions => {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const { storeInUrl = true, urlParam = DEFAULT_FILTER_PARAM } = options;
	const [selectedFilters, setSelectedFilters] = React.useState<FilterRequest[]>(() => {
		if (!storeInUrl) return [];

		let filterList: FilterRequest[] = [];
		try {
			filterList = getFiltersFromUrl(searchParams, urlParam);
		} catch {
			// Filters object is borken. Delete it
			setSearchParams(
				(p) => {
					p.delete(urlParam);
					return p;
				},
				{
					replace: true,
					state: location.state,
				},
			);
		}
		return filterList;
	});

	const filtersInBase64 = React.useMemo(() => {
		const filterObject: FilterObject = selectedFilters.reduce<FilterObject>((acc, filter) => {
			if (!filter.filter) return acc;
			if (!acc[filter.filter]) acc[filter.filter] = [];
			acc[filter.filter].push(filter.value);
			return acc;
		}, {});
		if (isEmpty(filterObject)) return undefined;
		return btoa(JSON.stringify(filterObject));
	}, [selectedFilters]);

	React.useEffect(() => {
		if (!storeInUrl) return;
		setSearchParams(
			(prev) => {
				const newSearchParams = new URLSearchParams();
				if (filtersInBase64 === undefined) {
					newSearchParams.delete(urlParam);
				} else {
					prev.forEach((value, key) => {
						if (!newSearchParams.getAll(key).includes(value)) newSearchParams.append(key, value);
					});
					newSearchParams.set(urlParam, filtersInBase64);
				}

				if (prev.toString() === newSearchParams.toString()) return prev;
				return newSearchParams;
			},
			{
				replace: true,
				state: location.state,
			},
		);
	}, [filtersInBase64, location.state, setSearchParams, storeInUrl, urlParam]);

	const [toBeSet, setToBeSetFilters] = React.useState<FilterRequest[]>([]);
	const [toBeRemoved, setToBeRemovedFilters] = React.useState<FilterRequest[]>([]);

	const changeFilter = (id?: string): void => {
		const filterToBeSet = toBeSet.filter(filterList(id));
		const filterToBeRemoved = toBeRemoved.filter(filterList(id));
		setSelectedFilters((prev) => {
			const newFilters = uniq(
				(prev ?? [])
					.filter((filter) => !filterToBeRemoved.some(filterList(filter.filter, filter.value)))
					.concat(filterToBeSet),
			);
			if (isEqual(newFilters, prev)) return prev;
			return newFilters;
		});
		setToBeSetFilters((prev) => prev.filter(filterList(id, undefined, false)));
		setToBeRemovedFilters((prev) => prev.filter(filterList(id, undefined, false)));
	};

	const resetFilters = (): void => {
		setSelectedFilters([]);
		setToBeSetFilters([]);
		setToBeRemovedFilters([]);
		filters.forEach((filter) => {
			if ('search' in filter) filter.search.clearSearchText();
		});
	};

	const removeFilter = (id, value) => {
		setSelectedFilters((prev) => {
			return prev.filter((r) => {
				return r.filter !== id || r.value !== value;
			});
		});
	};

	const filtersWithSelectedOptions = React.useMemo(() => {
		const _acc: Record<string, boolean> = {};
		const selectedOptions: Record<string, boolean> = selectedFilters.reduce((acc, filter) => {
			acc[`${filter.filter}:${filter.value}`] = true;
			return acc;
		}, _acc);

		return filters.map((filter) => {
			const updatedValues = filter.values?.map((value) => ({
				...value,
				isSelected: selectedOptions[`${filter.id}:${value.value}`] ?? false,
			}));

			// Sort the values to put selected ones at the top
			const sortedValues = updatedValues?.sort((a, b) => {
				if (a.isSelected === b.isSelected) return 0;
				return a.isSelected ? -1 : 1;
			});

			return {
				...filter,
				values: sortedValues,
			};
		});
	}, [filters, selectedFilters]);

	return {
		filters: filtersWithSelectedOptions,
		toBeSet,
		toBeRemoved,
		changeFilter,
		changeFilterMobile: () => changeFilter(),
		removeFilter,
		resetFilters,
		setToBeSetFilters,
		setToBeRemovedFilters,
		selectedFilters,
	};
};
