import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'library/routing';
import { SortDirection } from 'generated/data-contracts';

type SortType = string;
type SortOption = {
	label: string;
	value: SortType;
};

export interface UseBasketSortByReturnType {
	sortBy: SortType;
	sortDirection: SortDirection;
	setSortBy: React.Dispatch<React.SetStateAction<SortType>>;
	setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
	sortOptions: Map<SortType, SortOption>;
}

const BASKET_SORT_BY_SEARCH_PARAM = 'sortBy';
const BASKET_SORT_DIRECTION_SEARCH_PARAM = 'sortDirection';
const DEFAULT_SORT_OPTION = 'ShippingDate';

export const useBasketSortBy = (): UseBasketSortByReturnType => {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const sortOptions = React.useMemo<Map<SortType, SortOption>>(() => {
		return new Map([
			['Latest', { label: 'Latest', value: 'Latest' }],
			['Shipping', { label: 'Shipping', value: 'Shipping' }],
			['Markup', { label: 'Markup', value: 'Markup' }],
			['WSP', { label: 'WSP', value: 'WSP' }],
		]);
	}, []);
	const [sortBy, setSortBy] = React.useState<SortType>(() => {
		const value = searchParams.get(BASKET_SORT_BY_SEARCH_PARAM);
		const option = value ? sortOptions.get(value) : undefined;
		if (option) return option.value;
		return DEFAULT_SORT_OPTION;
	});
	const [sortDirection, setSortDirection] = React.useState<SortDirection>(() => {
		const value = searchParams.get(BASKET_SORT_DIRECTION_SEARCH_PARAM);
		if (value === SortDirection.Ascending) return SortDirection.Ascending;
		return SortDirection.Descending;
	});

	React.useEffect(() => {
		setSearchParams(
			(prev) => {
				const newSearchParams = new URLSearchParams(prev);
				newSearchParams.set(BASKET_SORT_BY_SEARCH_PARAM, sortBy);
				newSearchParams.set(BASKET_SORT_DIRECTION_SEARCH_PARAM, sortDirection);
				return newSearchParams;
			},
			{
				replace: true,
				state: location.state,
			},
		);
	}, [location.state, setSearchParams, sortBy, sortDirection]);

	return React.useMemo(
		() => ({
			sortBy,
			sortDirection,
			sortOptions,
			setSortBy,
			setSortDirection,
		}),
		[sortBy, sortDirection, sortOptions],
	);
};
