import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'library/routing/useSearchParams';
import { isEqual } from 'lodash';
import {
	Area,
	OrderHistorySort,
	PageType,
	SortDirection,
	SortingResponseOrderHistorySort,
} from 'generated/data-contracts';
import { OrderListUrlParamKeys } from '../constants/OrderListUrlParamKeys';

interface UseSortingOptionsReturnType {
	changeSort: (sort: OrderHistorySort, sortDirection: SortDirection) => void;
	currentSort: SortingResponseOrderHistorySort | undefined;
	sortOptions: SortingResponseOrderHistorySort[];
	setSortOptions: React.Dispatch<SortingResponseOrderHistorySort[] | undefined>;
}

export const useSortingOptions = (): UseSortingOptionsReturnType => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [sortOptions, setSortOptions] = useState<SortingResponseOrderHistorySort[] | undefined>(undefined);
	const getCurrentSortFromSortOptions = useCallback(
		({ sortBy, sortDirection }: { sortBy: string | null; sortDirection: string | null }) =>
			sortOptions?.find((sort) => sort.sortBy === sortBy && sort.sortDirection === sortDirection),
		[sortOptions],
	);
	const [currentSort, setCurrentSort] = useState<SortingResponseOrderHistorySort | undefined>(() =>
		getCurrentSortFromSortOptions({
			sortBy: searchParams.get(OrderListUrlParamKeys.OrderlineSortBy),
			sortDirection: searchParams.get(OrderListUrlParamKeys.OrderlineSortDirection),
		}),
	);
	React.useEffect(() => {
		if (!currentSort) {
			setCurrentSort(
				getCurrentSortFromSortOptions({
					sortBy: searchParams.get(OrderListUrlParamKeys.OrderlineSortBy),
					sortDirection: searchParams.get(OrderListUrlParamKeys.OrderlineSortDirection),
				}),
			);
		}
	}, [currentSort, getCurrentSortFromSortOptions, searchParams]);

	useEffect(() => {
		setSearchParams(
			(prev) => {
				if (!currentSort) {
					return prev;
				}
				prev.set(OrderListUrlParamKeys.OrderlineSortBy, currentSort.sortBy);
				prev.set(OrderListUrlParamKeys.OrderlineSortDirection, currentSort.sortDirection);
				return prev;
			},
			{
				replace: true,
				state: { area: Area.StaticPages, pageType: PageType.Orders },
			},
		);
	}, [currentSort, setSearchParams]);

	const changeSort = useCallback(
		(sort: OrderHistorySort, sortDirection: SortDirection) => {
			setCurrentSort((prev) => {
				const newSort = getCurrentSortFromSortOptions({
					sortBy: sort,
					sortDirection: sortDirection,
				});
				if (prev && isEqual(prev, newSort)) {
					return prev;
				}
				return newSort;
			});
		},
		[getCurrentSortFromSortOptions],
	);

	return {
		currentSort,
		changeSort,
		sortOptions: sortOptions ?? [],
		setSortOptions,
	};
};
