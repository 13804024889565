import React, { createContext, useCallback, useContext } from 'react';
import { nanoid } from 'nanoid';
import { AlertTypes } from 'components/shared/Alert';

export type NotificationItem = {
	id?: string;
	type: AlertTypes;
	options?: {
		duration?: number;
		isClosable?: boolean;
	};
	children: React.ReactNode;
};

export type NotificationContextProps = {
	notifications: NotificationItem[];

	notificationActions: {
		handleClose: (id: string) => void;
		stopTimer: () => void;
		startTimer: (notification: NotificationItem) => void;
		addNotification: (notification: NotificationItem) => void;
		removeNotification: (id: string) => void;
		clearNotifications: () => void;
	};
};

export const NotificationContext = createContext<NotificationContextProps | null>(null);

export type NotificationProviderProps = {
	children: React.ReactNode;
};

export const NotificationContextProvider: React.FunctionComponent<NotificationProviderProps> = ({
	children,
}: NotificationProviderProps) => {
	const timerRef = React.useRef<NodeJS.Timeout>();
	const [notifications, setNotifications] = React.useState<NotificationItem[]>([]);

	const addNotification = (notification: NotificationItem): void => {
		const newNotification = {
			...notification,
			id: notification.id ?? nanoid(),
			options: {
				duration: notification.options?.duration ?? 3000,
				isClosable: notification.options?.isClosable ?? true,
			},
		};
		setNotifications([...notifications, newNotification]);
	};

	const clearNotifications = (): void => {
		setNotifications([]);
	};

	const removeNotification = useCallback(
		(id: string): void => {
			setNotifications(notifications.filter((item) => item.id !== id));
		},
		[notifications],
	);

	const handleClose = React.useCallback(
		(id: string): void => {
			if (id) {
				removeNotification(id);
			}
		},
		[removeNotification],
	);

	const startTimer = React.useCallback(
		(notification: NotificationItem) => {
			timerRef.current = setTimeout(
				() => handleClose(notification.id ?? ''),
				notification.options?.duration ?? 3000,
			);
		},
		[handleClose],
	);

	const stopTimer = (): void => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
	};

	const notificationContext: NotificationContextProps = {
		notifications,
		notificationActions: {
			handleClose,
			stopTimer,
			addNotification,
			startTimer,
			removeNotification,
			clearNotifications,
		},
	};

	return <NotificationContext.Provider value={notificationContext}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = (): NotificationContextProps => {
	const context = useContext(NotificationContext);

	if (!context) {
		throw new Error('useNotificationContext must be used within a NotificationContextProvider');
	}
	return context;
};
