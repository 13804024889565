import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { BasketResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';

export const useAvailabilityNotification = (basket: BasketResponse) => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	React.useEffect(() => {
		if (basket.hasUnavailableProducts) {
			const hasAvailableProducts = parseInt(basket.lookups.totals.Basket.totalQuantity) > 0;

			notificationActions.addNotification({
				options: {
					duration: 5000,
				},
				children: formatTranslation(
					hasAvailableProducts
						? translations?.openBaskets.someProductsUnavailable
						: translations?.openBaskets.allProductsUnavailable,
					{},
				),
				type: AlertTypes.DANGER,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		translations?.openBaskets.someProductsUnavailable,
		translations?.openBaskets.allProductsUnavailable,
		basket.lookups.totals.Basket.totalQuantity,
		basket.hasUnavailableProducts,
	]);
};
