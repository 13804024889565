import React, { PropsWithChildren } from 'react';
import { useLocalStorage } from './useLocalStorage';

const HIDE_BASKET_TOTALS_NAME = 'hideBasketTotals';

interface UseHideBasketTotalsReturnType {
	hideBasketTotals: boolean;
	handleClick: (value: boolean) => void;
}

const useHideBasketTotalsState = (): UseHideBasketTotalsReturnType => {
	const [hideBasketTotals, setHideBasketTotals] = useLocalStorage<boolean>(HIDE_BASKET_TOTALS_NAME, true);

	return {
		hideBasketTotals,
		handleClick: setHideBasketTotals,
	};
};

const HideBasketTotalsContext = React.createContext<UseHideBasketTotalsReturnType | null>(null);

export const HideBasketTotalsProvider: React.FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
	const value = useHideBasketTotalsState();

	return <HideBasketTotalsContext.Provider value={value}>{children}</HideBasketTotalsContext.Provider>;
};

export const useHideBasketTotals = (): UseHideBasketTotalsReturnType => {
	const context = React.useContext(HideBasketTotalsContext);

	if (!context) {
		throw new Error('useHideBasketTotals must be used within a HideBasketTotalsProvider');
	}

	return context;
};
