import React from 'react';
import classNames from 'classnames';
import { useMiniBasketQuantityQuery } from 'api/basket';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Badge, IconLink } from 'components/shared';
import { PageType } from 'generated/data-contracts';
import styles from './MiniBasketIcon.module.scss';

/**
 * MiniBasket component, used for linking to basket and showing basket count.
 */
export const MiniBasketIcon: React.FunctionComponent = () => {
	const { data: layout } = useLayoutQuery();
	const { data: miniBasketObject } = useMiniBasketQuantityQuery();

	const staticLinks = layout?.staticLinks;
	const { data: translations } = useTranslationQuery();
	const basketUrl = staticLinks?.find((link) => link.pageType === PageType.Basket)?.url;

	let basketQuantity: string | number = miniBasketObject?.totalQuantity ?? 0;

	if (basketQuantity > 9999) {
		basketQuantity = '9999+';
	}

	const route = {
		area: 'StaticPages',
		pageType: 'Basket',
	};

	return (
		<IconLink
			className={classNames(styles.wrapper)}
			icon={'basket'}
			title={translations?.shared.miniBasketTitle || ''}
			to={basketUrl}
			state={route}
		>
			<Badge className={styles.basketBadge} value={basketQuantity} />
		</IconLink>
	);
};
