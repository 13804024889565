import React from 'react';
import classNames from 'classnames';
import { Alert } from 'components/shared/Alert';
import { useNotificationContext } from './store/NotificationContext';
import styles from './Notifications.module.scss';

/**
 * Component that wraps the Alert component.
 * Handles the open/close functionality.
 */
export const Notifications: React.FunctionComponent = () => {
	const { notificationActions, notifications } = useNotificationContext();
	const { startTimer, stopTimer, handleClose } = notificationActions;

	return (
		<div className={classNames(styles.list, 'u-container-sm')}>
			{notifications.map((notification) => {
				if (!notification) {
					return null;
				}

				const { id = '', options, type, children } = notification;
				startTimer(notification);

				return (
					<div
						key={id}
						className={styles.item}
						onMouseEnter={stopTimer}
						onMouseLeave={() => startTimer(notification)}
					>
						<Alert type={type} onClose={options?.isClosable ? () => handleClose(id) : undefined}>
							{children}
						</Alert>
					</div>
				);
			})}
		</div>
	);
};
