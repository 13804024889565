import React from 'react';
import { useShiptoSearchQuery } from 'api/shipTo';
import { useTranslationQuery } from 'api/translations';
import {
	CheckboxFilterValue,
	FilterTypesEnum,
	SearchableCheckboxFilterType,
} from 'components/shared/FilterList/V2/types/FilterTypes';
import { useSearch } from 'helpers/useSearch';
import { OrderListUrlParamKeys } from '../constants/OrderListUrlParamKeys';

export const useAccountsFilter = (selectedShipTos: string[] = []): SearchableCheckboxFilterType => {
	const { data: translations } = useTranslationQuery();
	const search = useSearch({
		storeInUrl: false,
	});

	const shipToData = useShiptoSearchQuery({
		phrase: search.debouncedSearch,
		pageSize: 36,
		selectedShipTos,
	});

	const allShipTos = React.useMemo((): CheckboxFilterValue[] => {
		return (
			shipToData.data?.pages.flatMap((r) => {
				return r.shipTos ?? [];
			}) ?? []
		).map((r) => {
			return {
				isSelected: false,
				label: r.name,
				value: r.id,
			};
		});
	}, [shipToData.data?.pages]);

	const accountsFilter = React.useMemo((): SearchableCheckboxFilterType => {
		return {
			label: translations?.myOrders.accounts ?? '',
			hasNextPage: shipToData.hasNextPage,
			search,
			id: OrderListUrlParamKeys.ShipTo,
			loadMore: shipToData.fetchNextPage,
			type: FilterTypesEnum.Checkbox,
			values: allShipTos,
		};
	}, [allShipTos, search, shipToData.fetchNextPage, shipToData.hasNextPage, translations?.myOrders.accounts]);

	return accountsFilter;
};
