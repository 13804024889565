import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'library/routing/useSearchParams';
import { isEqual } from 'lodash';
import { useDebounce } from './useDebounce';

const SEARCH_URL_PARAM = 'search';
interface UseSearchProps {
	/**
	 * If the current value should be stored in the url
	 * @default true */
	storeInUrl?: boolean;
	/**
	 * The URL Param used to store the current value
	 *
	 * @default "search"
	 */
	urlParam?: string;
}
export interface UseSearchReturnType {
	search: string;
	debouncedSearch: string;
	clearSearch: () => void;
	changeSearch: (value: string) => void;
}
export const useSearch = (params: UseSearchProps = {}): UseSearchReturnType => {
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const { storeInUrl = true, urlParam = storeInUrl ? SEARCH_URL_PARAM : undefined } = params;
	const [search, setSearch] = React.useState<string>(() => {
		if (urlParam) {
			return searchParams.get(urlParam) ?? '';
		}
		return '';
	});
	const updateDebouncedSearch = useDebounce((s) => setDebouncedSearch(s), 500);
	const [debouncedSearch, setDebouncedSearch] = React.useState<string>(search);

	const changeSearch = (value: string) => {
		setSearch(value);
	};

	const clearSearch = () => {
		setSearch('');
	};

	React.useEffect(() => {
		updateDebouncedSearch(search);
	}, [search, updateDebouncedSearch]);

	React.useEffect(() => {
		if (!storeInUrl || !urlParam) return;
		if (isEqual(debouncedSearch, searchParams.get(urlParam))) return;
		setSearchParams(
			(prev) => {
				if (isEqual(debouncedSearch, prev.get(urlParam))) return prev;
				prev.set(urlParam, debouncedSearch);
				return prev;
			},
			{
				replace: true,
				state: location.state,
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearch, location.state, storeInUrl, urlParam]);

	const returnType = React.useMemo(
		() => ({
			search,
			debouncedSearch,
			changeSearch,
			clearSearch,
		}),
		[debouncedSearch, search],
	);

	return returnType;
};
