import React from 'react';
import { FilterTypesEnum, SearchableCheckboxFilterType } from '../../types/FilterTypes';
import { FilterDropdown } from '../shared/FilterDropdown';
import { SearchableFilterContent } from './SearchableFilterContent';
import { SearchableFilterHeader } from './SearchableFilterHeader';

export interface SearchableFilterProps {
	filter: SearchableCheckboxFilterType;
}

export const SearchableFilter: React.FunctionComponent<SearchableFilterProps> = ({ filter }) => {
	return (
		<FilterDropdown
			filter={filter}
			filterType={FilterTypesEnum.Checkbox}
			hasSelectedItemsSection
			headerProps={{
				children: <SearchableFilterHeader filter={filter} />,
			}}
		>
			<SearchableFilterContent filter={filter} />
		</FilterDropdown>
	);
};
