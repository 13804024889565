import React from 'react';
import classNames from 'classnames';
import { useLayoutQuery } from 'api/layout';
import { usePageTrackingQuery } from 'api/tracking';
import { useTranslationQuery } from 'api/translations';
import { MemoizedFooter } from 'components/features/Footer';
import { MemoizedHeader } from 'components/features/Header';
import { Notifications } from 'components/shared';
import { useZoomCorrectionSettings } from 'components/shared/hooks/useZoomCorrectionSettings';
import { PageType } from 'generated/data-contracts';
import { isScannerApp, portalRootId } from 'helpers/index';
import { trackPage } from 'helpers/tracking';
import { OverlayTypes, useAppStore } from 'store/appStore';
import '../types/global-overrides';
import styles from './_page.partial.module.scss';

interface PageProps {
	children?: React.ReactNode;
	isModal?: boolean;
	hasNoVisibleContent?: boolean;
	criticalNav?: boolean;
	removeAllFooterSpacing?: boolean;
	removeAllHeaderSpacing?: boolean;
	removeMobileFooterSpacing?: boolean;
	hasPlaceHolderFooterSpacing?: boolean;
}

const Page: React.FunctionComponent<PageProps> = ({
	isModal,
	criticalNav,
	children,
	removeAllFooterSpacing,
	removeAllHeaderSpacing,
	removeMobileFooterSpacing,
	hasNoVisibleContent,
	hasPlaceHolderFooterSpacing,
}) => {
	const { data: layout } = useLayoutQuery();
	useZoomCorrectionSettings();

	const { data: translations } = useTranslationQuery();

	const { pageOrigin, showOverlay } = useAppStore((state) => state);
	const [overlayType, setOverlayType] = React.useState(OverlayTypes.CONTENT);

	const searchUrl = layout?.staticLinks?.find((link) => link.pageType === PageType.Search)?.url || '';
	const { data: trackingData } = usePageTrackingQuery();

	React.useEffect(() => {
		if (trackingData) trackPage(trackingData);
	}, [trackingData]);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	React.useEffect(() => {
		if (showOverlay === OverlayTypes.CONTENT && overlayType === OverlayTypes.FULL) {
			setOverlayType(OverlayTypes.CONTENT);
			return;
		}

		if (showOverlay === OverlayTypes.FULL) setOverlayType(OverlayTypes.FULL);
	}, [overlayType, showOverlay]);

	const structuredData = {
		'@context': 'https://schema.org',
		'@type': 'WebSite',
		url: pageOrigin,
		potentialAction: {
			'@type': 'SearchAction',
			target: `${pageOrigin}${searchUrl}?phrase={query}`,
			'query-input': 'required maxlength=255 name=query',
		},
	};

	if (isScannerApp) {
		document.body.classList.add('isScannerApp');
	}

	if (isModal) {
		return <div>{children}</div>;
	}

	return (
		<>
			<div
				className={classNames(styles.overlay, {
					[styles.isShown]: showOverlay !== false,
					[styles.isFullOverlay]: overlayType === OverlayTypes.FULL,
				})}
			/>
			{hasNoVisibleContent ? (
				<>{children}</>
			) : (
				<>
					{!isScannerApp && (
						<MemoizedHeader critical={criticalNav} layout={layout} translations={translations} />
					)}
					<main
						className={classNames(styles.contentWrapper, {
							[styles.hasNoHeaderMargin]: removeAllHeaderSpacing,
						})}
					>
						{children}
					</main>
					{!isScannerApp && (
						<MemoizedFooter
							className={classNames({
								[styles.hasNoMarginMobile]: removeMobileFooterSpacing,
								[styles.hasNoMargin]: removeAllFooterSpacing,
							})}
							critical={criticalNav}
							layout={layout}
							translations={translations}
						/>
					)}
					{isScannerApp && hasPlaceHolderFooterSpacing && <div className={styles.footerPlaceHolder}></div>}
				</>
			)}

			<Notifications />
			<div id={portalRootId} />

			<script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
		</>
	);
};

export default Page;
