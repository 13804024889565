import React from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'library/routing';
import { useBrandListQuery, useSetBrandMutation } from 'api/brand';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import BrandSelectorDesktop from 'components/features/BrandSelector/BrandSelectorDesktop';
import BrandSelectorMobile from 'components/features/BrandSelector/BrandSelectorMobile';
import { DrawerBody, Link } from 'components/shared';
import { Area, BrandResponse, PageType, SubBrandResponse } from 'generated/data-contracts';
import { messageToApp } from 'helpers/app';
import { useViewportSize } from 'helpers/useViewportSize';
import styles from './BrandSelectorMenu.module.scss';

interface BrandSelectorMenuProps {
	children?: React.ReactNode;
	onClose: () => void;
}

export const BrandSelectorMenu: React.FunctionComponent<BrandSelectorMenuProps> = () => {
	const { data: layout } = useLayoutQuery();
	const { data: translations } = useTranslationQuery();

	const { isMobile } = useViewportSize();

	const [searchParams] = useSearchParams();

	const accountUrl = layout?.staticLinks?.find((item) => item.pageType === PageType.AccountSelector)?.url;

	const route = {
		area: Area.StaticPages,
		pageType: PageType.AccountSelector,
		editAccounts: true,
	};

	const { data: brands } = useBrandListQuery();
	const setBrand = useSetBrandMutation();

	const onChooseBrand = (brand: BrandResponse | SubBrandResponse): void => {
		const body = {
			presentationBrandId: brand.presentationBrandId,
			languageId: layout?.languageId,
			returnUrl: searchParams.get('returnUrl'),
		};

		setBrand.mutate(body, {
			onSuccess: (response) => {
				const frontPageRoute = response.data.route.externalRoute || '/';

				messageToApp({ type: 'presentationBrandChanged', presentationBrandId: brand.presentationBrandId });
				// Navigate with full page reload to re-initialize all the data in the store
				window.location.href = window.location.origin + frontPageRoute;
			},
		});
	};

	const emptyArray = new Array(5).fill(null);

	const gridColAmount = isMobile ? 2 : 3;

	return (
		<DrawerBody className={styles.mainContent}>
			{brands ? (
				isMobile ? (
					<BrandSelectorMobile
						brands={brands.brands}
						isDrawer={true}
						onChooseBrand={onChooseBrand}
					></BrandSelectorMobile>
				) : (
					<BrandSelectorDesktop
						brands={brands.brands}
						isDrawer={true}
						onChooseBrand={onChooseBrand}
					></BrandSelectorDesktop>
				)
			) : (
				<section className={classNames(styles.skeletonWrapper, 'u-container-lg')}>
					<ul
						className={styles.skeletonList}
						style={
							{
								'--gridColumnSize': `${gridColAmount}`,
							} as React.CSSProperties
						}
					>
						{emptyArray.map((index, i) => {
							return (
								<li key={`${index}-${i}`} className={styles.skeletonItem}>
									<span className={'u-spinner'}></span>
								</li>
							);
						})}
					</ul>
					<div className={classNames(styles.manageAccountsWrapper, styles.manageAccountsPosition)}>
						<Link className={styles.link} to={accountUrl} state={route}>
							{translations?.brandSelector.manageAccounts}
						</Link>
					</div>
				</section>
			)}
		</DrawerBody>
	);
};
