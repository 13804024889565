import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'library/routing';
import { useTranslationQuery } from 'api/translations';
import { BasketGroupingTypeRequest } from 'generated/data-contracts';

interface GroupingOption {
	label: string;
	sortBy: BasketGroupingTypeRequest;
}

export interface UseBasketGroupingReturnType {
	currentGrouping: BasketGroupingTypeRequest;
	setCurrentGrouping: React.Dispatch<React.SetStateAction<BasketGroupingTypeRequest>>;
	groupingOptions: GroupingOption[];
}

const BASKET_GROUP_BY_SEARCH_PARAM = 'groupBy';
const DEFAULT_GROUPING_OPTION = BasketGroupingTypeRequest.Non;

export const useBasketGrouping = (): UseBasketGroupingReturnType => {
	const location = useLocation();
	const { data: translations } = useTranslationQuery();
	if (!translations) throw new Error('No translations are available');
	const [searchParams, setSearchParams] = useSearchParams();
	const groupingOptions = React.useMemo<GroupingOption[]>(() => {
		return [
			{
				label: translations.basket.grouping.ungrouped,
				sortBy: BasketGroupingTypeRequest.Non,
			},
			{
				label: translations.basket.grouping.category,
				sortBy: BasketGroupingTypeRequest.Category,
			},
			{
				label: translations.basket.grouping.deliveryDate,
				sortBy: BasketGroupingTypeRequest.ShippingDate,
			},
			{
				label: translations.basket.grouping.productLine,
				sortBy: BasketGroupingTypeRequest.ProductLine,
			},
			{
				label: translations.basket.grouping.noos,
				sortBy: BasketGroupingTypeRequest.Noos,
			},
		];
	}, [
		translations.basket.grouping.category,
		translations.basket.grouping.deliveryDate,
		translations.basket.grouping.noos,
		translations.basket.grouping.productLine,
		translations.basket.grouping.ungrouped,
	]);
	const [currentGrouping, setCurrentGrouping] = React.useState<BasketGroupingTypeRequest>(() => {
		const value = searchParams.get(BASKET_GROUP_BY_SEARCH_PARAM);
		const option = groupingOptions.find((r) => r.sortBy === value);
		if (option) return option.sortBy;
		return DEFAULT_GROUPING_OPTION;
	});

	React.useEffect(() => {
		setSearchParams(
			(prev) => {
				const newSearchParams = new URLSearchParams(prev);
				newSearchParams.set(BASKET_GROUP_BY_SEARCH_PARAM, currentGrouping);
				return newSearchParams.toString();
			},
			{
				replace: true,
				state: location.state,
			},
		);
	}, [setSearchParams, currentGrouping, location.state]);

	return React.useMemo(
		() => ({
			currentGrouping,
			groupingOptions,
			setCurrentGrouping,
		}),
		[currentGrouping, groupingOptions],
	);
};
