import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { useProductCardContext } from '../../context/ProductCardContext';
import BottomSplash from '../BottomSplash';

export const InBasket: FC = () => {
	const { product } = useProductCardContext();
	const { data: translations } = useTranslationQuery();
	const splash = product.splashes.quantityInBasket;

	if (!splash) {
		return null;
	}
	return <BottomSplash icon="basket" text={translations?.productCard.inBasket} quantity={splash} />;
};
