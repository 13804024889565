import React from 'react';
import { cloneDeep } from 'lodash';
import { FilterValueResponse } from 'generated/data-contracts';
import { FilterState } from '../types/FilterState';
import { SingleFilterType, FilterTypesEnum } from '../types/FilterTypes';

type UseSingleFilterStateProps = {
	uncommittedFilters: FilterState;
	setUncommittedFilters: React.Dispatch<React.SetStateAction<FilterState>>;
	selectedFilters: FilterState;
	setSelectedFilters: React.Dispatch<React.SetStateAction<FilterState>>;
	filters: SingleFilterType[];
};

export type UseSingleFilterStateReturnType = {
	isSingleSelected: (filterId: string, item: FilterValueResponse) => boolean;
	onChangeSingleItem: (filterId: string, item: FilterValueResponse) => void;
	onInitializeSingleFilter: (filterId: string, item: FilterValueResponse) => void;
	onRemoveSingleItem: (filterId: string) => void;
};

export const useSingleFilterState = ({
	uncommittedFilters,
	setUncommittedFilters,
	setSelectedFilters,
}: UseSingleFilterStateProps): UseSingleFilterStateReturnType => {
	const isSingleSelected = (filterId: string, item: FilterValueResponse): boolean => {
		const filter = uncommittedFilters[FilterTypesEnum.Single].get(filterId);
		return filter?.value === item.value;
	};

	const onChangeSingleItem = (filterId: string, item: FilterValueResponse) => {
		return setUncommittedFilters((prev): FilterState => {
			const allSingleFilters = prev[FilterTypesEnum.Single];
			const currentFilter = allSingleFilters.get(filterId);
			if (currentFilter?.value === item.value) {
				allSingleFilters.delete(filterId);
			} else {
				allSingleFilters.set(filterId, item);
			}
			prev[FilterTypesEnum.Single] = allSingleFilters;
			return cloneDeep(prev);
		});
	};

	const onInitializeSingleFilter = (filterId: string, item: FilterValueResponse) => {
		return setSelectedFilters((prev): FilterState => {
			prev[FilterTypesEnum.Single].set(filterId, item);
			return cloneDeep(prev);
		});
	};

	const onRemoveSingleItem = (filterId: string) => {
		return setSelectedFilters((prev): FilterState => {
			prev[FilterTypesEnum.Single].delete(filterId);
			return cloneDeep(prev);
		});
	};

	return {
		isSingleSelected,
		onChangeSingleItem,
		onInitializeSingleFilter,
		onRemoveSingleItem,
	};
};
