import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { useFilterContext } from '../../../context/FilterContext';
import { Filter } from '../../../types/FilterTypes';
import { SelectedItemsSection } from '../SelectedItemsSection';
import styles from './FilterBody.module.scss';

export interface FilterBodyProps {
	id: string;
	filter: Filter;
	isOpen: boolean;
	hasSelectedItemsSection?: boolean;
	onClose: () => void;
	headerProps?: React.PropsWithChildren;
}

export const FilterBody = React.forwardRef<HTMLElement, React.PropsWithChildren<FilterBodyProps>>(
	({ id, children, filter, isOpen, hasSelectedItemsSection, onClose, headerProps }, ref) => {
		const filterContext = useFilterContext();
		const footerRef = React.useRef<HTMLElement>(null);
		const onCancel = () => {
			filterContext.closeWithoutSaving();
			onClose();
		};
		const onSave = () => {
			filterContext.commitFilters();
			onClose();
		};
		const { data: translations } = useTranslationQuery();
		return (
			<section
				ref={ref}
				className={classNames(styles.filterDialog, {
					[styles.isOpen]: isOpen,
				})}
				id={id}
				role="listbox"
			>
				<header className={styles.filterHeader}>
					<div className={styles.headerRow}>
						<h4 className={styles.title}>{filter.label}</h4>
						<Button variant="ghost" hasOnlyIcon size="sm" onClick={onCancel}>
							<Icon name="close" size="sm" />
						</Button>
					</div>
					{headerProps?.children}
				</header>
				<div className={styles.filterBody}>{children}</div>
				{isOpen && hasSelectedItemsSection && (
					<SelectedItemsSection filterId={filter.id} footerRef={footerRef} />
				)}
				<footer ref={footerRef} className={styles.filterFooter}>
					<Button onClick={onSave} size="sm" variant="dark" className={styles.chooseButton}>
						{translations?.productList.filters.choose}
					</Button>
				</footer>
			</section>
		);
	},
);
FilterBody.displayName = 'FilterBody';
