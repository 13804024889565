import React, { FC } from 'react';
import classNames from 'classnames';
import { Icon } from 'components/shared';
import styles from './IncomingVariantIcon.module.scss';
import { useVariantAvailabilityIcon } from './useVariantAvailabilityIcon';

type IncomingVariantIconProps = {
	variantId?: string;
	shouldUseStockAvailability?: boolean;
	inputValue?: number;
	isMobile?: boolean;
	className?: string;
};

/**
 * Displays an icon based on the availability of a variant.
 * @param variantId - The ID of the variant.
 * @param shouldUseStockAvailability - A boolean to determine if the stock availability should be used.
 * @param variantQuantity - The quantity of the variant.
 * @param className - The class name to apply to the icon.
 * @returns The icon component.
 * @example <IncomingVariantIcon variantId="123" inputValue={1} className="icon" />
 */
export const IncomingVariantIcon: FC<IncomingVariantIconProps> = ({
	variantId,
	shouldUseStockAvailability = false,
	inputValue,
	isMobile = false,
	className,
}) => {
	const availabilityIcon = useVariantAvailabilityIcon({
		variantId,
		inputValue,
		shouldUseStockAvailability: shouldUseStockAvailability,
	});
	if (!availabilityIcon) return null;
	return (
		<Icon
			className={classNames(styles.availabilityIcon, { [styles.availabilityIconMobile]: isMobile }, className)}
			name={availabilityIcon}
			size="sm"
		/>
	);
};
