import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useCreateBasketMutation } from 'api/basket';
import { useTranslationQuery } from 'api/translations';
import AccountSelectorBody from 'components/fragments/AccountSelectorBody';
import { AlertTypes } from 'components/shared';
import { Button } from 'components/shared/Button';
import { DrawerBody, DrawerFooter } from 'components/shared/Drawer';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { CreateBasketRequest, ShipToInfoResponse } from 'generated/data-contracts';
import { messageToApp } from 'helpers/app';
import { useViewportHeight } from 'helpers/useViewportSizeHeight';
import styles from './CreateView.module.scss';

interface CreateViewProps {
	handleBack: () => void;
	handleClose: () => void;
	productId?: string;
}

const CreateView: React.FunctionComponent<CreateViewProps> = ({ handleBack, handleClose, productId }) => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	const createBasket = useCreateBasketMutation();
	const queryClient = useQueryClient();

	const [isLoading, setIsLoading] = React.useState(false);
	const [selectedShipTos, setSelectedShipTos] = useState<ShipToInfoResponse[]>([]);

	const { isSmallScreenSize } = useViewportHeight();

	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

	const [filterList, setFilterList] = React.useState<ShipToInfoResponse[]>([]);

	const onToggleClick = (): void => {
		setIsExpanded(!isExpanded);
	};

	const handleChange = (event): void => {
		const { value } = event.target;

		if (selectedShipTos.some((shipTo) => shipTo.id === value)) {
			// remove shipTo from selected list
			setSelectedShipTos(selectedShipTos.filter((shipTo) => shipTo.id !== value));

			// handle if selectedShipTos gets empty
			if (selectedShipTos.length === 1) {
				setIsExpanded(false);
			}
		} else {
			// find checked shipTo object from showed list
			const checkedShipTo = filterList.find((shipTo) => shipTo.id === value);
			// add shipTo to selected list
			if (checkedShipTo) setSelectedShipTos((prevState) => [...prevState, checkedShipTo]);
		}
	};

	const handleSubmit = (event): void => {
		event.preventDefault();

		const shipToIds = selectedShipTos.map(({ id }) => id);

		const body: CreateBasketRequest = {
			selectBasket: true,
			shipToIds,
		};

		setIsLoading(true);

		createBasket.mutate(
			{ query: { body, familyId: productId } },
			{
				onSuccess: () => {
					messageToApp({ type: 'basketChanged' });

					notificationActions.addNotification({
						type: AlertTypes.SUCCESS,
						children: translations?.openBaskets.create.success,
					});

					queryClient.invalidateQueries();
					handleClose();
				},
				onError: () => {
					notificationActions.addNotification({
						type: AlertTypes.DANGER,
						children: translations?.openBaskets.create.error,
					});
				},
				onSettled: () => {
					setIsLoading(false);
				},
			},
		);
	};

	return (
		<>
			<DrawerBody className={classNames(styles.body, { [styles.hasNoTopPadding]: isExpanded })}>
				<AccountSelectorBody
					handleChange={handleChange}
					onToggleClick={onToggleClick}
					isSelectedShipTosExpanded={isExpanded}
					setFilterList={setFilterList}
					selectedShipTos={selectedShipTos}
					setSelectedShipTos={setSelectedShipTos}
					handleSubmit={handleSubmit}
					isCreateBasketView
					page={1}
					formId="createBasketForm"
				/>
			</DrawerBody>

			<DrawerFooter className={styles.footer}>
				<Button
					form={'createBasketForm'}
					type="submit"
					variant="dark"
					isFullWidth
					isLoading={isLoading}
					disabled={selectedShipTos.length === 0}
				>
					{translations?.openBaskets.create.submit}
				</Button>

				<Button
					className={classNames({ ['u-mt-sm']: !isSmallScreenSize })}
					variant="secondary"
					isFullWidth
					onClick={handleBack}
				>
					{translations?.shared.cancel}
				</Button>
			</DrawerFooter>
		</>
	);
};

export default CreateView;
