import React, { useState, useEffect, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { queryKeys } from 'api/apiConfig';
import { useSetFavouriteMutation, useRemoveFavouriteMutation } from 'api/favouriteList';
import { useTranslationQuery } from 'api/translations';
import { Button, ButtonSizeType } from 'components/shared/Button';
import { Icon, IconSize } from 'components/shared/Icon';
import { AddToFavouriteListRequest, RemoveFromFavouriteListRequest } from 'generated/data-contracts';
import { messageToApp } from 'helpers/app';
import styles from './FavouriteIcon.module.scss';

interface FavouriteIconProps {
	productMasterId?: string;
	deliveryMonth?: string;
	isFreeAssortment?: boolean;
	className?: string;
	isFavourite?: boolean;
	showRemoveIcon?: boolean;
	buttonSize?: ButtonSizeType;
	iconSize?: IconSize;
}

/**
 * Generic FavouriteIcon component, used for setting products as favourites
 */
export const FavouriteIcon: React.FunctionComponent<FavouriteIconProps> = ({
	className,
	deliveryMonth = '',
	productMasterId = '',
	isFreeAssortment = false,
	isFavourite,
	showRemoveIcon,
	buttonSize = 'md',
	iconSize = 'md',
}) => {
	const queryClient = useQueryClient();
	const { data: translations } = useTranslationQuery();

	const setFavourite = useSetFavouriteMutation();
	const removeFavourite = useRemoveFavouriteMutation();

	const [isFilled, setIsFilled] = React.useState(isFavourite);
	const [isBeingDeleted, setIsBeingDeleted] = useState(false);

	const buttonTitle = showRemoveIcon
		? translations?.shared.delete
		: isFilled
		? translations?.favouriteList.removeProductToFavouriteList
		: translations?.favouriteList.addProductToFavouriteList;

	const invalidateFavouriteListQueries = useCallback(async () => {
		await Promise.all([
			queryClient.invalidateQueries({ queryKey: queryKeys.favouritelist.groupItems._def }),
			queryClient.invalidateQueries({ queryKey: queryKeys.favouritelist.listInit._def }),
			queryClient.invalidateQueries({ queryKey: queryKeys.favouritelist.list._def }),
			queryClient.invalidateQueries({ queryKey: queryKeys.favouritelist.total._def }),
			queryClient.invalidateQueries({ queryKey: queryKeys.product.fetch._def }),
			queryClient.invalidateQueries({ queryKey: queryKeys.product.list._def }),
			queryClient.invalidateQueries({ queryKey: queryKeys.product.bundleQuery._def }),
		]);

		messageToApp({ type: 'reloadFavoriteList' });
	}, [queryClient]);

	useEffect(() => {
		setIsFilled(isFavourite);
	}, [productMasterId, isFavourite]);

	const handleClick = (): void => {
		if (setFavourite.isPending || removeFavourite.isPending || isFilled != isFavourite) {
			return;
		}

		setIsFilled((prev) => !prev);

		const favouriteInfo: RemoveFromFavouriteListRequest | AddToFavouriteListRequest = {
			productMasterId: productMasterId,
			deliveryDate: deliveryMonth,
			isFreeAssortment: isFreeAssortment,
		};

		const shouldRemove = isFilled || showRemoveIcon;

		if (shouldRemove) {
			setIsBeingDeleted(Boolean(showRemoveIcon));

			removeFavourite.mutate(favouriteInfo, {
				onSuccess: async () => await invalidateFavouriteListQueries(),
				onError: () => setIsFilled(true),
			});
		} else {
			setFavourite.mutate(favouriteInfo, {
				onSuccess: async () => await invalidateFavouriteListQueries(),
				onError: () => setIsFilled(false),
			});
		}
	};

	return (
		<Button
			disabled={showRemoveIcon && isBeingDeleted}
			className={classNames(styles.wrapper, className)}
			title={buttonTitle}
			variant="ghost"
			size={buttonSize}
			hasOnlyIcon
			onClick={handleClick}
		>
			{showRemoveIcon ? (
				<Icon className={styles.iconHover} name={'bin'} size={iconSize} />
			) : (
				<Icon
					className={classNames(styles.favouriteIcon, styles.iconHover, { [styles.isFilled]: isFilled })}
					name={isFilled ? 'favouriteFilled' : 'favourite'}
					size={iconSize}
				/>
			)}
		</Button>
	);
};
