import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/shared';
import styles from './DisabledCell.module.scss';

interface DisabledCellProps {
	className?: string;
}

const DisabledCell: FC<DisabledCellProps> = ({ className }) => {
	const { data: translations } = useTranslationQuery();
	return (
		<Tooltip placement="top" delay={100} showArrow={true}>
			<TooltipTrigger className={classNames(styles.disabledCellTooltipTrigger, className)}>
				<div />
			</TooltipTrigger>
			<TooltipContent className={styles.disabledCellToolTipContent}>
				<div className={styles.disabledCellTooltipText}>{translations?.basket.disabledCellInBasket}</div>
			</TooltipContent>
		</Tooltip>
	);
};
export default DisabledCell;
