import React from 'react';
import { isEqual } from 'lodash';
import { BasketRequest, useUpdateBasketLinesMutation } from 'api/newBasket';
import { BasketFilterType, BasketLineQuantityResponse, BasketLineRequest } from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { useDebounce } from 'helpers/useDebounce';
import { NOW_DELIVERY_DATE_VALUE } from '../shared/basketTypes';

type ResponseType = BasketLineQuantityResponse | undefined;

type UseBasketLinesUpdateContextReturnType = (basketLine: BasketLineRequest) => Promise<ResponseType>;

interface RequestType {
	data: BasketLineRequest;
	resolve: (value: ResponseType | PromiseLike<ResponseType>) => void;
}
export const useBasketLinesUpdateState = (basketQuery: BasketRequest): UseBasketLinesUpdateContextReturnType => {
	const [basketLines, setBasketLines] = React.useState<RequestType[]>([]);

	const { mutate } = useUpdateBasketLinesMutation();

	const updateBasketLines: (query: RequestType[]) => Promise<HttpResponse<ResponseType, unknown>[]> =
		// eslint-disable-next-line react-hooks/exhaustive-deps
		React.useCallback(
			useDebounce(
				(query: RequestType[]) =>
					new Promise<HttpResponse<ResponseType, unknown>[]>(() => {
						const firstLine = query[0].data;
						mutate(
							{
								query: {
									basketLines: query.map((basketLine) => basketLine.data),
									// These are gotten from the first basket line, as they should be the same for all basket lines,
									// as they are all added at the same time
									deliveryDate: firstLine.deliveryDate ?? NOW_DELIVERY_DATE_VALUE,
									requestedShipmentFilterTypes: [
										BasketFilterType.All,
										BasketFilterType.DeliveryDate,
										BasketFilterType.Now,
									],
									shipmentFilterType: basketQuery.shippingFilter,
									shipmentFilterValue: basketQuery.shippingFilterDate,
								},
							},
							{
								onSuccess: async ({ data }) => {
									query.forEach((basketLine) => {
										const currentLine = {
											bundleId: basketLine.data.bundleId,
											variantId: basketLine.data.variantId ?? undefined,
											deliveryDate: basketLine.data.deliveryDate,
											shipToId: basketLine.data.shipToId,
										};
										const relatedBasketLine = data.find((b) => {
											const lineToCompare = {
												bundleId: b.bundleId,
												variantId: b.variantId ?? undefined,
												deliveryDate: b.deliveryDate,
												shipToId: b.shipToId,
											};

											return isEqual(currentLine, lineToCompare);
										});
										basketLine.resolve(relatedBasketLine);
									});
								},
								onError: (err) => {
									Promise.reject(err);
								},
								onSettled: () => {
									setBasketLines([]);
								},
							},
						);
					}),
				10,
			),
			[mutate],
		);

	React.useEffect(() => {
		if (basketLines.length > 0) {
			updateBasketLines(basketLines);
		}
	}, [basketLines, updateBasketLines]);

	const addBasketLine = (basketLine: BasketLineRequest): Promise<ResponseType> => {
		return new Promise<ResponseType>((resolve) => {
			setBasketLines((prev) => {
				return [
					...prev,
					{
						data: basketLine,
						resolve,
					},
				];
			});
		});
	};

	return addBasketLine;
};
