import React, { FC } from 'react';
import {
	autoUpdate,
	flip,
	offset,
	shift,
	hide,
	useFloating,
	useInteractions,
	useRole,
	FloatingPortal,
} from '@floating-ui/react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { useBasketRowContext } from 'components/fragments/Basket/Desktop/Shared/context/BasketRowContext';
import { Button, Icon } from 'components/shared';
import { ProductImage } from 'components/shared/ProductImage';
import { StockAvailabilityDesktop } from 'components/shared/StockAvailabilityDesktop';
import { BasketAvailabilityResponse, NewBasketProductMasterResponse, SplashType } from 'generated/data-contracts';
import styles from './FreeAssortmentStockPopup.module.scss';

interface FreeAssortmentStockPopupProps {
	masterInfo?: NewBasketProductMasterResponse;
	isSelected?: boolean;
	closePopup?: () => void;
	realTimeTotalInBasket: number;
	availability: BasketAvailabilityResponse | undefined;
	inputRef?: React.RefObject<HTMLInputElement>;
}

export const FreeAssortmentStockPopup: FC<FreeAssortmentStockPopupProps> = ({
	masterInfo,
	isSelected = false,
	realTimeTotalInBasket,
	availability,
	closePopup,
	inputRef,
}) => {
	const { shouldUseAvailability } = useBasketRowContext();
	const { refs, floatingStyles, context } = useFloating({
		placement: 'bottom-start',
		open: isSelected,
		middleware: [offset(0), shift(), flip(), hide()],
		elements: {
			reference: inputRef?.current,
		},
		whileElementsMounted: autoUpdate,
		strategy: 'fixed',
	});

	const role = useRole(context, { role: 'dialog' });

	const { data: translations } = useTranslationQuery();
	const { getFloatingProps } = useInteractions([role]);
	if (!masterInfo || !shouldUseAvailability) {
		return null;
	}
	return (
		<>
			{isSelected && (
				<FloatingPortal>
					<section
						ref={refs.setFloating}
						style={floatingStyles}
						{...getFloatingProps()}
						className={classNames(styles.popup)}
					>
						<div className={styles.masterInfoWrapper}>
							<div className={styles.masterInfo}>
								<div className={styles.masterImageWrapper}>
									<ProductImage
										className={styles.masterImage}
										colorDotClassName={classNames(styles.masterWrapper, styles.masterImage)}
										desktopSrc={masterInfo?.images[0]?.size100}
										isThumbnail
										altText={masterInfo?.images[0]?.altText ?? ''}
										colorInfo={masterInfo.colour}
									/>

									{masterInfo.splashes.map((splash) => {
										return (
											<div
												key={`splash-${splash.type}`}
												className={classNames({
													[styles.discountSplash]: splash.type === SplashType.Discount,
													[styles.noosSplash]: splash.type === SplashType.NeverOutOfStockType,
												})}
											>
												{splash.text}
											</div>
										);
									})}
								</div>

								<span>{masterInfo?.colour?.name}</span>
							</div>
							<Button
								title={translations?.shared?.close}
								hasOnlyIcon
								size="xs"
								variant="outline"
								onClick={(): void => closePopup?.()}
							>
								<Icon size="sm" name={'close'} />
							</Button>
						</div>

						<div className={styles.stockContainer}>
							<StockAvailabilityDesktop
								availability={availability}
								quantity={realTimeTotalInBasket}
								renderInPortal={false}
							/>
						</div>
					</section>
				</FloatingPortal>
			)}
		</>
	);
};
