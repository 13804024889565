import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { ProductCardPricing } from '../ProductCardPricing';
import { ProductCardTitle } from '../ProductCardTitle';
import styles from './ProductCardInfo.module.scss';

export interface ProductCardInfoProps {
	className?: string;

	Title?: React.ReactNode;
	PriceInfo?: React.ReactNode;
}

type ProductCardInfoSubComponents = {
	Title: typeof ProductCardTitle;
	PriceInfo: typeof ProductCardPricing;
};

export const ProductCardInfo: React.FunctionComponent<ProductCardInfoProps> & ProductCardInfoSubComponents = ({
	className,
	PriceInfo,
	Title,
}: ProductCardInfoProps) => {
	return (
		<div className={classNames(styles.info, className)}>
			{Title}
			{!isEmpty(PriceInfo) && (
				<>
					<hr className={classNames(styles.divider, 'u-hr')} />
					{PriceInfo}
				</>
			)}
		</div>
	);
};

ProductCardInfo.Title = ProductCardTitle;
ProductCardInfo.PriceInfo = ProductCardPricing;
