import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef } from 'react';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useSearchParams } from 'library/routing';
import { isEqual, uniq } from 'lodash';
import { useOrderHistoryGroupingQuery, useOrderOverviewGroupingQuery } from 'api/myOrders';
import { useBasketQuery } from 'api/newBasket';
import { useTranslationQuery } from 'api/translations';
import { useFilterContext } from 'components/shared/FilterList/V2/context/FilterContext';
import { convertFilterResponseToCheckboxFilter } from 'components/shared/FilterList/V2/helpers/convertFilterResponseToCheckboxFilter';
import { FilterTypesEnum } from 'components/shared/FilterList/V2/types/FilterTypes';
import {
	OrderHistoryViewportSize,
	useOrderHistoryViewportSize,
} from 'components/shared/hooks/useOrderHistoryViewportSize';
import {
	ExportInformationResponse,
	FilterRequest,
	GroupingResponse,
	OrderHistoryGroupingRequest,
	OrderHistorySearchGroup,
	OrderHistorySearchGroupingType,
	OrderHistorySort,
	OrderOverviewRequest,
	OrderOverviewSearchResponse,
	SortDirection,
	SortingResponseOrderHistorySort,
} from 'generated/data-contracts';
import { convertFiltersToFilterRequest } from '../helpers/convertFiltersToFilterRequests';
import { getOrderHistoryDesktopHeader } from '../helpers/getOrderHistoryDesktopHeader';
import { useSearch, UseSearchReturnType } from 'helpers/useSearch';
import { OrderListUrlParamKeys, PREDEFINED_ORDERLIST_URL_PARAMS } from '../constants/OrderListUrlParamKeys';
import { useAccountsFilter } from '../hooks/useAccountsFilter';
import {
	GroupingOption,
	OrderHistorySearchGroupingTypeExtended,
	OrderHistorySearchGroupingTypeExtendedValue,
	useGroupingFilter,
} from '../hooks/useGroupingFilter';
import { useSortingOptions } from '../hooks/useSortingOptions';

type OrderHistoryLayoutInfo = {
	totalValue?: string;
	totalQuantity?: number;
};

type UseOrdersPageContextResponse = {
	header: { label: string; sortBy?: OrderHistorySort }[];
	ordersResponse?: UseInfiniteQueryResult<OrderOverviewSearchResponse | undefined, Error>;
	overviewResponse?: OrderOverviewSearchResponse;
	shipToGroupings?: GroupingResponse[];
	status: OrderHistorySearchGroup;
	layoutInfo: OrderHistoryLayoutInfo;
	isLoading: boolean;
	size: OrderHistoryViewportSize;
	changeSort: (sort: OrderHistorySort, sortDirection: SortDirection) => void;
	groupingOptions: GroupingOption[];
	currentGrouping: OrderHistorySearchGroupingTypeExtended;
	changeGrouping: (grouping: OrderHistorySearchGroupingTypeExtended) => void;
	search: UseSearchReturnType;
	currentSort?: SortingResponseOrderHistorySort;
	sortOptions?: SortingResponseOrderHistorySort[];
	selectedShipTos: string[] | undefined;
	exportInformation?: ExportInformationResponse;
	isOnOrdersOverview: boolean;
	filterRequests: FilterRequest[];
};

const parseQueryParameters = (
	searchParams: URLSearchParams,
	selectedFilters: FilterRequest[] = [],
	selectedShipTos?: string[],
): OrderHistoryGroupingRequest => {
	const filters = selectedFilters.filter((filter) => {
		if (!filter.filter) return true;
		return !PREDEFINED_ORDERLIST_URL_PARAMS.includes(filter.filter);
	});
	return {
		phrase: searchParams.get(OrderListUrlParamKeys.SearchPhrase) || '',
		groupingType: OrderHistorySearchGroupingType.ShipTo, // This is hardcoded intentionally because this is the default grouping type
		collapseGroups: [],
		status: (searchParams.get(OrderListUrlParamKeys.Tab) as OrderHistorySearchGroup) ?? OrderHistorySearchGroup.All,
		shipToIds: selectedShipTos?.length ? selectedShipTos : undefined,
		filters,
	};
};

const useOrdersPageState = (): UseOrdersPageContextResponse => {
	const { data: shipTos } = useBasketQuery();
	const { changeGrouping, groupingOptions, currentGrouping } = useGroupingFilter();
	const search = useSearch();
	const { changeSort, currentSort, setSortOptions, sortOptions } = useSortingOptions();
	const size = useOrderHistoryViewportSize(currentGrouping);
	const filters = useFilterContext();
	const [searchParams] = useSearchParams();
	const { data: translations } = useTranslationQuery();

	const selectedShipTos = React.useMemo(() => {
		const selectedShipTosValues = filters.selectedFilters[FilterTypesEnum.Checkbox]
			.get(OrderListUrlParamKeys.ShipTo)
			?.keys();
		if (!selectedShipTosValues) return [];
		return uniq([...selectedShipTosValues]);
	}, [filters.selectedFilters]);
	const filterRequests = React.useMemo(
		() =>
			convertFiltersToFilterRequest(filters.selectedFilters, {
				excluded: PREDEFINED_ORDERLIST_URL_PARAMS,
			}),
		[filters.selectedFilters],
	);
	const searchQuery = React.useMemo((): OrderHistoryGroupingRequest => {
		return parseQueryParameters(searchParams, filterRequests, selectedShipTos);
	}, [filterRequests, searchParams, selectedShipTos]);

	const isOverviewEnabled = currentGrouping === OrderHistorySearchGroupingTypeExtendedValue.Overview;
	const { data: shipToResponse, isLoading: isLoadingGroup } = useOrderHistoryGroupingQuery(
		!isOverviewEnabled,
		searchQuery,
	);
	const accountsFilter = useAccountsFilter(selectedShipTos);

	const orderOverviewQuery: OrderOverviewRequest | undefined = searchQuery
		? {
				...searchQuery,
				sortBy: currentSort?.sortBy,
				sortDirection: currentSort?.sortDirection,
		  }
		: undefined;
	const ordersResponse = useOrderOverviewGroupingQuery(isOverviewEnabled, orderOverviewQuery);
	const { data: overviewResponse, isLoading: isLoadingOverview } = ordersResponse;

	useEffect(() => {
		setSortOptions(isOverviewEnabled ? overviewResponse?.sorting : shipToResponse?.sorting);
	}, [isOverviewEnabled, overviewResponse?.sorting, setSortOptions, shipToResponse?.sorting]);

	const header = useMemo(
		() => getOrderHistoryDesktopHeader(isOverviewEnabled, translations),
		[isOverviewEnabled, translations],
	);

	const filtersFromResponse = React.useMemo(() => {
		if (isOverviewEnabled)
			return [accountsFilter, ...(overviewResponse?.filters ?? []).map(convertFilterResponseToCheckboxFilter)];
		return [accountsFilter, ...(shipToResponse?.filters ?? []).map(convertFilterResponseToCheckboxFilter)];
	}, [accountsFilter, isOverviewEnabled, overviewResponse?.filters, shipToResponse?.filters]);

	const { setAvailableFilters } = filters;
	useEffect(() => {
		setAvailableFilters(filtersFromResponse);
	}, [filtersFromResponse, setAvailableFilters]);

	const initializedShipTos = useRef<string[]>([]);
	const selectedShipToLength =
		filters.selectedFilters[FilterTypesEnum.Checkbox].get(OrderListUrlParamKeys.ShipTo)?.size ?? 0;

	/**
	 * For the MyOrders shipTo filter, some special rules apply.
	 *
	 * These need to be instantiated with the shipTos from the basket, IF the url does not contain any shipTos.
	 * However, if the shipTos in the basket are changed, the filter must also be set to those shipTos, overwriting the old value.
	 */
	useEffect(() => {
		if (!shipTos) return;
		if (
			selectedShipToLength === 0 ||
			(initializedShipTos.current.length !== 0 && !isEqual(initializedShipTos.current, shipTos.basketShipTos))
		) {
			filters.checkboxActions.onInitializeCheckboxFilter(
				OrderListUrlParamKeys.ShipTo,
				shipTos.basketShipTos.map((v) => ({
					isSelected: true,
					label: v,
					value: v,
				})),
			);
		}
		initializedShipTos.current = shipTos.basketShipTos;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedShipToLength, filters.checkboxActions.onInitializeCheckboxFilter, shipTos]);

	const layoutInfo = React.useMemo(() => {
		if (isOverviewEnabled) {
			return {
				totalValue: ordersResponse?.data?.totalValue ?? undefined,
				totalQuantity: ordersResponse?.data?.totalQuantity,
			};
		}

		return {
			totalValue: shipToResponse?.totalValue ?? undefined,
			totalQuantity: shipToResponse?.totalQuantity,
		};
	}, [
		isOverviewEnabled,
		ordersResponse?.data?.totalQuantity,
		ordersResponse?.data?.totalValue,
		shipToResponse?.totalQuantity,
		shipToResponse?.totalValue,
	]);

	return {
		header,
		overviewResponse,
		ordersResponse,
		shipToGroupings: shipToResponse?.groupings,
		layoutInfo,
		status: (searchParams.get(OrderListUrlParamKeys.Tab) as OrderHistorySearchGroup) ?? OrderHistorySearchGroup.All,
		isLoading: isOverviewEnabled ? isLoadingOverview : isLoadingGroup,
		filterRequests,
		selectedShipTos,
		search,
		size,
		changeGrouping,
		groupingOptions,
		currentGrouping,
		changeSort,
		currentSort,
		sortOptions,
		exportInformation: shipToResponse?.exportInformation,
		isOnOrdersOverview: isOverviewEnabled,
	};
};

const OrdersPageContext = React.createContext<UseOrdersPageContextResponse | null>(null);

export const OrdersPageContextProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
	const ordersActions = useOrdersPageState();
	return <OrdersPageContext.Provider value={ordersActions}>{children}</OrdersPageContext.Provider>;
};

export const useOrdersPageContext = (): UseOrdersPageContextResponse => {
	const context = useContext(OrdersPageContext);

	if (!context) {
		throw new Error('useOrdersActionsContext must be used within a OrdersActionsContextProvider');
	}

	return context;
};
