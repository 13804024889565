import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Icon } from 'components/shared/Icon';
import { SplashType } from 'generated/data-contracts';
import { useProductCardContext } from '../../context/ProductCardContext';
import styles from './Cmi.module.scss';

export interface CmiSplashProps {
	className?: string;
}

export const CmiSplash: React.FunctionComponent<CmiSplashProps> = ({ className }) => {
	const { product } = useProductCardContext();
	const { data: translations } = useTranslationQuery();
	const splash = product.splashes[SplashType.Cmi];
	if (!splash) return;
	return (
		<div className={classNames(className, styles.splash)}>
			<Icon name="cmi" size="xs" className={styles.icon} />
			{translations?.cmi.heading}
		</div>
	);
};
