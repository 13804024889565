import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { InputCheckbox } from 'components/shared';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { FilterValueResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { useFilterContext } from '../../../context/FilterContext';
import { FilterTypesEnum } from '../../../types/FilterTypes';
import styles from './SelectedItemsSection.module.scss';

export interface SelectedItemsSectionProps {
	filterId: string;
	footerRef: React.RefObject<HTMLElement>;
}

export const SelectedItemsSection: React.FunctionComponent<SelectedItemsSectionProps> = ({ filterId, footerRef }) => {
	const headerRef = React.useRef<HTMLHeadElement>(null);
	const { uncommittedFilters, checkboxActions } = useFilterContext();
	const { data: translations } = useTranslationQuery();
	const [isExpanded, setIsExpanded] = React.useState(false);
	const selectedItems =
		uncommittedFilters[FilterTypesEnum.Checkbox].get(filterId) ?? new Map<string, FilterValueResponse>();
	const selectedEntries = Array.from(selectedItems.values());

	const headerFallbackHeight = `3.375rem`;
	const height =
		selectedItems.size > 0
			? isExpanded
				? `calc(100% - ${footerRef.current?.getBoundingClientRect().height ?? 0}px)`
				: headerRef.current?.getBoundingClientRect().height || headerFallbackHeight
			: 0;

	React.useEffect(() => {
		if (selectedItems.size === 0) setIsExpanded(false);
	}, [selectedItems.size]);

	return (
		<section
			className={styles.selectedAccountsSection}
			style={{
				position: isExpanded ? 'absolute' : 'relative',
				height,
				bottom: isExpanded ? `${footerRef.current?.getBoundingClientRect().height ?? 0}px` : 0,
			}}
		>
			<header ref={headerRef} className={styles.header}>
				<span className={styles.headerText}>
					{formatTranslation(translations?.shared.selectedNItems, {
						0: selectedItems.size.toString(),
					})}
				</span>
				<Button
					variant="linkButton"
					className={styles.headerButton}
					size="sm"
					onClick={() => setIsExpanded((prev) => !prev)}
				>
					<span>{translations?.shared.viewAll}</span>
					<Icon name={'chevronDown'} rotate={isExpanded ? undefined : 180} size="sm" />
				</Button>
			</header>
			<ul
				hidden={!isExpanded}
				style={{
					display: isExpanded ? 'flex' : 'none',
				}}
				className={styles.selectedAccountsList}
			>
				{selectedEntries.map((item) => (
					<li key={`${filterId}-${item.value}-${item.label}`} role="option" aria-selected>
						<InputCheckbox
							controlled
							className={styles.checkbox}
							label={
								<div className={styles.listItem}>
									<span>{item.label}</span>
								</div>
							}
							id={item.value}
							checked
							name={item.label}
							onChange={() => checkboxActions.onChangeCheckboxItem(filterId, item)}
						/>
					</li>
				))}
			</ul>
		</section>
	);
};
