import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/shared/Tooltip';
import { Area, PageType, ResourceLocation } from 'generated/data-contracts';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import { SkeletonButton } from '../SkeletonButton';
import styles from './ViewBasketButton.module.scss';

export interface ViewBasketButtonProps {
	className?: string;
	buttonClassName?: string;
}

export const ViewBasketButton: React.FunctionComponent<ViewBasketButtonProps> = ({ className, buttonClassName }) => {
	const { data: translations } = useTranslationQuery();
	const {
		isSkeleton,
		orderLineDetails: { basketId },
	} = useOrderLineContext();
	if (isSkeleton) return <SkeletonButton />;

	const disableViewBasketButton = true; // !basketId; TODO: https://hesehus-jira.atlassian.net/browse/BSW-4989
	const route: ResourceLocation | undefined = basketId
		? {
				area: Area.StaticPages,
				pageType: PageType.Basket,
				externalRoute: String(basketId),
		  }
		: undefined;
	const ButtonComponent = (
		<Link
			size="sm"
			to={'/not-yet-implemented'}
			route={route}
			className={classNames(styles.viewBasketButton, buttonClassName, {
				[styles.disabled]: disableViewBasketButton,
			})}
		>
			{translations?.myOrders.orderDetail.viewBasket}
			<Icon name="chevronDown" size={'sm'} rotate={270} />
		</Link>
	);

	if (!disableViewBasketButton) {
		return <span className={classNames(className, styles.container)}>{ButtonComponent}</span>;
	}
	return (
		<Tooltip placement="bottom" isRenderedInPortal={false}>
			<TooltipTrigger
				className={classNames(className, styles.container, {
					[styles.disabled]: disableViewBasketButton,
				})}
			>
				{ButtonComponent}
			</TooltipTrigger>
			<TooltipContent className={styles.tooltipText}>
				{translations?.myOrders.orderDetail.viewBasketDisabled}
			</TooltipContent>
		</Tooltip>
	);
};
