import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'library/routing';
import { useTranslationQuery } from 'api/translations';
import { OrderHistorySearchGroupingType, Area, PageType } from 'generated/data-contracts';
import { OrderListUrlParamKeys } from '../constants/OrderListUrlParamKeys';

export enum GroupingOptionType {
	DROPDOWN = 'DROPDOWN',
	BUTTON = 'BUTTON',
}
enum OverviewGroupingType {
	Overview = 'Overview',
}
export type OrderHistorySearchGroupingTypeExtended = OrderHistorySearchGroupingType | OverviewGroupingType;
export const OrderHistorySearchGroupingTypeExtendedValue = {
	...OrderHistorySearchGroupingType,
	...OverviewGroupingType,
};

export interface GroupingOption {
	value: OrderHistorySearchGroupingTypeExtended;
	displayName: string;
	optionType: GroupingOptionType;
}

export const useGroupingFilter = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { data: translations } = useTranslationQuery();
	const groupingOptions: GroupingOption[] = useMemo(
		() =>
			translations
				? [
						{
							value: OrderHistorySearchGroupingTypeExtendedValue.ShipTo,
							displayName: translations.shared.grouping.orderLineGrouping,
							optionType: GroupingOptionType.DROPDOWN,
						},
						{
							value: OrderHistorySearchGroupingTypeExtendedValue.DeliveryMonth,
							displayName: translations.shared.grouping.deliveryGrouping,
							optionType: GroupingOptionType.DROPDOWN,
						},
						{
							value: OrderHistorySearchGroupingTypeExtendedValue.Category,
							displayName: translations.shared.grouping.categoryGrouping,
							optionType: GroupingOptionType.DROPDOWN,
						},
						{
							value: OrderHistorySearchGroupingTypeExtendedValue.Overview,
							displayName: translations.shared.grouping.overviewGrouping,
							optionType: GroupingOptionType.BUTTON,
						},
				  ]
				: [],
		[translations],
	);

	const currentGrouping = useMemo(
		() =>
			groupingOptions.find((r) => r.value === searchParams.get(OrderListUrlParamKeys.GroupedBy))?.value ??
			OrderHistorySearchGroupingTypeExtendedValue.Overview,
		[searchParams, groupingOptions],
	);

	const changeGrouping = useCallback(
		(grouping: OrderHistorySearchGroupingTypeExtended): void => {
			setSearchParams(
				(prev) => {
					prev.set(OrderListUrlParamKeys.GroupedBy, grouping);
					return prev;
				},
				{
					replace: true,
					state: { area: Area.StaticPages, pageType: PageType.Orders },
				},
			);
		},
		[setSearchParams],
	);

	const grouping = useMemo(
		() => ({ groupingOptions, currentGrouping, changeGrouping }),
		[groupingOptions, currentGrouping, changeGrouping],
	);

	return grouping;
};
