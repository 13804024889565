import React from 'react';
import { useDragToCopy } from '..';
import { Icon } from 'components/shared/Icon';
import styles from './DragHandle.module.scss';

export interface DragHandleElement<T> extends HTMLElement {
	ondragcontentstart?: () => T;
	ondropcontent?: (content: T) => boolean;
}

export interface DragHandleProps<T> {
	/** OnDragStart is executed if the content is copied from the current cell
	 *
	 * @returnValue Cell Content - The content of the cell that is being dragged.
	 * This is the value that will be copied into other cells when dropped.
	 */
	onDragStart: () => T;
	/** OnDrop is executed if the content is dropped into the current cell
	 *
	 * @param copiedContent - The content that was copied from another cell.
	 * @returnValue boolean - If the content was successfully dropped into the cell.
	 */
	onDrop: (copiedContent: T) => boolean;

	/** Data type of the content that is being dragged. This is to ensure that you can only drag into similar cells */
	dataType: string;
}

export const DragHandle = <T,>({ onDragStart, onDrop, dataType }: DragHandleProps<T>) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const { isDraggable } = useDragToCopy();
	React.useEffect(() => {
		const element: DragHandleElement<T> | null = ref.current;
		if (!element || !isDraggable) return;
		element.ondragcontentstart = () => {
			return onDragStart();
		};
		element.ondropcontent = (content: T) => {
			return onDrop(content);
		};
	}, [isDraggable, onDragStart, onDrop]);
	return isDraggable ? (
		<div className={styles.dragHandle} ref={ref} data-drag-handle data-drag-type={dataType}>
			<Icon name="drag" size="sm" />
		</div>
	) : null;
};
