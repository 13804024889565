/**
 * Clamps a given value to a specified maximum.
 *
 * @param value - The value to be clamped.
 * @param max - The maximum value to clamp to.
 * @returns The clamped value, which will be the lesser of the input value or the maximum.
 */
export function clampToMax(value: number, max: number): number {
	return value > max ? max : value;
}
