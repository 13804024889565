import React from 'react';
import { SplashType } from 'generated/data-contracts';
import { useProductCardContext } from '../../context/ProductCardContext';
import { Splash } from '../Splash';

export interface DeliveryMonthProps {
	className?: string;
}

export const DeliveryMonth: React.FunctionComponent<DeliveryMonthProps> = ({ className }) => {
	const { product } = useProductCardContext();
	const splash = product.splashes[SplashType.DeliveryMonth];
	if (!splash) return;
	return <Splash className={className} splash={splash} />;
};
