import React, { CSSProperties, FC, memo } from 'react';
import classNames from 'classnames';
import { isEmpty, uniqueId } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { PartialBasketLineRequest } from 'components/features/ProductDetail/ProductBasket/V2/shared/basketTypes';
import { GridCell } from 'components/fragments/Basket/Desktop/Shared/GridCell';
import { BasketLineRequest, BasketVariant, NewBasketProductMasterResponse } from 'generated/data-contracts';
import { FreeAssortmentQuantityInput } from '../FreeAssortmentQuantityInput';
import { useBasketRowContext } from '../context/BasketRowContext';
import styles from './SizesTable.module.scss';

type SizesTableProps = {
	masterInfo: NewBasketProductMasterResponse;
	variantsData: Record<string, BasketVariant>;
	partialBasketLineRequest: PartialBasketLineRequest;
	shipTo: string;
	closePopup: () => void;
};

export const SizesTable: FC<SizesTableProps> = ({
	masterInfo,
	variantsData,
	partialBasketLineRequest,
	shipTo,
	closePopup,
}) => {
	const { shouldUseAvailability } = useBasketRowContext();
	const key = React.useRef(uniqueId('variant-sizes-table-')).current;
	const { data: translations } = useTranslationQuery();

	const hideLengthColumn = isEmpty(masterInfo.variantsGroupedByLength[0]?.length);
	const is2d = !isEmpty(masterInfo.variantsGroupedByLength[0].length);

	return (
		<section
			className={classNames(styles.table, { [styles.hideLengthCell]: hideLengthColumn })}
			style={
				{
					'--js-number-of-columns': masterInfo.sizes.length + (is2d ? 1 : 0),
					'--js-number-of-rows': masterInfo.variantsGroupedByLength.length + 1,
				} as CSSProperties
			}
		>
			<header className={styles.tableHeader}>
				<div className={classNames(styles.headerLengthSizeCell, styles.lengthCell)}>
					<span className={styles.sizeLabel}>{translations?.basket.size}</span>
					<span className={styles.lengthLabel}>{translations?.productDetails.length}</span>
				</div>
				{masterInfo.sizes.map((size, index) => (
					<div key={`${key}-${size}-${index}`} className={styles.headerSizeCell}>
						{size}
					</div>
				))}
			</header>
			<div className={styles.body}>
				{masterInfo.variantsGroupedByLength.map((masterInfoLengths, rowIndex) => (
					<div
						role="row"
						className={styles.row}
						key={`${masterInfo.colour.name}-${masterInfoLengths.length}-${rowIndex}`}
					>
						<div role="cell" className={styles.lengthCell}>
							{masterInfoLengths.length}
						</div>
						{masterInfo.sizes.map((size, columnIndex) => {
							const variantIds = masterInfoLengths.variantsBySize[size];

							if (!variantIds) {
								return (
									<GridCell
										key={`${masterInfo.colour.name}-${masterInfoLengths.length}-${size}`}
										shipTo={shipTo}
										rowIndex={rowIndex}
										columnIndex={columnIndex}
										isDisabledOverride
									/>
								);
							}

							return variantIds.map((ean) => {
								const variantData = variantsData[ean];

								const newBasketLine = {
									...partialBasketLineRequest,
									variantId: ean,
									quantity: Number(variantData?.shipToQuantity[shipTo]) || 0,
								} as BasketLineRequest;

								return (
									<GridCell
										className={styles.sizeCell}
										key={`${masterInfo.colour.name}-${masterInfoLengths.length}-${size}`}
										shipTo={shipTo}
										rowIndex={rowIndex}
										columnIndex={columnIndex}
									>
										<FreeAssortmentQuantityInput
											isDisabled={variantData?.allowedShipTos[shipTo] !== true}
											quantity={variantData?.shipToQuantity[shipTo] ?? 0}
											queryInfo={newBasketLine}
											shouldUseAvailability={shouldUseAvailability}
											closePopup={closePopup}
											inboundQuantity={variantData?.incomingOrders[shipTo]}
										/>
									</GridCell>
								);
							});
						})}
					</div>
				))}
			</div>
		</section>
	);
};

export const MemoSizesTable = memo(SizesTable);
