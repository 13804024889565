import React, { FC } from 'react';
import { Icon } from 'components/shared/Icon';
import { SplashType } from 'generated/data-contracts';
import { useProductCardContext } from '../../context/ProductCardContext';
import styles from './FashionForward.module.scss';

export const FashionForward: FC = () => {
	const { product } = useProductCardContext();
	const splash = product.splashes[SplashType.FashionForward];
	if (!splash) {
		return null;
	}

	return <Icon className={styles.icon} name={'fastForward'} size="sm" />;
};
