import React from 'react';
import classNames from 'classnames';
import { DrawerFooter } from 'components/shared/Drawer';
import styles from './OrderLineDrawerFooter.module.scss';

export interface OrderLineDrawerFooterProps {
	TopActions?: React.ReactNode;
	BottomActions?: React.ReactNode;
	className?: string;
	topActionsClassName?: string;
	bottomActionsClassName?: string;
}

export const OrderLineDrawerFooter: React.FunctionComponent<OrderLineDrawerFooterProps> = ({
	TopActions,
	BottomActions,
	className,
	topActionsClassName,
	bottomActionsClassName,
}) => {
	return (
		<DrawerFooter className={classNames(styles.footer, className)}>
			<div className={classNames(styles.dropdowns, topActionsClassName)}>{TopActions}</div>
			<div className={classNames(styles.bottomActions, bottomActionsClassName)}>{BottomActions}</div>
		</DrawerFooter>
	);
};
