import React from 'react';
import classNames from 'classnames';
import { ProductCardInfo } from '../ProductCardInfo';
import { ProductCardThumbnails } from '../ProductCardThumbnails';
import styles from './ProductCardDetails.module.scss';

export interface ProductCardDetailsProps {
	className?: string;
	Thumbnails?: React.ReactNode;
	Info?: React.ReactNode;
}

type ProductCardDetailsSubComponents = {
	Thumbnails: typeof ProductCardThumbnails;
	Info: typeof ProductCardInfo;
};

export const ProductCardDetails: React.FunctionComponent<ProductCardDetailsProps> & ProductCardDetailsSubComponents = ({
	className,
	Thumbnails,
	Info,
}: ProductCardDetailsProps) => {
	return (
		<div className={classNames(styles.details, className)}>
			{Thumbnails}
			{Info}
		</div>
	);
};

ProductCardDetails.Thumbnails = ProductCardThumbnails;
ProductCardDetails.Info = ProductCardInfo;
