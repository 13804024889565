/**
 * Parses a given string input and converts it to a number.
 *
 * This function removes any non-numeric characters (except for the decimal point and minus sign)
 * from the input string and then attempts to convert the sanitized string to a number.
 * If the conversion results in NaN, the function returns 0.
 *
 * @param {string} input - The string input to be parsed.
 * @returns {number} - The parsed number, or 0 if the input cannot be converted to a valid number.
 */
export function parseToNumber(input: string): number {
	const sanitizedInput = input.replace(/[^0-9.-]/g, '');
	const parsedNumber = Number(sanitizedInput);
	return isNaN(parsedNumber) ? 0 : parsedNumber;
}
