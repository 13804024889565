import React from 'react';
import { useMiniBasketQuantityQuery } from 'api/basket';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/shared';
import { useHideBasketTotals } from 'components/shared/hooks/useHideBasketTotals';
import styles from './MiniBasket.module.scss';
import { MiniBasketIcon } from './MiniBasketIcon';

/**
 * MiniBasket component, used for linking to basket and showing basket count.
 */
export const MiniBasket: React.FunctionComponent = () => {
	const { data: miniBasketObject } = useMiniBasketQuantityQuery();
	const { hideBasketTotals } = useHideBasketTotals();

	let basketQuantity: string | number = miniBasketObject?.totalQuantity ?? 0;

	if (basketQuantity > 9999) {
		basketQuantity = '9999+';
	}

	const basketShortenedPrice: string = miniBasketObject?.shortenedTotalPrice ?? '';
	const basketFullPrice: string = miniBasketObject?.totalPrice ?? '0';

	const isTooltipValueSame: boolean = basketFullPrice === basketShortenedPrice;

	let isBasketValueZero: boolean = false;
	for (let i = 0; i < basketShortenedPrice.length; i++) {
		const char = basketShortenedPrice[i];
		if (!isNaN(Number(char)) && char !== ' ') {
			isBasketValueZero = char === '0';
			break;
		}
	}

	const shouldShowTooltip: boolean = hideBasketTotals || !(isBasketValueZero || isTooltipValueSame);

	return (
		<Tooltip placement="bottom">
			<TooltipTrigger>
				<div className={styles.tooltipTrigger}>
					<MiniBasketIcon />
					{!hideBasketTotals && <p>{basketShortenedPrice}</p>}
				</div>
			</TooltipTrigger>
			{shouldShowTooltip && <TooltipContent className={styles.tooltipText}>{basketFullPrice}</TooltipContent>}
		</Tooltip>
	);
};
