import React from 'react';
import { cloneDeep } from 'lodash';
import { FilterState, RangeFilterValueResponse } from '../types/FilterState';
import { RangeFilterType, FilterTypesEnum } from '../types/FilterTypes';

type UseRangeFilterStateProps = {
	uncommittedFilters: FilterState;
	setUncommittedFilters: React.Dispatch<React.SetStateAction<FilterState>>;
	selectedFilters: FilterState;
	setSelectedFilters: React.Dispatch<React.SetStateAction<FilterState>>;
	filters: RangeFilterType[];
};

export type UseRangeFilterStateReturnType = {
	onChangeRangeItem: (filterId: string, item: RangeFilterValueResponse) => void;
	onRemoveRangeItem: (filterId: string) => void;
};

export const useRangeFilterState = ({
	setUncommittedFilters,
	setSelectedFilters,
}: UseRangeFilterStateProps): UseRangeFilterStateReturnType => {
	const onChangeRangeItem = (filterId: string, item: RangeFilterValueResponse) => {
		return setUncommittedFilters((prev) => {
			prev[FilterTypesEnum.Range].set(filterId, item);
			return cloneDeep(prev);
		});
	};

	const onRemoveRangeItem = (filterId: string) => {
		return setSelectedFilters((prev) => {
			prev[FilterTypesEnum.Range].delete(filterId);
			return cloneDeep(prev);
		});
	};

	return {
		onChangeRangeItem,
		onRemoveRangeItem,
	};
};
