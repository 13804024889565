import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { Translations } from 'generated/Translations';
import { TranslationsWebsite } from 'generated/data-contracts';
import { useAppStore } from 'store/appStore';

export const useTranslationQuery = (): UseQueryResult<TranslationsWebsite> => {
	const segmentationId = useAppStore((state) => state.segmentationId);

	return useQuery({
		queryKey: queryKeys.translations.fetch(segmentationId).queryKey,
		queryFn: async (): Promise<TranslationsWebsite> => {
			const translationsApi = new Translations({ baseApiParams: { headers: setHeaders() || undefined } });
			const response = await translationsApi.translationsList({ segmentationId });

			return response.data;
		},
	});
};
