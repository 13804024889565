import React from 'react';
import classNames from 'classnames';
import styles from './ProductCard.module.scss';
import { ProductCardDetails } from './ProductCardDetails';
import { ProductCardImage } from './ProductCardImage';
import { useProductCardContext } from './context/ProductCardContext';

export interface ProductCardProps {
	className?: string;
	Image?: React.ReactNode;
	Details?: React.ReactNode;
	Footer?: React.ReactNode;
}

type ProductCardSubComponents = {
	Image: typeof ProductCardImage;
	Details: typeof ProductCardDetails;
};

export const ProductCard: React.FunctionComponent<ProductCardProps> & ProductCardSubComponents = ({
	Details,
	className,
	Footer,
	Image,
}: ProductCardProps) => {
	const {
		product: { styleName },
		setIsBeingHovered,
	} = useProductCardContext();
	return (
		<article
			className={classNames(styles.wrapper, className)}
			aria-label={styleName}
			onMouseEnter={(): void => setIsBeingHovered(true)}
			onMouseLeave={(): void => setIsBeingHovered(false)}
		>
			{Image}
			{Details}
			{Footer && <div className={styles.footer}>{Footer}</div>}
		</article>
	);
};

ProductCard.Image = ProductCardImage;
ProductCard.Details = ProductCardDetails;
