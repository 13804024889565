import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import styles from './OrderLineSizeSplit.module.scss';
import { SizeSplitHeader } from './SizeSplitHeader';
import SizeSplitRowGroup from './SizeSplitRowGroup';
import { ColumnKeys, SizeSplitContextProvider, useSizeSplitContext } from './context/SizeSplitContext';

export interface OrderLineSizeSplitProps {
	className?: string;
}

const OrderLineSizeSplitInner: React.FunctionComponent<OrderLineSizeSplitProps> = ({ className }) => {
	const { masters, columns, is2DimensionalTable, rowGroups } = useSizeSplitContext();
	const { productInfo } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	const id = React.useRef(uniqueId('size-split-')).current;

	if (!masters?.length) return null;

	return (
		<div className={classNames(styles.container, className)} title={translations?.myOrders.orderDetails.sizeSplit}>
			{productInfo?.bundleName && (
				<p className={styles.bundleName}>
					{translations?.shared.box}:&nbsp;
					{productInfo?.bundleName}
				</p>
			)}
			<section
				role="table"
				className={styles.sizeSplitTable}
				style={
					{
						'--js-number-of-columns': columns.length,
						'--js-number-of-bodies': masters.length,
					} as CSSProperties
				}
			>
				<SizeSplitHeader
					id={id}
					columns={columns}
					isColumnHeader={(column): boolean => is2DimensionalTable && column.key !== ColumnKeys.COLOR}
					isRowHeader={(column): boolean => !is2DimensionalTable && column.key === ColumnKeys.SIZE}
				/>
				{rowGroups.map((rowGroup, index) => {
					return (
						<SizeSplitRowGroup key={`master-table-${rowGroup.master.id}-${index}`} rowGroup={rowGroup} />
					);
				})}
			</section>
		</div>
	);
};

export const OrderLineSizeSplit: React.FunctionComponent<OrderLineSizeSplitProps> = ({ className }) => {
	const { productInfo } = useOrderLineContext();
	return (
		<SizeSplitContextProvider masters={productInfo?.masters}>
			<OrderLineSizeSplitInner className={className} />
		</SizeSplitContextProvider>
	);
};
