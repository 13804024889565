import React from 'react';
import { cloneDeep } from 'lodash';
import { FilterValueResponse } from 'generated/data-contracts';
import { FilterState, SelectedFilterValues } from '../types/FilterState';
import { CheckboxFilterType, FilterTypesEnum, SearchableCheckboxFilterType } from '../types/FilterTypes';

type UseCheckboxFilterStateProps = {
	uncommittedFilters: FilterState;
	setUncommittedFilters: React.Dispatch<React.SetStateAction<FilterState>>;
	selectedFilters: FilterState;
	setSelectedFilters: React.Dispatch<React.SetStateAction<FilterState>>;
	filters: (CheckboxFilterType | SearchableCheckboxFilterType)[];
};

export type UseCheckboxFilterStateReturnType = {
	isCheckboxSelected: (filterId: string, item: FilterValueResponse) => boolean;
	onChangeCheckboxItem: (filterId: string, item: FilterValueResponse) => void;
	onInitializeCheckboxFilter: (filterId: string, items: FilterValueResponse[]) => void;
	onRemoveCheckboxItem: (filterId: string, itemId?: string) => void;
	onSelectAllCheckboxes: (filterId: string, shouldBeSelected?: boolean) => void;
	areAllCheckboxesSelected: (filterId: string) => {
		areSomeSelected: boolean;
		areAllSelected: boolean;
	};
};

export const useCheckboxFilterState = ({
	uncommittedFilters,
	setUncommittedFilters,
	setSelectedFilters,
	filters,
}: UseCheckboxFilterStateProps): UseCheckboxFilterStateReturnType => {
	const isCheckboxSelected = (filterId: string, item: FilterValueResponse): boolean => {
		const filter = uncommittedFilters[FilterTypesEnum.Checkbox].get(filterId);
		return filter?.get(item.value) !== undefined;
	};
	const areAllCheckboxesSelected = React.useCallback(
		(
			filterId: string,
		): {
			areSomeSelected: boolean;
			areAllSelected: boolean;
		} => {
			const filter = uncommittedFilters[FilterTypesEnum.Checkbox].get(filterId);
			if (!filter)
				return {
					areAllSelected: false,
					areSomeSelected: false,
				};

			const allFilterOptions = filters.find((r) => r.id === filterId)?.values ?? [];
			const areSomeSelected = allFilterOptions.some((r) => filter.get(r.value) !== undefined);
			const areSomeFiltersNotSelected = allFilterOptions.some((r) => !filter?.get(r.value));
			return {
				areSomeSelected,
				areAllSelected: !areSomeFiltersNotSelected,
			};
		},
		[filters, uncommittedFilters],
	);

	const onSelectAllCheckboxes = (filterId: string) => {
		const { areSomeSelected } = areAllCheckboxesSelected(filterId);
		setUncommittedFilters((prev) => {
			if (areSomeSelected) {
				prev[FilterTypesEnum.Checkbox].get(filterId)?.forEach((r) => {
					prev[FilterTypesEnum.Checkbox].get(filterId)?.delete(r.value);
				});
				return cloneDeep(prev);
			}
			const currentFilter: SelectedFilterValues =
				prev[FilterTypesEnum.Checkbox].get(filterId) ?? new Map<string, FilterValueResponse>();
			const allFilterOptions = filters.find((r) => r.id === filterId)?.values ?? [];
			allFilterOptions.forEach((option) => {
				currentFilter.set(option.value, option);
			});
			prev[FilterTypesEnum.Checkbox].set(filterId, cloneDeep(currentFilter));
			return cloneDeep(prev);
		});
	};

	const onChangeCheckboxItem = (filterId: string, item: FilterValueResponse) => {
		return setUncommittedFilters((prev): FilterState => {
			const id = item.value;
			const allCheckBoxFilters = prev[FilterTypesEnum.Checkbox];
			let currentFilter = allCheckBoxFilters.get(filterId);
			if (!(currentFilter instanceof Map)) {
				currentFilter = new Map<string, FilterValueResponse>();
			}
			const itemToSelect = currentFilter.get(id);
			if (!itemToSelect) {
				currentFilter.set(id, item);
			} else {
				currentFilter.delete(id);
			}
			allCheckBoxFilters.set(filterId, currentFilter);
			prev[FilterTypesEnum.Checkbox] = allCheckBoxFilters;
			return cloneDeep(prev);
		});
	};

	const onInitializeCheckboxFilter = (filterId: string, items: FilterValueResponse[]) => {
		return setSelectedFilters((prev): FilterState => {
			const allCheckBoxFilters = prev[FilterTypesEnum.Checkbox];
			const currentFilter = new Map<string, FilterValueResponse>();

			items.forEach((item) => {
				const id = item.value;
				currentFilter.set(id, item);
			});
			allCheckBoxFilters.set(filterId, currentFilter);
			prev[FilterTypesEnum.Checkbox] = allCheckBoxFilters;
			return cloneDeep(prev);
		});
	};

	const onRemoveCheckboxItem = (filterId: string, itemId?: string) => {
		return setSelectedFilters((prev): FilterState => {
			const allCheckBoxFilters = prev[FilterTypesEnum.Checkbox];
			let currentFilter = allCheckBoxFilters.get(filterId);
			if (!(currentFilter instanceof Map)) {
				currentFilter = new Map<string, FilterValueResponse>();
			}
			if (itemId) {
				currentFilter.delete(itemId);
				allCheckBoxFilters.set(filterId, currentFilter);
			} else {
				allCheckBoxFilters.delete(filterId);
			}
			prev[FilterTypesEnum.Checkbox] = allCheckBoxFilters;
			return cloneDeep(prev);
		});
	};

	return {
		isCheckboxSelected,
		onChangeCheckboxItem,
		onInitializeCheckboxFilter,
		onRemoveCheckboxItem,
		onSelectAllCheckboxes,
		areAllCheckboxesSelected,
	};
};
