import { FilterState } from 'components/shared/FilterList/V2/types/FilterState';
import { FilterTypesEnum } from 'components/shared/FilterList/V2/types/FilterTypes';
import { FilterRequest } from 'generated/data-contracts';

export const convertFiltersToFilterRequest = (
	filters: FilterState,
	{
		excluded,
	}: {
		excluded?: string[];
	} = {},
): FilterRequest[] =>
	[...filters[FilterTypesEnum.Checkbox].entries()].flatMap(([filterId, values]): FilterRequest[] => {
		if (excluded?.includes(filterId)) return [];
		return [...values.keys()].map((value) => ({
			filter: filterId,
			value,
		}));
	});
