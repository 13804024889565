import { UseSearchReturnType } from 'helpers/useSearch';

export enum FilterTypesEnum {
	Checkbox,
	Range,
	Date,
	Single,
}
export type Filter = {
	label: string;
	id: string;
};

export interface RangeFilterType extends Filter {
	type: FilterTypesEnum.Range;
	min?: number;
	max?: number;
	selectedMin?: number;
	selectedMax?: number;

	startAdornment?: string;
	endAdornment?: string;
}

export interface CheckboxFilterValue {
	label: string;
	value: string;
	hitCount?: number;
	isSelected: boolean;
}

export interface CheckboxFilterType extends Filter {
	type: FilterTypesEnum.Checkbox;
	values: CheckboxFilterValue[];
}

export interface SingleFilterType extends Filter {
	type: FilterTypesEnum.Single;
	values: CheckboxFilterValue[];
}

export interface SearchableCheckboxFilterType extends CheckboxFilterType {
	type: FilterTypesEnum.Checkbox;
	search: UseSearchReturnType;
	hasNextPage: boolean;
	loadMore: () => void;
}
