import React from 'react';
import classNames from 'classnames';
import { AllOrNone } from 'types/allOrNone';
import { useTranslationQuery } from 'api/translations';
import styles from './Toggle.module.scss';

type ToggleVariants = 'secondary' | 'primary' | 'contrast';

type ControlledToggleProps = AllOrNone<{
	value: boolean;
	onChange: (value: boolean) => void;
}>;

export type ToggleProps = {
	disabled?: boolean;
	variant?: ToggleVariants;
	withStatus?: boolean;
} & ControlledToggleProps &
	Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'type' | 'disabled'>;

const Toggle: React.FunctionComponent<ToggleProps> = ({
	disabled = false,
	variant = 'primary',
	onChange,
	value,
	withStatus,
	defaultChecked,
	...props
}) => {
	const { data: translations } = useTranslationQuery();

	const [checked, setChecked] = React.useState<boolean>(value ?? defaultChecked ?? false);

	const onToggle = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (disabled) return;
		setChecked(e.target.checked);
	};

	React.useEffect(() => {
		if (onChange && checked !== value) {
			onChange(checked);
		}
	}, [checked, onChange, value]);

	return (
		<div className={styles.wrapper}>
			{withStatus && <span className={classNames({ [styles.status]: checked })}>{translations?.shared.off}</span>}

			<input
				disabled={disabled}
				className={classNames(styles.toggle, styles[variant])}
				type="checkbox"
				checked={checked}
				data-checked={checked}
				onChange={onToggle}
				{...props}
			/>

			{withStatus && <span className={classNames({ [styles.status]: !checked })}>{translations?.shared.on}</span>}
		</div>
	);
};

export default Toggle;
