import React from 'react';
import { SplashType } from 'generated/data-contracts';
import { useProductCardContext } from '../../context/ProductCardContext';
import { Splash } from '../Splash';

export interface NoosProps {
	className?: string;
}

export const Noos: React.FunctionComponent<NoosProps> = ({ className }) => {
	const { product } = useProductCardContext();
	const splash = product.splashes[SplashType.NeverOutOfStock];
	if (!splash) return;
	return <Splash className={className} splash={splash} />;
};
