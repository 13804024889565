import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { formatTranslation } from 'helpers';
import { useShiptoSelectedListQuery } from 'api/shipTo';
import { useTranslationQuery } from 'api/translations';
import EditView from 'components/fragments/BasketSelector/EditView';
import { Button, Icon } from 'components/shared';
import { Drawer, DrawerHeader, DrawerHeaderWBackButton, DrawerLevel } from 'components/shared/Drawer';
import styles from './OpenAccounts.module.scss';

interface OpenAccountsPros {
	handleClose?: () => void;
}
export const OpenAccounts: FC<OpenAccountsPros> = ({ handleClose }) => {
	const { data: translations } = useTranslationQuery();

	const [isOpen, setIsOpen] = useState(false);

	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

	// get selected shiptos
	const { data: sortedSelectedShipTos } = useShiptoSelectedListQuery();

	const handleOpen = (): void => setIsOpen(true);
	const handleBack = (): void => setIsOpen(false);

	const onToggleClick = (): void => {
		setIsExpanded(!isExpanded);
	};

	if (!sortedSelectedShipTos?.shipTos) return null;

	return (
		<>
			{sortedSelectedShipTos.shipTos.length > 0 && (
				<div className={styles.accountDetails}>
					<div className={styles.heading}>
						<div>
							{formatTranslation(translations?.accountSelector.accountsInBasket, {
								0: sortedSelectedShipTos.shipTos.length,
							})}{' '}
						</div>
						<div className={styles.hidden}>
							<Button
								size={'sm'}
								className={styles.button}
								title={translations?.openAccounts.manageBtn}
								variant={'ternary'}
								onClick={handleOpen}
							>
								<Icon name="edit" size="sm" />
								{translations?.openAccounts.manageBtn}
							</Button>
						</div>
					</div>

					<ul className={classNames(styles.list, { [styles.isExpanded]: isExpanded })}>
						{sortedSelectedShipTos.shipTos.map((item, index) => (
							<li key={`${item.id}-${index}`} className={styles.shipToItem}>
								<Icon className={styles.icon} name={'account'} size="md" />
								<div>
									<span className={styles.name}>{item.name}</span>

									<div className={styles.shipToInfo}>
										<span>
											{item.streetAndNumber} {item.postalCode} {item.city}
										</span>
										<span>
											{translations?.basket.accountId}: {item.id}
										</span>
									</div>
								</div>
							</li>
						))}
					</ul>

					{sortedSelectedShipTos.shipTos.length > 3 && (
						<Button variant={'ghost'} className={styles.toggleBtn} hasNoStyles onClick={onToggleClick}>
							{translations?.accountSelector.viewAccounts}
							<Icon name={'chevronDown'} rotate={isExpanded ? 180 : undefined} size="lg" />
						</Button>
					)}
				</div>
			)}

			{isOpen &&
				(handleClose ? (
					<DrawerLevel isActive={isOpen}>
						<DrawerHeaderWBackButton
							handleBack={handleBack}
							handleClose={handleClose}
							title={translations?.openAccounts.title}
						/>

						<EditView handleClose={handleClose} />
					</DrawerLevel>
				) : (
					<Drawer onClose={handleBack}>
						<DrawerHeader handleClose={handleBack} title={translations?.openAccounts.title} />
						<EditView handleClose={handleBack} />
					</Drawer>
				))}
		</>
	);
};
