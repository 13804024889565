import React from 'react';
import { ProductItemResponse } from 'generated/data-contracts';
import { AddFavouriteIcon } from '../Actions/AddFavouriteIcon';
import { ProductCard } from '../ProductCard';
import { Splashes } from '../Splashes';
import { ProductCardContextProvider } from '../context/Providers/ProductItemResponseProvider';

export interface PlpProductCardProps {
	product?: ProductItemResponse;
	className?: string;
	updateNavParams?: () => void;
}

export const PlpProductCard: React.FunctionComponent<PlpProductCardProps> = ({
	product,
	className,
	updateNavParams,
}) => {
	return (
		<ProductCardContextProvider product={product} updateNavParams={updateNavParams}>
			<ProductCard
				className={className}
				Image={
					<ProductCard.Image.Wrapper
						BottomLeftSplashes={
							<>
								<Splashes.FashionForward />
								<Splashes.Cmi />
							</>
						}
						BottomRightSplashes={
							<>
								<Splashes.InBasket />
								<Splashes.Inbound />
								<Splashes.Delivered />
							</>
						}
						TopRightSplashes={
							<>
								<AddFavouriteIcon />
							</>
						}
						TopLeftSplashes={
							<>
								<Splashes.DeliveryMonth />
								<Splashes.Discount />
								<Splashes.Noos />
							</>
						}
						Image={<ProductCard.Image />}
					/>
				}
				Details={
					<ProductCard.Details
						Thumbnails={<ProductCard.Details.Thumbnails />}
						Info={
							<ProductCard.Details.Info
								Title={<ProductCard.Details.Info.Title />}
								PriceInfo={<ProductCard.Details.Info.PriceInfo />}
							/>
						}
					/>
				}
			/>
		</ProductCardContextProvider>
	);
};
