import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Layout } from 'generated/Layout';
import { LayoutResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { useAppStore } from 'store/appStore';
import { useTranslationQuery } from './translations';

export const useLayoutQuery = (): UseQueryResult<LayoutResponse> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();
	const { setDevice } = useAppStore((state) => state);

	return useQuery({
		queryKey: queryKeys.layoutList.fetch(segmentationId).queryKey,
		queryFn: async (): Promise<LayoutResponse> => {
			const layoutApi = new Layout({ baseApiParams: { headers: setHeaders() || undefined } });
			const response = await layoutApi.layoutList({ segmentationId });

			if (!response.ok) {
				notificationActions.addNotification({
					type: AlertTypes.DANGER,
					children: formatTranslation(translations?.shared.layoutErrorMsg, {}),
				});

				setDevice(response.data.audienceTargeting.device);
			}

			return response.data;
		},
		placeholderData: keepPreviousData,
	});
};
