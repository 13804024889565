import React, { FC } from 'react';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Icon, Link } from 'components/shared';
import { FeatureFlag, PageType } from 'generated/data-contracts';
import styles from './ManageUsers.module.scss';

export const ManageUsers: FC = () => {
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();

	const ff = layout?.userFeatureFlags.includes(FeatureFlag.CustomerManagement);

	if (!ff) {
		return null;
	}

	const link = layout?.staticLinks.find((l) => l.pageType === PageType.CustomerManagement);

	if (!link) {
		return null;
	}

	return (
		<Link className={styles.title} linkUrl={link.url} hasExternalIconHidden>
			<Icon className={'u-mr-sm'} name={'users'} size={'md'} />
			{translations?.myProfile.manageUsers}
		</Link>
	);
};
