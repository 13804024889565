import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { clamp } from 'lodash';
import { useFilterContext } from '../../../context/FilterContext';
import { RangeFilterType } from '../../../types/FilterTypes';
import styles from './RangeFilterContent.module.scss';

export interface RangeFilterContentProps {
	filter: RangeFilterType;
	startAdornment?: string;
	endAdornment?: string;
}

export const RangeFilterContent: React.FunctionComponent<RangeFilterContentProps> = ({ filter }) => {
	const { rangeFilterActions } = useFilterContext();
	const min = filter.min ?? 0;
	const max = filter.max ?? 999;

	const [firstValue, setFirstValue] = React.useState<number>(filter.selectedMin ?? min ?? 0);
	const [secondValue, setSecondValue] = React.useState<number>(filter.selectedMax ?? max ?? 0);
	const minValue = Math.min(firstValue, secondValue);
	const maxValue = Math.max(secondValue, firstValue);
	const valueIsTheSame = maxValue === minValue;
	const onChangeFirstVal = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = +e.target.value;
			setFirstValue(clamp(newValue, min, max));
		},
		[max, min],
	);
	const onChangeSecondVal = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = +e.target.value;
			setSecondValue(clamp(newValue, min, max));
		},
		[max, min],
	);
	const percent1 = (minValue / max) * 100;
	const percent2 = (maxValue / max) * 100;
	const onBlur = () => {
		setFirstValue(minValue);
		setSecondValue(maxValue);
	};

	React.useEffect(() => {
		rangeFilterActions.onChangeRangeItem(filter.id, {
			max: maxValue,
			min: minValue,
		});
	}, [filter.id, maxValue, minValue, rangeFilterActions]);
	return (
		<form className={styles.body}>
			<div className={styles.inputs}>
				<div className={styles.inputWrapper}>
					<span>{filter.startAdornment}</span>
					<input
						type="number"
						className={styles.input}
						value={minValue}
						min={min}
						max={Math.min(maxValue, max)}
						onChange={onChangeFirstVal}
					/>
					<span>{filter.endAdornment}</span>
				</div>

				<div className={styles.inputWrapper}>
					<span>{filter.startAdornment}</span>
					<input
						type="number"
						className={styles.input}
						value={maxValue}
						min={Math.max(minValue, min)}
						max={max}
						onChange={onChangeSecondVal}
					/>
					<span>{filter.endAdornment}</span>
				</div>
			</div>
			<div
				className={styles.rangeSlider}
				style={
					{
						'--js-pos-left': `${percent1}%`,
						'--js-pos-width': `${percent2 - percent1}%`,
					} as CSSProperties
				}
			>
				<input
					type="range"
					className={classNames(styles.rangeInput)}
					value={firstValue}
					data-lowest={!valueIsTheSame && firstValue === minValue}
					onMouseUp={onBlur}
					onChange={onChangeFirstVal}
					min={min}
					max={max}
				/>
				<input
					type="range"
					className={classNames(styles.rangeInput)}
					value={secondValue}
					data-lowest={!valueIsTheSame && secondValue === minValue}
					onMouseUp={onBlur}
					onChange={onChangeSecondVal}
					min={min}
					max={max}
				/>
			</div>
		</form>
	);
};
