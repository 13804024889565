import React, { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { AllOrNone } from 'types/allOrNone';
import { Icon } from 'components/shared/Icon';
import styles from './Accordion.module.scss';

interface AccordionProps {
	/** The summary for the accordion. This can be either just text, or the entire component */
	Summary: string | ReactNode;
	/** Class name for the `<summary>` tag */
	summaryClassName?: string;
	/** Class name for the `<details>` tag */
	className?: string;
	/** Location for the `chevron` icon. This can be either at the start, end, or be omitted */
	icon?: 'start' | 'end';
	/** If true, the accordion is expanded by default. This does not change i the accordion is controlled or not */
	openByDefault?: boolean;
	/** If true, the content of the accordion is rendered. This is for example to prefetch the content when hovering.
	 * Content will always be rendered if the accordion is expanded
	 */
	renderContent?: boolean;
}

type ControlledOptions = {
	/** The controlled value. If not undefined, `onToggle` must also be defined */
	expanded: boolean;
	/** Method to control the value. If not undefined, `expanded` must also be defined */
	onToggle: (isOpen: boolean) => void;
};

/**
 * Generic Accordion component, used for displaying content in an accordion / collapse.
 */
/**
 * AccordionV2 component.
 *
 * This component renders an accordion element with a summary and collapsible content.
 * It supports both controlled and uncontrolled modes.
 *
 * @param {AccordionProps & AllOrNone<ControlledOptions>} props - The props for the AccordionV2 component.
 * @param {string} props.summary - The summary text for the accordion.
 * @param {string} [props.className] - Additional class names for the accordion.
 * @param {string} [props.summaryClassName] - Additional class names for the summary element.
 * @param {React.ReactNode} props.children - The content to be displayed inside the accordion.
 * @param {'start' | 'end'} [props.icon] - The position of the icon, either 'start' or 'end'.
 * @param {function} [props.onToggle] - Callback function to be called when the accordion is toggled.
 * @param {boolean} [props.expanded] - If true, the accordion is expanded (controlled mode).
 *
 * @returns {JSX.Element} The rendered AccordionV2 component.
 */
export const AccordionV2: FC<PropsWithChildren<AccordionProps & AllOrNone<ControlledOptions>>> = ({
	Summary,
	className,
	summaryClassName,
	children,
	icon,
	onToggle,
	expanded,
	openByDefault,
	renderContent,
}) => {
	const detailsRef = React.useRef<HTMLDetailsElement>(null);
	const isControlled = expanded !== undefined;
	const [isOpen, setIsOpen] = React.useState(openByDefault);

	const hasBeenInitialized = React.useRef(false);
	const handleToggle = () => {
		if (isControlled) {
			return;
		}
		// NOTE: This is done because the `onToggle` method is called when the components `open` state is initialized
		if (openByDefault && !hasBeenInitialized.current) {
			hasBeenInitialized.current = true;
			return;
		}
		setIsOpen((prev) => {
			return !prev;
		});
	};

	React.useEffect(() => {
		if (!isControlled) return;
		const details = detailsRef.current;
		if (!details) return;
		const detailsEventListener = (event: Event) => {
			const isToggleEvent = event instanceof ToggleEvent;
			const isTargetAnElement = event.target instanceof Element;
			if (!isToggleEvent || !isTargetAnElement) return;
			onToggle((event.target as HTMLDetailsElement).open);
			(event.target as HTMLDetailsElement).blur();
		};
		details.addEventListener('toggle', detailsEventListener);
		return () => {
			details.removeEventListener('toggle', detailsEventListener);
		};
	}, [isControlled, onToggle]);

	const openState = isControlled ? expanded : isOpen;
	const ArrowIcon = (
		<Icon name="chevronDown" size="md" svgClassName={styles.arrowIcon} rotate={openState ? 180 : undefined} />
	);
	return (
		<details
			ref={detailsRef}
			className={classNames(className, styles.accordion)}
			open={openState}
			onToggle={handleToggle}
		>
			<summary className={classNames(summaryClassName, styles.summary)}>
				{icon === 'start' && ArrowIcon}
				{Summary}
				{icon === 'end' && ArrowIcon}
			</summary>
			{(openState || renderContent) && children}
		</details>
	);
};
