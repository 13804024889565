import React from 'react';
import { FilterTypesEnum, RangeFilterType } from '../../types/FilterTypes';
import { FilterDropdown } from '../shared/FilterDropdown';
import { RangeFilterContent } from './RangeFilterContent';

export interface RangeFilterProps {
	filter: RangeFilterType;
}

export const RangeFilter: React.FunctionComponent<RangeFilterProps> = ({ filter }) => {
	return (
		<FilterDropdown filter={filter} filterType={FilterTypesEnum.Range}>
			<RangeFilterContent filter={filter} />
		</FilterDropdown>
	);
};
