import {
	UseMutationResult,
	UseSuspenseQueryResult,
	useMutation,
	useQueryClient,
	useSuspenseQuery,
} from '@tanstack/react-query';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Basket as BasketApi } from 'generated/Basket';
import {
	BasketDetailsResponse,
	BasketFilterType,
	BasketLineRequest,
	BasketResponse,
	ErrorResult,
	ExtraModelsResponse,
	RemoveLinesForBundleRequest,
	RemoveLinesForFamilyRequest,
	RemoveLinesForMasterRequest,
	CopyBasketRequest,
	BasketQuantityRequest,
	BasketLineQuantityResponse,
	BasketGroupingTypeRequest,
} from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { isScannerApp, messageToApp } from 'helpers/app';
import { queryKeys, setHeaders } from './apiConfig';
import { useTranslationQuery } from './translations';

export type BasketRequest = {
	shippingFilter?: BasketFilterType;
	groupingTypeRequest?: BasketGroupingTypeRequest;
	includeShipToGrouping?: boolean;
	/** @format date */
	shippingFilterDate?: string;
};

export const useBasketQuery = (
	request: BasketRequest = {
		groupingTypeRequest: BasketGroupingTypeRequest.Non,
		includeShipToGrouping: false,
		shippingFilter: BasketFilterType.All,
		shippingFilterDate: undefined,
	},
	host?: string,
	cookies?: string,
): UseSuspenseQueryResult<BasketResponse, ExtraModelsResponse | ErrorResult | void> => {
	const query = useSuspenseQuery<BasketResponse, ExtraModelsResponse | ErrorResult | void>({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.newBasket.current(request).queryKey,
		queryFn: async () => {
			const basketApi = new BasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });

			const response = await basketApi.basketIndexv2List(request);

			return response.data;
		},
		refetchOnWindowFocus: isScannerApp ? 'always' : true,

		retry: false,
	});

	return query;
};

export const useBasketLineMutation = (): UseMutationResult<
	HttpResponse<BasketDetailsResponse>,
	HttpResponse<ErrorResult>,
	{ query: BasketLineRequest; host?: string; cookies?: string }
> => {
	const { notificationActions } = useNotificationContext();

	const queryClient = useQueryClient();
	const { data: translations } = useTranslationQuery();

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketSetlinequantityCreate(variables.query);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: queryKeys.newBasket._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.miniQuantity._def });
		},
		onError: (err) => {
			if (err.status === 500) {
				notificationActions.addNotification({
					type: AlertTypes.DANGER,
					children: translations?.basket.variantQuantityUpdateFailed,
				});
			}
		},
	});
};

export const useUpdateBasketLinesMutation = (): UseMutationResult<
	HttpResponse<BasketLineQuantityResponse[]>,
	HttpResponse<ErrorResult>,
	{ query: BasketQuantityRequest; host?: string; cookies?: string }
> => {
	const { notificationActions } = useNotificationContext();

	const queryClient = useQueryClient();
	const { data: translations } = useTranslationQuery();
	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});
			return basketApi.basketSetbasketquantityCreate(variables.query);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: queryKeys.newBasket._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.miniQuantity._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.productCardQuantity._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.productBasket._def });
		},
		onError: (err) => {
			if (err.status === 500) {
				notificationActions.addNotification({
					type: AlertTypes.DANGER,
					children: translations?.basket.variantQuantityUpdateFailed,
				});
			}
		},
	});
};

export const useRemoveBasketFamilyMutation = (): UseMutationResult<
	HttpResponse<BasketDetailsResponse>,
	HttpResponse<ErrorResult>,
	{ query: RemoveLinesForFamilyRequest; host?: string; cookies?: string }
> => {
	const { notificationActions } = useNotificationContext();

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketRemovefamilyCreate(variables.query);
		},
		onSuccess: async ({ data }) => {
			messageToApp({
				type: 'miniBasketQuantity',
				quantity: data.miniBasketQuantity,
			});
			messageToApp({
				type: 'basketChanged',
			});
			await queryClient.invalidateQueries({ queryKey: queryKeys.newBasket.current._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.miniQuantity._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.productCardQuantity._def });
		},
		onError: (err) => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: err.data.detail,
			});
		},
	});
};

export const useRemoveBasketBundleMutation = (): UseMutationResult<
	HttpResponse<BasketDetailsResponse>,
	HttpResponse<ErrorResult>,
	{ query: RemoveLinesForBundleRequest; host?: string; cookies?: string }
> => {
	const { notificationActions } = useNotificationContext();

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketRemovebundleCreate(variables.query);
		},
		onSuccess: async ({ data }) => {
			messageToApp({
				type: 'miniBasketQuantity',
				quantity: data.miniBasketQuantity,
			});
			messageToApp({
				type: 'basketChanged',
			});
			await queryClient.invalidateQueries({ queryKey: queryKeys.newBasket.current._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.miniQuantity._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.productCardQuantity._def });
		},
		onError: (err) => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: err.data.detail,
			});
		},
	});
};

export const useRemoveBasketMasterMutation = (): UseMutationResult<
	HttpResponse<BasketDetailsResponse>,
	HttpResponse<ErrorResult>,
	{ query: RemoveLinesForMasterRequest; host?: string; cookies?: string }
> => {
	const { notificationActions } = useNotificationContext();

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketRemovemasterCreate(variables.query);
		},
		onSuccess: async ({ data }) => {
			messageToApp({
				type: 'miniBasketQuantity',
				quantity: data.miniBasketQuantity,
			});
			messageToApp({
				type: 'basketChanged',
			});
			await queryClient.invalidateQueries({ queryKey: queryKeys.newBasket.current._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.miniQuantity._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.productCardQuantity._def });
		},
		onError: (err) => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: err.data.detail,
			});
		},
	});
};

export const useCopyBasket = (): UseMutationResult<
	HttpResponse<BasketDetailsResponse>,
	HttpResponse<ErrorResult>,
	{ query: CopyBasketRequest; host?: string; cookies?: string }
> => {
	const { notificationActions } = useNotificationContext();

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketCopycurrentbasketCreate(variables.query);
		},
		onSuccess: async ({ data }) => {
			messageToApp({ type: 'miniBasketQuantity', quantity: data.miniBasketQuantity });
			messageToApp({ type: 'basketChanged' });

			await queryClient.invalidateQueries({ queryKey: queryKeys.newBasket.current._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.miniQuantity._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.productCardQuantity._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.shipTo.listselected._def });
			await queryClient.invalidateQueries({ queryKey: queryKeys.basket.list._def });
		},
		onError: (err) => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: err.data.detail,
			});
		},
	});
};
