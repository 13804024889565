/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  OrderHistoryAccountStatusResponse,
  OrderHistoryDetailLineRequest,
  OrderHistoryFamilySearchRequest,
  OrderHistoryGroupingRequest,
  OrderHistoryGroupingResponse,
  OrderHistoryInvoiceSearchRequest,
  OrderHistoryInvoiceSearchResponse,
  OrderHistoryLineSearchRequest,
  OrderHistoryLineSearchResponse,
  OrderHistorySearchResponse,
  OrderNumber,
  OrderOverviewRequest,
  OrderOverviewSearchResponse,
  ProblemDetails,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class OrderHistory<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags OrderHistory
   * @name OrderhistorySearchDetailsCreate
   * @summary Search order history by order history number
   * @request POST:/api/orderhistory/search/details
   * @secure
   */
  orderhistorySearchDetailsCreate = (
    query: {
      orderNumber: OrderNumber;
    },
    params: RequestParams = {},
  ) =>
    this.request<OrderHistorySearchResponse, ProblemDetails | void>({
      path: `/api/orderhistory/search/details`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderHistory
   * @name OrderhistorySearchDetailsLinesCreate
   * @summary Search order history lines by order history number
   * @request POST:/api/orderhistory/search/details/lines
   * @secure
   */
  orderhistorySearchDetailsLinesCreate = (data: OrderHistoryDetailLineRequest, params: RequestParams = {}) =>
    this.request<OrderHistoryLineSearchResponse, ProblemDetails | void>({
      path: `/api/orderhistory/search/details/lines`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderHistory
   * @name OrderhistorySearchCreate
   * @summary Search order history lines
   * @request POST:/api/orderhistory/search
   * @secure
   */
  orderhistorySearchCreate = (data: OrderHistoryLineSearchRequest, params: RequestParams = {}) =>
    this.request<OrderHistoryLineSearchResponse, ProblemDetails | void>({
      path: `/api/orderhistory/search`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderHistory
   * @name OrderhistorySearchinvoicelinesCreate
   * @summary Search order history lines on invoice numbers, used on my invoices
   * @request POST:/api/orderhistory/searchinvoicelines
   * @secure
   */
  orderhistorySearchinvoicelinesCreate = (data: OrderHistoryInvoiceSearchRequest, params: RequestParams = {}) =>
    this.request<OrderHistoryInvoiceSearchResponse, ProblemDetails | void>({
      path: `/api/orderhistory/searchinvoicelines`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderHistory
   * @name OrderhistorySearchgroupsCreate
   * @summary Search order history line groups
   * @request POST:/api/orderhistory/searchgroups
   * @secure
   */
  orderhistorySearchgroupsCreate = (data: OrderHistoryGroupingRequest, params: RequestParams = {}) =>
    this.request<OrderHistoryGroupingResponse, ProblemDetails | void>({
      path: `/api/orderhistory/searchgroups`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderHistory
   * @name OrderhistorySearchaccountstatusCreate
   * @summary Get grouping of requested shipTos in Inbound and Delivered.
   * @request POST:/api/orderhistory/searchaccountstatus
   * @secure
   */
  orderhistorySearchaccountstatusCreate = (data: OrderHistoryFamilySearchRequest, params: RequestParams = {}) =>
    this.request<OrderHistoryAccountStatusResponse, ProblemDetails | void>({
      path: `/api/orderhistory/searchaccountstatus`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderHistory
   * @name OrderhistorySearchoverviewCreate
   * @summary Get order history overview
   * @request POST:/api/orderhistory/searchoverview
   * @secure
   */
  orderhistorySearchoverviewCreate = (data: OrderOverviewRequest, params: RequestParams = {}) =>
    this.request<OrderOverviewSearchResponse, ProblemDetails | void>({
      path: `/api/orderhistory/searchoverview`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
