import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'helpers';
import { useNavigate } from 'library/routing';
import { SupportedCssUnits } from 'types/cssUnits';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Button, ButtonSizeType } from 'components/shared/Button';
import { Icon, IconName } from 'components/shared/Icon';
import { Logo } from 'components/shared/Logo';
import { Area } from 'generated/data-contracts';
import styles from './DrawerHeader.module.scss';

type LogoSize = {
	width?: SupportedCssUnits;
	height?: SupportedCssUnits;
};
type TitleSize = 'lg' | 'md';
type HeaderSize = 'sm' | 'lg';

export interface DrawerHeaderProps {
	className?: string;
	closeButtonClassName?: string;
	headerTitleClassName?: string;
	title?: string;
	icon?: IconName;
	logo?: string;

	// TODO: These 4 sizes should probably be combined into 1 prop.
	// (according to the Figma designs, there's only a large and a small header)
	titleSize?: TitleSize;
	logoSize?: LogoSize;
	size?: HeaderSize;
	closeButtonSize?: ButtonSizeType;

	showBackButton?: boolean;
	children?: React.ReactElement;
	showBrandSelectorMenu?: boolean;
	hideCloseButton?: boolean;
	handleBack?: () => void;
	handleClose?: () => void;
	handleToggle?: () => void;
}

export const DrawerHeader: React.FunctionComponent<DrawerHeaderProps> = ({
	className,
	closeButtonClassName,
	headerTitleClassName,
	title,
	titleSize,
	icon,
	logo,
	logoSize,
	children,
	size = 'lg',
	showBackButton,
	showBrandSelectorMenu,
	hideCloseButton,
	closeButtonSize,
	handleBack,
	handleClose,
	handleToggle,
}) => {
	const navigate = useNavigate();
	const { data: layout } = useLayoutQuery();
	const frontPageUrl = layout?.frontPageUrl;
	const { data: translations } = useTranslationQuery();

	const route = {
		area: Area.CMS,
	};

	const goToFrontPage = (): void => {
		// Setting link url this way makes sure mobile menu closes
		// even though user is already on that page
		navigate(frontPageUrl || '/', { state: route });
		handleClose?.();
	};

	const onToggleClick = (): void => {
		handleToggle?.();
	};

	return (
		<header className={classNames(styles.header, className, styles['size' + capitalize(size)])}>
			{showBackButton && (
				<Button
					variant={'outline'}
					size={'sm'}
					hasOnlyIcon
					onClick={handleBack}
					title={translations?.shared.back}
				>
					<Icon name={'chevronDown'} rotate={90} size="lg" />
				</Button>
			)}
			{children ? (
				<div>{children}</div>
			) : title ? (
				<div className={styles.titleWrapper}>
					{icon && <Icon name={icon} size="md" />}
					{titleSize === 'md' ? (
						<h6 className={classNames(styles.headerTitle, headerTitleClassName)}>{title}</h6>
					) : (
						<h5 className={classNames(styles.headerTitle, headerTitleClassName)}>{title}</h5>
					)}
				</div>
			) : (
				<>
					{showBrandSelectorMenu && (
						<Button
							className={styles.logoCta}
							title={translations?.shared.siteName}
							hasNoStyles
							onClick={onToggleClick}
						>
							<Icon name={'apps'} size={'sm'} />
						</Button>
					)}
					<div className={classNames({ [styles.brandWrapper]: logo })}>
						<Button
							className={classNames(styles.logoCta)}
							title={translations?.shared.siteName}
							hasNoStyles
							onClick={goToFrontPage}
						>
							<Logo
								name={logo || 'bestsellerLogo'}
								width={logoSize?.width || '7.5rem'}
								height={logoSize?.height || '1.5rem'}
							/>
						</Button>
					</div>
				</>
			)}
			{!hideCloseButton && handleClose && (
				<Button
					className={classNames(styles.closeBtn, closeButtonClassName)}
					hasOnlyIcon
					size={closeButtonSize || 'sm'}
					variant="outline"
					onClick={handleClose}
					title={translations?.shared.close}
				>
					<Icon name={'close'} size="md" />
				</Button>
			)}
		</header>
	);
};
