import React from 'react';
import { Icon } from 'components/shared';
import styles from './IncomingQuantityBadge.module.scss';

interface IncomingQuantityBadgeProps {
	quantity: string | undefined;
}

export const IncomingQuantityBadge: React.FunctionComponent<IncomingQuantityBadgeProps> = (props) => {
	if (!props.quantity || props.quantity === '0') {
		return null;
	}
	return (
		<div className={styles.incomingQuantityBadge}>
			<Icon name="boxDelivery" size="xs" />
			<span>{props.quantity}</span>
		</div>
	);
};
