import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { LoadingBar } from 'components/shared/LoadingBar';
import { useHideWhsPriceSettings } from 'components/shared/hooks/useHideWhsPriceSettings';
import { useProductCardContext } from '../context/ProductCardContext';
import styles from './ProductCardPricing.module.scss';

type ProductCardPricingProps = {
	className?: string;
};

export const ProductCardPricing: React.FunctionComponent<ProductCardPricingProps> = ({ className }) => {
	const { isSkeleton, product } = useProductCardContext();
	const { hideWhsPrice } = useHideWhsPriceSettings();
	const pricingDetails = product.priceDetails;
	const translations = useTranslationQuery().data;
	if (isSkeleton) {
		return (
			<section className={classNames(styles.pricing, className)}>
				<div className={styles.price}>
					<LoadingBar width="4rem" />
					<LoadingBar width="3rem" />
				</div>
				<div className={styles.markup}>
					<LoadingBar width="4rem" />
					<LoadingBar width="3rem" />
				</div>
			</section>
		);
	}
	if (!pricingDetails) {
		return null;
	}

	const rrpPriceLabel = `${translations?.productCard.price.rrp}${
		pricingDetails.currencyCode ? ` (${pricingDetails.currencyCode})` : ''
	}`;
	return (
		<section className={classNames(styles.pricing, className)}>
			<div className={styles.price}>
				<span className={styles.priceLabel}>{rrpPriceLabel}</span>
				<span className={styles.priceInfo}>{pricingDetails.recommendedRetailPrice}</span>
			</div>
			{!hideWhsPrice && (
				<div className={styles.markup}>
					<span className={styles.priceLabel}>{translations?.productCard.price.markup}</span>
					<span className={styles.priceInfo}>{pricingDetails.markup}</span>
				</div>
			)}
		</section>
	);
};
