import { FilterValueResponse } from 'generated/data-contracts';
import {
	CheckboxFilterType,
	FilterTypesEnum,
	RangeFilterType,
	SearchableCheckboxFilterType,
	SingleFilterType,
} from '../types/FilterTypes';

export type SelectedFilterValues = Map<string, FilterValueResponse>;

export type FilterState = {
	[FilterTypesEnum.Checkbox]: Map<string, SelectedFilterValues>;
	[FilterTypesEnum.Range]: Map<string, RangeFilterValueResponse>;
	[FilterTypesEnum.Date]: Map<string, DateFilterValueResponse>;
	[FilterTypesEnum.Single]: Map<string, FilterValueResponse>;
};

export interface RangeFilterValueResponse {
	min?: number;
	max?: number;
}

export interface DateFilterValueResponse {
	min?: Date;
	max?: Date;
}

export type FilterTypes = CheckboxFilterType | SearchableCheckboxFilterType | RangeFilterType | SingleFilterType;

export const DEFAULT_FILTER_STATE: FilterState = {
	[FilterTypesEnum.Checkbox]: new Map<string, SelectedFilterValues>(),
	[FilterTypesEnum.Range]: new Map<string, RangeFilterValueResponse>(),
	[FilterTypesEnum.Date]: new Map<string, DateFilterValueResponse>(),
	[FilterTypesEnum.Single]: new Map<string, FilterValueResponse>(),
};
