import React, { CSSProperties, LegacyRef } from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'components/shared/Link';
import { ResourceLocation } from 'generated/data-contracts';
import { capitalize } from 'helpers/index';
import styles from './Button.module.scss';

export const buttonVariants = [
	'primary',
	'secondary',
	'dark',
	'ghost',
	'outline',
	'danger',
	'linkButton',
	'ternary',
] as const;

export type ButtonVariantType = (typeof buttonVariants)[number];

export const buttonSizes = ['xs', 'sm', 'md'] as const;

export type ButtonSizeType = (typeof buttonSizes)[number];

export interface ButtonProps extends Partial<LinkProps> {
	className?: string;
	contentClassName?: string;
	style?: CSSProperties;
	children?: React.ReactNode;
	route?: ResourceLocation;
	href?: string;
	onClick?: React.MouseEventHandler<HTMLElement>;
	onMouseEnter?: (e: never) => void;
	onMouseLeave?: (e: never) => void;
	type?: 'button' | 'submit' | 'reset';
	form?: string;
	disabled?: boolean;
	isLoading?: boolean;
	variant?: ButtonVariantType;
	size?: ButtonSizeType;
	isFullWidth?: boolean;
	isNotRound?: boolean;
	hasOnlyIcon?: boolean;
	ctaGroupPos?: 'left' | 'center' | 'right';
	hasNoPaddingX?: boolean;
	hasNoStyles?: boolean;
	hasNoBorder?: boolean;
}

/**
 * Generic Button component, can be rendered as button or anchor, depending on props passed to it.
 */
export const Button = React.forwardRef<HTMLElement, ButtonProps>(
	(
		{
			className,
			contentClassName,
			style,
			variant,
			size,
			isFullWidth,
			isNotRound,
			hasOnlyIcon,
			ctaGroupPos,
			href,
			target,
			onClick,
			onMouseEnter,
			onMouseLeave,
			children,
			route,
			to,
			type,
			form,
			hasNoPaddingX,
			hasNoStyles,
			disabled,
			isLoading,
			hasNoBorder,
			...props // For passing props inherited from HTMLAnchorElement
		},
		ref,
	) => {
		const classes = classNames(
			{
				[styles.button]: !hasNoStyles,
				[styles.isFullWidth]: isFullWidth,
				[styles.isNotRound]: isNotRound,
				[styles.hasOnlyIcon]: hasOnlyIcon,
				[styles.hasNoPaddingX]: hasNoPaddingX,
				[styles.isLoading]: isLoading,
				[styles.hasNoBorder]: hasNoBorder,
				['u-cta-group-' + ctaGroupPos]: ctaGroupPos,
			},
			variant && styles['variant' + capitalize(variant)],
			size && styles['size' + capitalize(size)],
			className,
		);

		if (to || route) {
			return (
				<Link className={classes} to={to || route?.externalRoute} state={route} target={target} {...props}>
					{children}
				</Link>
			);
		}

		if (href) {
			return (
				<a ref={ref as LegacyRef<HTMLAnchorElement>} className={classes} href={href} target={target} {...props}>
					{children}
				</a>
			);
		}

		return (
			<button
				ref={ref as LegacyRef<HTMLButtonElement>}
				className={classes}
				style={style}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				type={type || 'button'}
				form={form}
				aria-controls={props['aria-controls']}
				aria-expanded={props['aria-expanded']}
				aria-label={props['aria-label']}
				aria-current={props['aria-current']}
				disabled={disabled}
				title={props.title}
				{...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
			>
				{isLoading && <span className={styles.spinner} />}
				<span className={classNames(styles.content, contentClassName)}>{children}</span>
			</button>
		);
	},
);

Button.displayName = 'Button';
