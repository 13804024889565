/**
 * Clamps a given value to a specified minimum or zero.
 *
 * @param value - The value to be clamped.
 * @param min - The minimum value to clamp to.
 * @returns The clamped value, which will be either the minimum value or the original value if it is greater than the minimum. If the value is zero, it returns zero.
 */
export function clampToMinOrZero(value: number, min: number): number {
	if (value === 0) {
		return 0;
	}
	return value < min ? min : value;
}
