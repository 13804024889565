import React from 'react';
import { CheckboxFilterType, FilterTypesEnum } from '../../types/FilterTypes';
import { FilterDropdown } from '../shared/FilterDropdown';
import { CheckBoxFilterContent } from './CheckBoxFilterContent';

export interface CheckBoxFilterProps {
	filter: CheckboxFilterType;
}

export const CheckBoxFilter: React.FunctionComponent<CheckBoxFilterProps> = ({ filter }) => {
	return (
		<FilterDropdown filter={filter} filterType={FilterTypesEnum.Checkbox}>
			<CheckBoxFilterContent filter={filter} />
		</FilterDropdown>
	);
};
