import React from 'react';
import { uniqueId } from 'lodash';
import { useOnClickAway } from 'helpers/useOnClickAway';
import { Filter, FilterTypesEnum } from '../../../types/FilterTypes';
import { FilterBody } from '../FilterBody';
import { FilterButton } from '../FilterButton';
import styles from './FilterDropdown.module.scss';

export interface FilterDropdownProps {
	filter: Filter;
	filterType: FilterTypesEnum;
	headerProps?: React.PropsWithChildren;
	bodyClassName?: string;
	hasSelectedItemsSection?: boolean;
}

export const FilterDropdown: React.FunctionComponent<React.PropsWithChildren<FilterDropdownProps>> = ({
	filter,
	filterType,
	children,
	headerProps,
	hasSelectedItemsSection,
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const sectionRef = React.useRef<HTMLElement>(null);
	const buttonRef = React.useRef<HTMLButtonElement>(null);
	const filterId = React.useRef(uniqueId('filter-')).current;
	const recalculatePosition = React.useCallback(() => {
		const button = buttonRef.current;
		const element = sectionRef.current;
		if (!button || !element || !isOpen) return;

		const dropdownHeight = element.clientHeight;
		const windowHeight = window.innerHeight;
		const dropdownTop = button.getBoundingClientRect().top;

		const dropdownWidth = element.clientWidth;
		const windowWidth = window.innerWidth;
		const dropdownLeft = button.getBoundingClientRect().left;

		if (windowHeight - dropdownTop < dropdownHeight) {
			element.style.bottom = '100%';
			element.style.top = 'auto';
		} else {
			element.style.bottom = 'auto';
			element.style.top = '100%';
		}

		if (windowWidth - dropdownWidth < dropdownLeft) {
			element.style.left = 'auto';
			element.style.right = '0';
		} else {
			element.style.left = '0';
			element.style.right = 'auto';
		}
	}, [isOpen]);

	React.useEffect(() => {
		recalculatePosition();
		window.addEventListener('resize', recalculatePosition);
		window.addEventListener('scroll', recalculatePosition);
		return () => {
			window.removeEventListener('resize', recalculatePosition);
			window.removeEventListener('scroll', recalculatePosition);
		};
	}, [recalculatePosition]);
	const closeDialog = () => {
		setIsOpen(false);
	};
	const openDialog = () => {
		setIsOpen(true);
	};
	useOnClickAway(sectionRef, closeDialog, true);

	return (
		<div className={styles.container}>
			<FilterButton id={filterId} filter={filter} ref={buttonRef} onClick={openDialog} filterType={filterType} />
			<FilterBody
				id={filterId}
				filter={filter}
				ref={sectionRef}
				isOpen={isOpen}
				onClose={closeDialog}
				headerProps={headerProps}
				hasSelectedItemsSection={hasSelectedItemsSection}
			>
				{children}
			</FilterBody>
		</div>
	);
};
