import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import CopyTextButton from 'components/shared/CopyTextButton';
import { Link } from 'components/shared/Link';
import { LoadingBar } from 'components/shared/LoadingBar';
import { useProductCardContext } from '../context/ProductCardContext';
import styles from './ProductCardTitle.module.scss';

interface ProductCardTitleProps {
	className?: string;
}

export const ProductCardTitle: React.FunctionComponent<ProductCardTitleProps> = ({ className }) => {
	const { isSkeleton, product, productUrl, updateNavParams, route } = useProductCardContext();
	const translations = useTranslationQuery().data;
	if (isSkeleton) {
		return (
			<div className={classNames(styles.title, className)}>
				<LoadingBar width="50%" />
				<LoadingBar width="25%" />
			</div>
		);
	}

	return (
		<div className={classNames(styles.title, className)}>
			<Link onClick={updateNavParams} className={styles.productPrimaryAction} to={productUrl} state={route}>
				<p className={styles.name}>{product.styleName}</p>
			</Link>

			<CopyTextButton
				text={product.styleNumber}
				title={translations?.productDetails.copyStyleNumber}
				copySuccessMessage={translations?.productDetails.copiedStyleNumber}
				className={styles.styleNumber}
			/>
		</div>
	);
};
