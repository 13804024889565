import React, { PropsWithChildren } from 'react';
import {
	BasketLineQuantityResponse,
	BasketLineRequest,
	Lookups,
	NewBasketProductBundleResponse,
	NewBasketProductMasterResponse,
	ProductBundleId,
	ProductFamilyId,
	ProductMasterId,
} from 'generated/data-contracts';

interface UseBasketRowContextReturnType {
	bundle: NewBasketProductBundleResponse;
	master?: NewBasketProductMasterResponse;
	lookups: Lookups;
	productId: ProductFamilyId;
	bundleId: ProductBundleId;
	masterId?: ProductMasterId;
	isSDO: boolean;
	isFetchingBasket: boolean;
	onQuantityUpdate: (newBasketLine: BasketLineRequest) => Promise<BasketLineQuantityResponse | void>;
	shouldUseAvailability: boolean;
	allowedShipTos: Record<string, boolean>;
}

interface BasketRowContextContextProps {
	productId: ProductFamilyId;
	bundleId: ProductBundleId;
	masterId?: ProductMasterId;
	lookups: Lookups;
	allowedShipTos: Record<string, boolean>;
	isFetchingBasket: boolean;
	shouldUseAvailability: boolean;
	onQuantityUpdate: (newBasketLine: BasketLineRequest) => Promise<BasketLineQuantityResponse | void>;
}

const useBasketRowContextState = ({
	productId,
	bundleId,
	masterId,
	lookups,
	allowedShipTos,
	onQuantityUpdate,
	isFetchingBasket,
	shouldUseAvailability,
}: BasketRowContextContextProps): UseBasketRowContextReturnType => {
	const bundle = lookups.bundles[bundleId];
	let master: NewBasketProductMasterResponse | undefined = undefined;
	if (masterId) {
		master = lookups.masters[masterId];
	}
	const isSDO = Object.entries(lookups.shipTos).length === 1;
	return {
		bundle,
		isSDO,
		productId,
		bundleId,
		masterId,
		master,
		lookups,
		onQuantityUpdate,
		allowedShipTos,
		shouldUseAvailability,
		isFetchingBasket,
	};
};

const BasketRowContextContext = React.createContext<UseBasketRowContextReturnType | null>(null);

export const BasketRowContextProvider: React.FunctionComponent<PropsWithChildren<BasketRowContextContextProps>> = ({
	children,
	...props
}: PropsWithChildren<BasketRowContextContextProps>) => {
	const value = useBasketRowContextState(props);

	return <BasketRowContextContext.Provider value={value}>{children}</BasketRowContextContext.Provider>;
};

export const useBasketRowContext = (): UseBasketRowContextReturnType => {
	const context = React.useContext(BasketRowContextContext);

	if (!context) {
		throw new Error('useBasketRowContext must be used within a BasketRowContextProvider');
	}

	return context;
};
