import { AvailabilityIcons } from '..';
import { NOW_DELIVERY_DATE_VALUE } from 'components/features/NewBasket/shared/basketTypes';
import { useBasketRowContext } from 'components/fragments/Basket/Desktop/Shared/context/BasketRowContext';

type UseVariantAvailabilityIconProps = {
	variantId?: string;
	inputValue?: number;
	shouldUseStockAvailability?: boolean;
};

/**
 * Custom hook that returns the availability icon based on the variant's availability.
 *
 * @param variantId - The ID of the variant.
 * @param inputValue - The input value.
 * @returns The availability icon.
 */
const useVariantAvailabilityIcon = ({
	variantId,
	inputValue = 0,
	shouldUseStockAvailability = false,
}: UseVariantAvailabilityIconProps): AvailabilityIcons => {
	const { lookups } = useBasketRowContext();
	if (!shouldUseStockAvailability) return undefined;
	if (!lookups) return undefined;
	const availabilities = lookups.availabilities.availabilities;
	if (!variantId) return undefined;
	const availability = availabilities[variantId];
	if (!availability) return undefined;

	const firstAvailability = availability.availabilities[0];
	const hasOnlyBackOrders = firstAvailability.date !== NOW_DELIVERY_DATE_VALUE;
	const backOrderQuantityThreshold = hasOnlyBackOrders ? 0 : firstAvailability.amount;

	let availabilityIcon: AvailabilityIcons;
	if (hasOnlyBackOrders) availabilityIcon = 'backOrder';
	if (inputValue > backOrderQuantityThreshold) availabilityIcon = 'backOrderWithDot';

	return availabilityIcon;
};

export { useVariantAvailabilityIcon };
