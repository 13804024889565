import React from 'react';
import { Badge } from 'components/shared/Badge';
import { Button, ButtonProps } from 'components/shared/Button';
import { Icon, IconName } from 'components/shared/Icon';
import { useFilterContext } from '../../../context/FilterContext';
import { Filter, FilterTypesEnum } from '../../../types/FilterTypes';
import styles from './FilterButton.module.scss';

export interface FilterButtonProps extends ButtonProps {
	id: string;
	filter: Filter;
	filterType: FilterTypesEnum;
	icon?: IconName;
}

export const FilterButton = React.forwardRef<HTMLButtonElement, FilterButtonProps>(
	({ filter, icon, id, onClick, filterType }, ref) => {
		const {
			styling: {
				button: { variant, size },
			},
			getActiveCount,
		} = useFilterContext();

		const activeCount = getActiveCount(filterType, filter.id);

		return (
			<Button
				ref={ref}
				className={styles.button}
				variant={variant}
				size={size}
				id={`${id}-target`}
				aria-haspopup="listbox"
				onClick={onClick}
			>
				{icon && <Icon name={icon} size={'md'} />}
				<span>{filter.label}</span>
				<Badge value={activeCount} />
			</Button>
		);
	},
);

FilterButton.displayName = 'FilterButton';
