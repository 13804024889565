import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { useSearchParams, useNavigate } from 'library/routing';
import { isEmpty } from 'lodash';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import {
	Button,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerHeaderWBackButton,
	DrawerLevel,
	Icon,
	Link,
	SideMenu,
	SideMenuItem,
} from 'components/shared';
import { MegaMenuItem, MegaMenuModel, PageType, ProductCategoryId, ResourceLocation } from 'generated/data-contracts';
import styles from './CategoryMenu.module.scss';

interface CategoryMenuProps {
	productCategoryId?: ProductCategoryId;
	matchingProductCategoryIds: ProductCategoryId[] | null | undefined;
	changeCategory?: (categoryId: string) => void;
	isMobile?: boolean;
	isLoading?: boolean;
	phrase?: string;
}

export const CategoryMenu: React.FunctionComponent<CategoryMenuProps> = ({
	productCategoryId,
	matchingProductCategoryIds,
	phrase,
	isMobile,
	isLoading,
	changeCategory,
}) => {
	const { data: layout } = useLayoutQuery();
	const { data: translations } = useTranslationQuery();
	const children = (layout?.megaMenu as MegaMenuModel)?.children;
	const [isOpen, setIsOpen] = useState(false);
	const hasPhrase = phrase !== undefined;
	const [activeCategoryLevel, setActiveCategoryLevel] = React.useState('');

	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	const queryParams = React.useMemo(() => {
		const updatedParams = searchParams;
		const excludedParams = ['page'];

		excludedParams.forEach((excludedParam) => {
			if (!updatedParams.get(excludedParam)) return;

			updatedParams.delete(excludedParam);
		});

		return `?${updatedParams.toString()}`;
	}, [searchParams]);

	if (isEmpty(children) || isLoading) return <SideMenu />;

	const findMatch = (item: MegaMenuItem): boolean =>
		(matchingProductCategoryIds?.length ?? 0) > 0 &&
		(item.route?.pageType == PageType.All ||
			(matchingProductCategoryIds?.some((cid) => cid === item.route?.productCategoryId) ?? false));

	const checkIfSelected = (item: MegaMenuItem): boolean => {
		if (!productCategoryId && item.route?.pageType == PageType.All) {
			return true;
		}

		if (item.route?.productCategoryId && item.route?.productCategoryId === productCategoryId) {
			return true;
		}

		if (item.children.length > 0) {
			const childRoute = item.children.find(
				(i) => i.route?.productCategoryId && i.route?.productCategoryId === productCategoryId,
			);

			if (!isEmpty(childRoute)) {
				return true;
			}
		}

		return false;
	};

	const getRoute = (item: MegaMenuItem): ResourceLocation | undefined =>
		phrase && item.route ? { ...item.route, externalRoute: `${item.route?.externalRoute}` } : item.route;

	const addParents = (parents: MegaMenuItem[], hasChildren: boolean, current: SideMenuItem[]): SideMenuItem[] => {
		let childrenCollection = current;
		for (let i = 0; i < parents.length; i++) {
			const parent = parents[i];
			const currentChildrenCollection = childrenCollection;
			const newMenuItem = {
				route: getRoute(parent),
				name: parent.name,
				isInActiveSection: i === parents.length - 1 && !hasChildren,
				isDisabled: !findMatch(parent),
				children: new Array<SideMenuItem>(),
				selected: false,
			};

			const isNotTheTopOfTheTree = i > 0;
			if (isNotTheTopOfTheTree) {
				const grandParent = parents[i - 1];
				grandParent.children?.forEach((cousin) => {
					let newUncle = {
						route: getRoute(cousin),
						name: cousin.name,
						isInActiveSection: false,
						isDisabled: !findMatch(cousin),
						children: new Array<SideMenuItem>(),
						selected: false,
					};
					if (newUncle.route?.productCategoryId === newMenuItem.route?.productCategoryId) {
						newUncle = newMenuItem;
					}
					currentChildrenCollection.push(newUncle);
				});
			} else {
				currentChildrenCollection.push(newMenuItem);
			}
			childrenCollection = newMenuItem.children;
		}
		return childrenCollection;
	};

	const addSiblings = (
		parents: MegaMenuItem[],
		hasChildren: boolean,
		selected: SideMenuItem,
		current: SideMenuItem[],
	): void => {
		const immediateParent = parents[parents.length - 1];
		immediateParent.children?.forEach((child) => {
			let sibling: SideMenuItem = {
				route: getRoute(child),
				name: child.name,
				isInActiveSection: !hasChildren,
				isDisabled: !findMatch(child),
				children: new Array<SideMenuItem>(),
				selected: false,
			};
			if (child.route?.productCategoryId === selected.route?.productCategoryId) {
				sibling = selected;
			}
			current.push(sibling);
		});
	};

	const addChildren = (item: MegaMenuItem, selected: SideMenuItem): void => {
		item.children?.forEach((child) => {
			const isSelected = checkIfSelected(child);
			const childMenu = {
				route: getRoute(child),
				name: child.name,
				isInActiveSection: isSelected,
				isDisabled: !findMatch(child),
				children: [],
				selected: isSelected,
			};
			selected.children?.push(childMenu);
		});
	};

	const mapSelectedCategory = (item: MegaMenuItem, parents: MegaMenuItem[], hasChildren: boolean): SideMenuItem[] => {
		const root = new Array<SideMenuItem>();
		const isSelected = checkIfSelected(item);
		let current = root;
		const selected = {
			route: getRoute(item),
			name: item.name,
			selected: isSelected,
			isDisabled: !findMatch(item),
			isInActiveSection: isSelected,
			children: new Array<SideMenuItem>(),
		};

		if (parents && parents.length > 0) {
			current = addParents(parents, hasChildren, current);
			addSiblings(parents, hasChildren, selected, current);
		} else {
			current.push(selected);
		}

		if (hasChildren) {
			addChildren(item, selected);
		}

		return root;
	};

	const handleCategoryItem = (
		item: MegaMenuItem,
		isSelected: boolean,
		parents: MegaMenuItem[],
		hasChildren: boolean,
		isMatch?: boolean,
	): SideMenuItem[] => {
		const sideMenuItem: SideMenuItem[] = [];

		if (isSelected || isMobile) {
			sideMenuItem.push(...mapSelectedCategory(item, parents, hasChildren));
		} else {
			sideMenuItem.push({
				route: getRoute(item),
				name: item.name,
				selected: isSelected,
				isDisabled: !isMatch,
				isInActiveSection: false,
				children: new Array<SideMenuItem>(),
			});
		}

		return sideMenuItem;
	};

	const mapMenuItems = (
		parents: MegaMenuItem[],
		items: MegaMenuItem[] | null | undefined,
		id?: ProductCategoryId,
	): SideMenuItem[] | null => {
		if (!items) return null;

		const topSideMenuItems: SideMenuItem[] = [];
		for (const item of items) {
			const hasChildren = (item.children && item.children.length > 0) ?? false;
			let isSelectedCategory = checkIfSelected(item);

			let isMatch = false;

			if (hasPhrase || !id) {
				isMatch = findMatch(item);
				isSelectedCategory = isMatch && checkIfSelected(item);
			}

			topSideMenuItems.push(...handleCategoryItem(item, isSelectedCategory, parents, hasChildren, isMatch));
		}
		return topSideMenuItems.length > 0 ? topSideMenuItems : null;
	};

	const handleOpen = (): void => {
		setIsOpen(true);
	};

	const handleClose = (): void => {
		setActiveCategoryLevel('');
		setIsOpen(false);
	};

	const handleNavigation = (itemRoute?: ResourceLocation): void => {
		if (!itemRoute) return;

		navigate(itemRoute.externalRoute + queryParams, { state: itemRoute });
		handleClose();
	};

	const handleNextLevel = (itemId: string): void => {
		setActiveCategoryLevel(itemId);
	};

	const handlePreviousLevel = (): void => {
		setActiveCategoryLevel('');
	};

	const categoryItems = mapMenuItems(new Array<MegaMenuItem>(), children) || [];

	const handleCategoryClick = (item: SideMenuItem): void => {
		if (changeCategory) {
			changeCategory(item.route?.productCategoryId || '');
		} else {
			handleNavigation(item.route);
		}
	};

	if (!isMobile)
		return (
			<SideMenu
				ariaLabel={translations?.shared.navigation.category}
				menu={categoryItems}
				changeCategory={changeCategory}
			/>
		);

	return (
		<>
			<Button className={styles.mobileCategoryButton} variant={'secondary'} size={'sm'} onClick={handleOpen}>
				<Icon name="category" size="sm" />
				{translations?.productList.categories}
			</Button>
			{isOpen && (
				<Drawer onClose={handleClose}>
					<DrawerHeader title={translations?.productList.categories} handleClose={handleClose} />
					<DrawerBody className={styles.drawerBody}>
						{categoryItems.map((item, parentIndex) => {
							if (item.isDisabled) return;

							const hasChildren = item.children?.length > 0;
							const id = `${item.name}-${parentIndex}`;

							return (
								<Fragment key={`fragmentId-${item.id || parentIndex}`}>
									<Button
										key={`parentId-${item.id}`}
										onClick={(): void => handleCategoryClick(item)}
										className={classNames(styles.mobileCategoryLink, {
											[styles.isActive]: item.selected,
										})}
										hasNoStyles
									>
										{item.name}
										{hasChildren && (
											<Button
												hasNoStyles
												onClick={(e): void => {
													e.preventDefault();
													e.stopPropagation();
													handleNextLevel(`${item.name}-${parentIndex}`);
												}}
											>
												<Icon name={'chevronDown'} size={'lg'} rotate={270} />
											</Button>
										)}
									</Button>
									{activeCategoryLevel === id && (
										<DrawerLevel isActive={activeCategoryLevel === id}>
											<DrawerHeaderWBackButton
												handleClose={handleClose}
												handleBack={handlePreviousLevel}
												title={item.name}
											/>
											<DrawerBody>
												{item.children.map((child, index) => {
													if (changeCategory) {
														return (
															<Button
																className={classNames(styles.mobileCategoryLink, {
																	[styles.isActive]: child.selected,
																})}
																key={`childId-${child.id}-${index}`}
																onClick={(): void => {
																	changeCategory(
																		child.route?.productCategoryId || '',
																	);
																}}
																variant={'linkButton'}
																isTextLink
															>
																{child.name}
															</Button>
														);
													}

													return (
														<Link
															className={classNames(styles.mobileCategoryLink, {
																[styles.isActive]: child.selected,
															})}
															key={`childId-${child.id}-${index}`}
															onClick={handleClose}
															to={child.route?.externalRoute + queryParams}
															state={child.route}
															isTextLink
														>
															{child.name}
														</Link>
													);
												})}
											</DrawerBody>
										</DrawerLevel>
									)}
								</Fragment>
							);
						})}
					</DrawerBody>
				</Drawer>
			)}
		</>
	);
};
