import {
	useQuery,
	UseQueryResult,
	UseMutationResult,
	useMutation,
	useQueryClient,
	UseInfiniteQueryResult,
	useInfiniteQuery,
	InfiniteData,
	keepPreviousData,
} from '@tanstack/react-query';
import { isEmpty, sortBy } from 'lodash';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { ShipTo as ShipToApi } from 'generated/ShipTo';
import {
	ShipToListResponse,
	ProblemDetails,
	ShipToId,
	FavouriteShipToFilterResponse,
	VmiBrandListRespose,
	ShipToSearchRequest,
} from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { getInfiniteQueryNextPage } from 'helpers/getInfiniteQueryNextPage';
import { formatTranslation } from 'helpers/stringHelpers';
import { useAppStore } from 'store/appStore';
import { useTranslationQuery } from './translations';

const DefaultPageSize = 150;

const mapResult = (data: InfiniteData<ShipToListResponse>, sortResult: boolean): InfiniteData<ShipToListResponse> => {
	return {
		pageParams: data.pageParams,
		pages: data.pages.map((page) => ({
			pagingInformation: page.pagingInformation,
			cmiEnabled: page.cmiEnabled,
			vmiEnabled: page.vmiEnabled,
			shipTos: sortResult ? sortBy(page.shipTos, (account) => account.positionNumber) : page.shipTos,
		})),
	};
};

export const normalizeResponse = (
	response?: UseInfiniteQueryResult<InfiniteData<ShipToListResponse>>,
): Pick<ShipToListResponse, 'shipTos' | 'pagingInformation'> & {
	isLoading: boolean;
	isFetching: boolean;
} => {
	return {
		shipTos: response?.data?.pages.flatMap((page) => page.shipTos) ?? [],
		pagingInformation: response?.data?.pages[0]?.pagingInformation ?? {
			currentPage: 0,
			totalNumberOfItems: 0,
			pageSize: 0,
		},
		isLoading: response?.isLoading ?? false,
		isFetching: response?.isFetching ?? false,
	};
};

export interface ShipToBasedOnBasketItemsQuery {
	phrase?: string;
	page?: number;
	pageSize?: number;
	selectedShipToIds: string[];
}

export interface UseShiptoSearchOptions {
	phrase?: string;
	page?: number;
	pageSize?: number;
	sortResult?: boolean;
	isEnabled?: boolean;
	selectedShipTos?: string[];
	refetchOnSelectedShipTosChange?: boolean;
}
export const useShiptoSearchQuery = ({
	phrase,
	page = 1,
	sortResult = true,
	pageSize = DefaultPageSize,
	isEnabled = true,
	selectedShipTos = [],
	refetchOnSelectedShipTosChange = true,
}: UseShiptoSearchOptions): UseInfiniteQueryResult<InfiniteData<ShipToListResponse>> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const { data: translations } = useTranslationQuery();

	const { notificationActions } = useNotificationContext();

	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.shipTo.search(
			segmentationId,
			page,
			phrase,
			refetchOnSelectedShipTosChange ? selectedShipTos : [],
		).queryKey,
		queryFn: async ({ pageParam = page }): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoSearchCreate({
				phrase,
				page: pageParam,
				pageSize: pageSize + selectedShipTos?.length,
				selectedShipToIds: !isEmpty(selectedShipTos) ? selectedShipTos : undefined,
			});

			if (!result.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
					type: AlertTypes.DANGER,
				});
			}

			return result.data;
		},
		initialPageParam: 1,
		placeholderData: keepPreviousData,
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		select: (d) => mapResult(d, sortResult),
		enabled: isEnabled,
	});
};

export const useShiptoSelectedListQuery = (): UseQueryResult<ShipToListResponse> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const { data: translations } = useTranslationQuery();

	const { notificationActions } = useNotificationContext();

	return useQuery({
		queryKey: queryKeys.shipTo.listselected(segmentationId).queryKey,
		queryFn: async (): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoListselectedList();

			if (!result.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.accountSelector.errorFetchingSelectedAccounts, {}),
					type: AlertTypes.DANGER,
				});
			}

			return result.data;
		},
	});
};

export const useSearchBasedOnBasketItemsQuery = (query: ShipToSearchRequest) => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useInfiniteQuery({
		queryKey: queryKeys.shipTo.searchBasedOnBasketItems('basket', query).queryKey,
		queryFn: async (): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoSearchbasedonbasketitemsCreate(query);

			if (!result.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
					type: AlertTypes.DANGER,
				});
			}

			return result.data;
		},
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		initialPageParam: 1,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: 'always',
	});
};

export const useShiptoSearchFavouriteQuery = ({
	phrase,
	selectedCustomerGroupIds,
	selectedSalesRepIds,
	page = 1,
	pageSize = DefaultPageSize,
	sortResult = true,
	isEnabled = true,
	selectedShipTos = [],
}: UseShiptoSearchOptions & {
	selectedCustomerGroupIds: string[];
	selectedSalesRepIds: string[];
}) => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const { data: translations } = useTranslationQuery();

	const { notificationActions } = useNotificationContext();

	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.shipTo.searchFavourite(
			segmentationId,
			phrase,
			selectedCustomerGroupIds,
			selectedSalesRepIds,
		).queryKey,
		queryFn: async ({ pageParam = page }): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoSearchfavouriteCreate({
				phrase,
				page: pageParam,
				pageSize: pageSize + selectedShipTos?.length,
				...(selectedCustomerGroupIds.length > 0 ? { SelectedCustomerGroupIds: selectedCustomerGroupIds } : {}),
				...(selectedSalesRepIds.length > 0 ? { SelectedSalesReps: selectedSalesRepIds } : {}),
				...(selectedShipTos.length > 0 ? { SelectedShipToIds: selectedShipTos } : {}),
			});

			if (!result.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
					type: AlertTypes.DANGER,
				});
			}

			return result.data;
		},
		initialPageParam: 1,
		placeholderData: keepPreviousData,
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		select: (d) => mapResult(d, sortResult),
		enabled: isEnabled,
	});
};

export const useShiptoSearchNonFavouriteQuery = ({
	phrase,
	selectedCustomerGroupIds,
	selectedSalesRepIds,
	page = 1,
	sortResult = true,
	isEnabled = true,
	pageSize = DefaultPageSize,
	selectedShipTos = [],
}: UseShiptoSearchOptions & {
	selectedCustomerGroupIds: string[];
	selectedSalesRepIds: string[];
}): UseInfiniteQueryResult<InfiniteData<ShipToListResponse>, Error> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const { data: translations } = useTranslationQuery();

	const { notificationActions } = useNotificationContext();

	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.shipTo.searchNonFavourite(
			segmentationId,
			pageSize,
			phrase,
			selectedCustomerGroupIds,
			selectedSalesRepIds,
		).queryKey,
		queryFn: async ({ pageParam = page }): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoSearchnonfavouriteCreate({
				phrase,
				page: pageParam,
				pageSize: pageSize + selectedShipTos?.length,
				...(selectedCustomerGroupIds.length > 0 ? { SelectedCustomerGroupIds: selectedCustomerGroupIds } : {}),
				...(selectedSalesRepIds.length > 0 ? { SelectedSalesReps: selectedSalesRepIds } : {}),
				...(selectedShipTos.length > 0 ? { SelectedShipToIds: selectedShipTos } : {}),
			});

			if (!result.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
					type: AlertTypes.DANGER,
				});
			}

			return result.data;
		},
		select: (d) => mapResult(d, sortResult),
		placeholderData: keepPreviousData,
		initialPageParam: 1,
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		enabled: isEnabled,
	});
};

export const useShiptoSelectedFavouriteListQuery = ({ isEnabled = true }): UseQueryResult<ShipToListResponse> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const { data: translations } = useTranslationQuery();

	const { notificationActions } = useNotificationContext();

	return useQuery({
		queryKey: queryKeys.shipTo.favouriteListSelected(segmentationId).queryKey,
		queryFn: async (): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoListselectedfavouriteList();

			if (!result.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.accountSelector.errorFetchingSelectedAccounts, {}),
					type: AlertTypes.DANGER,
				});
			}

			return result.data;
		},
		enabled: isEnabled,
	});
};

export const useShiptoCustomerGroupFilterQuery = ({
	phrase,
	page = 1,
	isEnabled = true,
}: UseShiptoSearchOptions): UseInfiniteQueryResult<InfiniteData<FavouriteShipToFilterResponse> | ProblemDetails> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const { data: translations } = useTranslationQuery();

	const { notificationActions } = useNotificationContext();

	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.shipTo.customerGroupFilter(segmentationId, phrase).queryKey,
		queryFn: async ({ pageParam = page }) => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoCustomergroupfilterList({
				searchPhrase: phrase,
				page: pageParam,
				pageSize: DefaultPageSize,
			});

			if (!result.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
					type: AlertTypes.DANGER,
				});
			}

			return result.data;
		},
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = lastPage.more ? allPages.length + page : undefined;
			return nextPage;
		},
		initialPageParam: 1,
		placeholderData: keepPreviousData,
		enabled: isEnabled,
	});
};

export const useShiptoSalesRepFilterQuery = ({
	phrase,
	page = 1,
	isEnabled = true,
}: UseShiptoSearchOptions): UseInfiniteQueryResult<InfiniteData<FavouriteShipToFilterResponse> | ProblemDetails> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const { data: translations } = useTranslationQuery();

	const { notificationActions } = useNotificationContext();

	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.shipTo.salesRepFilter(segmentationId, phrase).queryKey,
		queryFn: async ({ pageParam = page }) => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoSalesrepfilterList({
				searchPhrase: phrase,
				page: pageParam,
				pageSize: DefaultPageSize,
			});

			if (!result.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
					type: AlertTypes.DANGER,
				});
			}

			return result.data;
		},
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = lastPage.more ? allPages.length + page : undefined;
			return nextPage;
		},
		initialPageParam: 1,
		placeholderData: keepPreviousData,
		enabled: isEnabled,
	});
};

interface UpdateFavouritesRequest {
	shipToIds: ShipToId[];
}

export const useShiptoUpdateFavouritesMutation = (): UseMutationResult<
	HttpResponse<void, void | ProblemDetails>,
	HttpResponse<void>,
	UpdateFavouritesRequest
> => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			return shipToApi.shiptoUpdatefavouriteshiptosCreate({
				shipToIds: variables.shipToIds,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: queryKeys.shipTo._def });
		},
	});
};

export const useAddFavouriteShipTosMutation = (): UseMutationResult<
	HttpResponse<void, void | ProblemDetails>,
	HttpResponse<void>,
	UpdateFavouritesRequest
> => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			return shipToApi.shiptoAddfavouriteshiptosCreate({
				shipToIds: variables.shipToIds,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: queryKeys.shipTo._def });
			queryClient.invalidateQueries({ queryKey: queryKeys.shipTo.favouriteListSelected._def });
		},
	});
};

export const useRemoveFavouriteShipTosMutation = (): UseMutationResult<
	HttpResponse<void, void | ProblemDetails>,
	HttpResponse<void>,
	UpdateFavouritesRequest
> => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			return shipToApi.shiptoRemovefavouriteshiptosCreate({
				shipToIds: variables.shipToIds,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: queryKeys.shipTo._def });
			queryClient.invalidateQueries({ queryKey: queryKeys.shipTo.favouriteListSelected._def });
		},
	});
};

export const useListVmiShipTosQuery = (): UseQueryResult<ShipToListResponse, void | ProblemDetails> => {
	const segmentationId = useAppStore((state) => state.segmentationId);

	return useQuery({
		queryKey: queryKeys.shipTo.listVmiShipTos(segmentationId).queryKey,
		queryFn: async (): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const response = await shipToApi.shiptoListvmishiptosList();

			return response.data;
		},
	});
};

export const useListVmiShipToBrandsQuery = (
	shipToId?: ShipToId,
): UseQueryResult<VmiBrandListRespose, void | ProblemDetails> => {
	const segmentationId = useAppStore((state) => state.segmentationId);

	return useQuery({
		queryKey: queryKeys.shipTo.listVmiShipToBrands(segmentationId, shipToId).queryKey,
		queryFn: async (): Promise<VmiBrandListRespose> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const response = await shipToApi.shiptoListvmishiptobrandsList({ shipToId });

			return response.data;
		},
		enabled: !isEmpty(shipToId),
	});
};
